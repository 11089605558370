import React from 'react'
import { Map } from 'immutable';
import {Editor, EditorState, ContentState, convertFromRaw, convertToRaw} from 'draft-js'

class ElementWYSIWYG extends React.Component {
  constructor(props) {
    super(props)

    var textContent
    if(this.props.text !== undefined && this.props.text !== '' && Object.prototype.toString.call(this.props.text) !== '[object String]') {
      textContent = EditorState.createWithContent(convertFromRaw(this.props.text))
    } else {
      textContent = EditorState.createEmpty()
    }
    this.state = {
      textContent: textContent
    }
    this.focus = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.text !== undefined && props.text !== '' && props.text !== this.state.textContent) {
        selectionState = this.state.textContent.getSelection()
        var textContent
        if(props.text !== undefined && this.props.text !== '' && Object.prototype.toString.call(this.props.text) !== '[object String]') {
          textContent = EditorState.createWithContent(convertFromRaw(props.text))
        } else {
          textContent = EditorState.createEmpty()
        }
        this.setState({
          textContent: textContent
        })
      }
    }
  }

  handleChange(textContent) {
    var text = convertToRaw(textContent.getCurrentContent())
    this.setState({
      textContent: textContent
    })
    var fieldName
    var fieldValue
    if(this.props.itemId !== undefined) {
      var items = this.props.element.settings[this.props.repeaterFieldName]
      items.map((item, index) => {
        if(item.id === this.props.itemId) {
          items[index].settings[this.props.fieldName] = text
        }
        return null
      })
      fieldName = this.props.repeaterFieldName
      fieldValue = items
    } else {
      fieldName = this.props.fieldName
      fieldValue = text
    }
    if(this.props.columnElementId !== undefined) {
      this.props.onChangeField({
        moduleId: this.props.module.id,
        elementId: this.props.elementId,
        columnId: this.props.column.id,
        columnElementId: this.props.columnElementId,
        fieldName: fieldName,
        fieldValue: fieldValue
      })
    } else {
      this.props.onChangeField({
        moduleId: this.props.module.id,
        elementId: this.props.element.id,
        fieldName: fieldName,
        fieldValue: fieldValue
      })
    }
  }

  handleFocus() {
    this.focus = true
  }

  handleBlur() {
    this.focus = false
  }

  render() {
    return(
      <div className="element-text">
        <Editor
          placeholder={this.props.placeholder}
          editorState={this.state.textContent}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
      </div>
    )
  }
}

export default ElementWYSIWYG
