import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementMultipleChoiceAnswersList from './ElementMultipleChoiceAnswersList'

class ElementMultipleChoice extends ElementBase {
  handleChangeAnswers(args) {
    var answers = this.props.element.settings.answers
    answers.map((answer, index) => {
      if(answer.id === args.itemId) {
        answers[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'answers',
      fieldValue: answers
    })
  }

  handleChangeBoxHeadline(headline, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_headline',
      fieldValue: headline
    })
  }

  handleChangeBoxText(text, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_text',
      fieldValue: text
    })
  }

  html() {
    return(
      <div className="multiple-choice">
        <ElementBox
          {...this.props}
        />
        <ElementMultipleChoiceAnswersList
          answers={this.props.element.settings.answers}
          onChangeAnswer={this.handleChangeAnswers.bind(this)}
        />
        <div className="buttons">
          <div className="button resolve">Zur Auflösung</div>
        </div>
      </div>
    )
  }
}

export default ElementMultipleChoice
