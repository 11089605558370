import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ElementWYSIWYG from './components/ElementWYSIWYG'

class ElementAccordion extends ElementBase {
  constructor(props) {
    super(props)

    this.state = {
      activeSection: -1
    }
  }

  openSection(index) {
    this.setState({
      activeSection: index
    })
  }

  html() {
    var settings = this.props.element.settings
    var sections = []
    if(settings.sections !== undefined && settings.sections.length > 0) {
      sections = settings.sections.map((section, index) => {
        return(
          <div
            key={index}
            className={'section' + (index === this.state.activeSection ? ' active' : '')}
          >
            <div
              className="section-head"
              onClick={this.openSection.bind(this, index)}
            >
              <div className="headline h2">
                <ElementTextarea
                  {...this.props}
                  fieldName="headline"
                  text={section.settings.headline}
                  placeholder="Titel..."
                  repeaterFieldName="sections"
                  itemId={section.id}
                />
              </div>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.858" height="8.479" viewBox="0 0 16.858 8.479">
                  <path d="M4332.7,2592.82l-7.432-8.091,7.432-8.091" transform="translate(-2576.301 4333.072) rotate(-90)" strokeWidth="1" />
                </svg>
              </div>
            </div>
            <div className="section-body">
              <ElementWYSIWYG
                {...this.props}
                fieldName="text"
                text={section.settings.text}
                placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                repeaterFieldName="sections"
                itemId={section.id}
              />
            </div>
          </div>
        )
      })
    }
    return(
      <div className="accordion">
        <div className="sections">
          {sections}
        </div>
      </div>
    )
  }
}

export default ElementAccordion
