import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ElementWYSIWYG from './components/ElementWYSIWYG'
import ComponentImage from './components/Image'

class ElementButton extends ElementBase {
  constructor(props) {
    super(props)
  }

  html() {
    var text = '';
    if(this.props.element.settings !== undefined && this.props.element.settings.description !== undefined) {
      text = this.props.element.settings.description;
    }
    if(text == '') {
      text = 'Button';
    }
    return(
      <div className="button-element">
        <a className="button">{text}</a>
      </div>
    )
  }
}

export default ElementButton
