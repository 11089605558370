import React from 'react'
import { Form } from 'semantic-ui-react';
import {withRouter} from 'react-router'
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize'
import xliff from 'xliff'

import InputImage from './WindowSettingsInputImage'

import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'
import ConfigHelper from '../../../helpers/Config'

import draftToHtml from 'draftjs-to-html';

var sanitize = require("sanitize-filename");

class WindowSettingsTabLanguagesTabText extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      originalStrings: [],
      translations: this.props.wbt.settings.translatedStrings,
      secondaryLang: ''
    }
  }

  componentDidMount() {
    this.loadOriginalStrings();
  }

  loadOriginalStrings() {
    var fields = []
    this.props.wbt.modules.map((module) => {
      if(module.settings.headline_show && module.settings.headline != '') {
        var moduleField = {};
        moduleField.type = 'textarea';
        moduleField.name = 'Headline';
        moduleField.key = module.id + '_headline';
        moduleField.value = module.settings.headline;
        fields.push(moduleField);
      }
      module.elements.map((element) => {
        var type = element.type
        var elementConfig = ConfigHelper.getElementConfig(type)
        if(elementConfig.settings.sections !== undefined) {
          elementConfig.settings.sections.map((section) => {
            if(section.fields !== undefined) {
              section.fields.map((sectionField) => {
                if(sectionField.type == 'text' || sectionField.type == 'textarea' || sectionField.type == 'wysiwyg') {
                  if(element.settings[sectionField.name] != '') {
                    var field = {};
                    field.type = sectionField.type;
                    field.name = '';
                    field.key = element.id + '_' + sectionField.name;
                    if(sectionField.type == 'wysiwyg') {
                      const rawContentState = element.settings[sectionField.name]
                      const markup = draftToHtml(
                        rawContentState
                      );
                      field.value = markup.replace(/<\/?[^>]+(>|$)/g, "");
                    } else {
                      field.value = element.settings[sectionField.name];
                    }
                    fields.push(field);
                  }
                } else if(sectionField.type == 'repeater') {
                  if(sectionField.fields !== undefined) {
                    {Object.keys(element.settings[sectionField.name]).map((rElement, i) => {
                      sectionField.fields.map((sectionFieldField) => {
                        if(sectionFieldField.type == 'text' || sectionFieldField.type == 'textarea' || sectionFieldField.type == 'wysiwyg') {
                          if(element.settings[sectionField.name][i].settings[sectionFieldField.name] != undefined) {
                            if(element.settings[sectionField.name][i].settings[sectionFieldField.name] != '') {
                              var field = {};
                              field.type = sectionFieldField.type;
                              field.name = '';
                              field.key = element.id + '_' + sectionField.name + '_' + element.settings[sectionField.name][i].id + '_' + sectionFieldField.name;
                              if(sectionFieldField.type == 'wysiwyg') {
                                const rawContentState = element.settings[sectionField.name]
                                const markup = draftToHtml(
                                  rawContentState
                                );
                                field.value = markup.replace(/<\/?[^>]+(>|$)/g, "");
                              } else {
                                field.value = element.settings[sectionField.name][i].settings[sectionFieldField.name];
                              }
                              fields.push(field);
                            }
                          }
                        }
                      })
                    })}
                  }
                }
              })
            }
          })
        }
      })
    });
    this.setState({
      originalStrings: fields
    }, () => {
      this.loadTranslations();
    })
  }

  loadTranslations() {
    var translationStrings = [];
    this.state.originalStrings.map((string) => {
      translationStrings.push({
        key: string.key,
        value: ''
      })
    });
    var translations = {};
    if(this.state.secondaryLang !== '') {
      translations[this.state.secondaryLang] = translationStrings;
      this.setState({
        translations: translations
      })
    }
  }

  handleChangeText(lang, key, e) {
    this.changeValue(lang, key, e.target.value)
  }

  handleChangeTextarea(lang, key, e) {
    this.changeValue(lang, key, e.target.value)
  }

  changeValue(lang, key, value) {
    if(this.state.secondaryLang != '') {
      var translations = this.state.translations;
      var translationSet = translations[lang]
      if(translationSet == undefined) {
        var translationStrings = [];
        this.state.originalStrings.map((string) => {
          translationStrings.push({
            key: string.key,
            value: ''
          })
        });
        translations[this.state.secondaryLang] = translationStrings;
        translationSet = translations[lang]
      }
      var translation = translationSet.find(x => x.key === key);
      if(translation == undefined) {
        var nTranslationStrings = translations[this.state.secondaryLang];
        nTranslationStrings.push({
          key: key,
          value: ''
        })
        translationSet = nTranslationStrings
        translation = translationSet.find(x => x.key === key);
      }
      let index = translationSet.indexOf(translation);
      translationSet[index].value = value;
      translations[lang] = translationSet;
      this.setState({
        translations: translations
      }, () => {
        this.props.onChangeTranslation(translations);
      });
    }
  }

  handleChangeSecondaryLang(e, {value}) {
    this.setState({
      secondaryLang: value
    })
  }

  handleExport() {
    this.downloadXliffFile()
  }

  downloadXliffFile = () => {
    var title = sanitize(this.props.wbt.settings.title)

    var id = this.props.wbt.id;
    var sourceLang = this.props.defaultLanguage;
    var body = '';

    var strings = {}
    this.state.originalStrings.map((string) => {
      var unitId = string.key;
      strings[unitId] = {
        source: sanitize(string.value.replace(/<\/?[^>]+(>|$)/g, "")),
        target: ''
      }
    })

    const js = {
      resources: {
        [id]: strings
      },
      sourceLanguage: sourceLang,
    }

    xliff.jsToXliff12(js, (err, res) => {
      const element = document.createElement("a");
      const file = new Blob([res], {type: 'application/x-xliff+xml'});
      element.href = URL.createObjectURL(file);
      element.download = title + '.xliff';
      document.body.appendChild(element);
      element.click();
    });
  }

  handleImport() {
    this.refs.fileUploader.click();
  }

  onChangeFile(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = e => {
      var xliffCode = e.target.result;
      xliff.xliff12ToJs(xliffCode, (err, res) => {
        if(res.resources !== undefined && res.resources[this.props.wbt.id] !== undefined) {
          var strings = res.resources[this.props.wbt.id];
          this.state.originalStrings.map((oString) => {
            var key = oString.key;
            if(strings[key] !== undefined) {
              this.changeValue(this.state.secondaryLang, key, strings[key].target)
            }
          });
        } else {
          alert('Ungültige WBT-ID!');
        }
      })
    };
    e.target.value = null;
  }

  render() {
    let dLanguages = this.props.defaultLanguageOptions.find(x => x.key === this.props.defaultLanguage);
    let dIndex = this.props.defaultLanguageOptions.indexOf(dLanguages);
    var defaultLangShort = ''
    if(this.props.defaultLanguageOptions[dIndex] !== undefined) {
      defaultLangShort = this.props.defaultLanguageOptions[dIndex].text;
    }
    var selectedLanguageOptions = [];
    if(this.props.defaultLanguageOptions !== undefined) {
      this.props.defaultLanguageOptions.map((option) => {
        if(this.props.selectedLanguageOptions.includes(option.key) && option.key != this.props.defaultLanguage) {
          selectedLanguageOptions.push(option)
        }
      });
    }
    return(
      <>
        <div className="table">
          <div className="table-head">
            <div className="row">
              <div className="column">Element</div>
              <div className="column">{defaultLangShort}</div>
              <div className="column">
                <Form.Select
                  options={selectedLanguageOptions}
                  value={this.state.secondaryLang}
                  placeholder="Sprache"
                  onChange={this.handleChangeSecondaryLang.bind(this)}
                />
              </div>
            </div>
            <div className="row highlighted">
              <div className="column">XLIFF</div>
              <div className="column column-wide">
                <a className="link export" onClick={this.handleExport.bind(this)}>
                  <span className="text">Export</span>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.061" height="4.591" viewBox="0 0 7.061 4.591">
                      <path id="Pfad_787" data-name="Pfad 787" d="M3138.31,281.57l3,3,3-3" transform="translate(-3137.78 -281.04)" fill="none" stroke="#fc45f0" strokeWidth="1.5"/>
                    </svg>
                  </span>
                </a>
              </div>
              <div className="column">
                <input type="file" id="file" ref="fileUploader" style={{display: "none"}} onChange={this.onChangeFile.bind(this)} accept=".xlf,.xliff" />
                <a className="link import" onClick={this.handleImport.bind(this)}>
                  <span className="text">Import</span>
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.061" height="4.591" viewBox="0 0 7.061 4.591">
                      <path id="Pfad_787" data-name="Pfad 787" d="M3138.31,284.57l3-3,3,3" transform="translate(-3137.779 -280.51)" fill="none" stroke="#fc45f0" strokeWidth="1.5"/>
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
          {
            this.state.originalStrings.length > 0 ?
              <div className="table-body">
                {
                  this.state.originalStrings.map((string) => {
                    var translatedValue = '';
                    var translationSet = this.state.translations[this.state.secondaryLang];
                    console.log('translationSet', this.state.translations)
                    console.log('2', this.state.translations[this.state.secondaryLang])
                    console.log('2', this.state.secondaryLang)
                    if(translationSet !== undefined) {
                      let translation = translationSet.find(x => x.key === string.key);
                      let index = translationSet.indexOf(translation);
                      if(translatedValue = translationSet[index] !== undefined) {
                        translatedValue = translationSet[index].value;
                      }
                    }
                    if(translatedValue == false) {
                      translatedValue = ''
                    }
                    return (
                      <div className="row" key={string.key + '_' + this.state.secondaryLang}>
                        <div className="column">
                          {
                            string.name != '' ?
                              string.name
                            :
                              ''
                          }
                          {
                            string.name == '' && string.type == 'text' ?
                              'Text'
                            :
                              ''
                          }
                          {
                            string.name == '' && string.type == 'textarea' ?
                              'Fließext'
                            :
                              ''
                          }
                          {
                            string.name == '' && string.type == 'wysiwyg' ?
                              'Fließext'
                            :
                              ''
                          }
                        </div>
                        <div className="column">{string.value}</div>
                        <div className="column" style={this.state.secondaryLang == '' ? {display: 'none'} : {}}>
                          {
                            string.type == 'text' ?
                              <input
                                type="text"
                                value={translatedValue}
                                placeholder={'Lorem ipsum'}
                                onChange={this.handleChangeText.bind(this, this.state.secondaryLang, string.key)}
                              />
                            :
                              ''
                          }
                          {
                            string.type == 'textarea' || string.type == 'wysiwyg' ?
                              <TextareaAutosize
                                placeholder={'Lorem ipsum'}
                                value={translatedValue}
                                onChange={this.handleChangeTextarea.bind(this, this.state.secondaryLang, string.key)}
                              />
                            :
                              ''
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            :
              null
          }
        </div>
      </>
    )
  }
}

export default WindowSettingsTabLanguagesTabText
