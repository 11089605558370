import React from 'react'

import InputBase from './WindowSettingsInputBase'

import CmsHelper from '../../../helpers/Cms'

class InputImage extends InputBase {
  constructor(props) {
    super(props)

    this.state = {
      imageName: '',
      url: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.value)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.value !== undefined && props.value !== this.props.value) {
      this.setUrl(props.value)
    }
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = CmsHelper.getImageUrl({
        file: response.file.path,
        width: 100
      })
      this.setState({
        imageName: response.title,
        url: url
      })
    } else {
      this.setState({
        imageName: '',
        url: ''
      })
    }
  }

  handleSelectImage() {
    this.props.onOpenModal({
      field: 'wbt_image'
    })
  }

  handleDeleteImage() {
    this.changeField('')
  }

  html() {
    return (
      this.state.url !== '' ?
        <div className="input input-image">
          <div
            className="preview"
            onClick={this.handleSelectImage.bind(this)}
          >
            <img src={this.state.url} alt="" />
          </div>
          <div
            className="title"
            onClick={this.handleSelectImage.bind(this)}
          >
            {this.state.imageName}
          </div>
          <button onClick={this.handleDeleteImage.bind(this)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.553" height="10.53" viewBox="0 0 8.553 10.53">
              <g transform="translate(0.003 0.001)">
                <path d="M222.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,222.645,154.7Zm0,0" transform="translate(-216.917 -150.889)"></path>
                <path d="M104.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,104.645,154.7Zm0,0" transform="translate(-101.827 -150.889)"></path>
                <path d="M.7,3.134V9.21a1.361,1.361,0,0,0,.362.938,1.214,1.214,0,0,0,.881.381H6.606a1.214,1.214,0,0,0,.881-.381,1.361,1.361,0,0,0,.362-.938V3.134a.942.942,0,0,0-.242-1.853H6.345V.973A.969.969,0,0,0,5.368,0H3.178A.969.969,0,0,0,2.2.973v.308H.939A.942.942,0,0,0,.7,3.134Zm5.909,6.9H1.94a.781.781,0,0,1-.75-.826V3.155H7.356V9.21A.781.781,0,0,1,6.606,10.036ZM2.695.973A.475.475,0,0,1,3.178.492h2.19a.475.475,0,0,1,.483.481v.308H2.695Zm-1.756.8H7.607a.444.444,0,0,1,0,.888H.939a.444.444,0,1,1,0-.888Zm0,0" transform="translate(0 0)"></path>
                <path d="M163.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,163.645,154.7Zm0,0" transform="translate(-159.372 -150.889)"></path>
              </g>
            </svg>
          </button>
        </div>
      :
        <button className="button add-edit" onClick={this.handleSelectImage.bind(this)}>
          <div className="icon icon-image">
            <svg xmlns="http://www.w3.org/2000/svg" width="9.708" height="8.326" viewBox="0 0 9.708 8.326">
              <g transform="translate(0 -0.5)">
                <path d="M181.72,98.256a.97.97,0,1,0-.97-.97A.971.971,0,0,0,181.72,98.256Zm0-1.371a.4.4,0,1,1-.4.4A.4.4,0,0,1,181.72,96.885Zm0,0" transform="translate(-177.323 -94)" />
                <path d="M9.354.5h-9A.355.355,0,0,0,0,.854V8.472a.355.355,0,0,0,.354.354h9a.355.355,0,0,0,.354-.354V.854A.355.355,0,0,0,9.354.5Zm-.214.569v4.5L7.35,3.781a.3.3,0,0,0-.423,0L4.4,6.312,2.781,4.7a.3.3,0,0,0-.423,0L.569,6.484V1.069ZM.569,8.257V7.289l2-2L4.185,6.9a.3.3,0,0,0,.423,0l2.53-2.53,2,2V8.257Zm0,0" />
              </g>
            </svg>
          </div>
          <div className="label">Bild auswählen</div>
        </button>
    )
  }
}

export default InputImage
