import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ComponentImage from './components/Image'

class ElementSlider extends ElementBase {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0
    }
  }

  slidePrev() {
    var nextActiveSlide = this.state.activeSlide - 1
    if(this.props.element.settings === undefined || this.props.element.settings.slides === undefined) {
      nextActiveSlide = 0
    } else if(nextActiveSlide < 0) {
      nextActiveSlide = this.props.element.settings.slides.length - 1
    }
    this.setState({
      activeSlide: nextActiveSlide
    })
  }

  slideNext() {
    var nextActiveSlide = this.state.activeSlide + 1
    if(this.props.element.settings === undefined || this.props.element.settings.slides === undefined || nextActiveSlide >= this.props.element.settings.slides.length) {
      nextActiveSlide = 0
    }
    this.setState({
      activeSlide: nextActiveSlide
    })
  }

  html() {
    var slidesImages = []
    var slidesContent = []
    if(this.props.element.settings !== undefined && this.props.element.settings.slides !== undefined && this.props.element.settings.slides.length > 0) {
      slidesImages = this.props.element.settings.slides.map((slide, index) => {
        return(
          <div
            key={index}
            className={'slide' + (index === this.state.activeSlide ? ' active' : '')}
          >
            <div className="image">
              <ComponentImage
                id={slide.settings.image}
              />
            </div>
          </div>
        )
      })
      slidesContent = this.props.element.settings.slides.map((slide, index) => {
        return(
          <div
            key={index}
            className={'slide' + (index === this.state.activeSlide ? ' active' : '')}
          >
            { /* customized start */ }
              <div className="separator"></div>
              <div className="separator"></div>
            { /* customized end */ }
            <div className="headline h2">
              <ElementTextarea
                {...this.props}
                fieldName="headline"
                text={slide.settings.headline}
                placeholder="Titel..."
                repeaterFieldName="slides"
                itemId={slide.id}
              />
            </div>
            <hr />
            <div className="text">
              <ElementTextarea
                {...this.props}
                fieldName="text"
                text={slide.settings.text}
                placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                repeaterFieldName="slides"
                itemId={slide.id}
              />
            </div>
          </div>
        )
      })
    }
    return(
      <div className="slider">
        <div className="slider-images">
          <div className="slides">{slidesImages}</div>
        </div>
        <div className="slider-content">
          <div className="slides">{slidesContent}</div>
        </div>
        <div className="slider-arrows">
          <div
            className="slider-arrow"
            onClick={this.slidePrev.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
              <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(970.401 243.457) rotate(180)"></path>
            </svg>
          </div>
          <div
            className="slider-arrow"
            onClick={this.slideNext.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.718 15.297">
              <path d="M962.03,228.521l7.646,7.344-7.646,7.229" transform="translate(-961.684 -228.159)"></path>
            </svg>
          </div>
        </div>
      </div>
    )
  }
}

export default ElementSlider
