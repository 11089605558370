import React from 'react'

import CmsHelper from '../../../../../helpers/Cms'

class Audio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      src: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.id)
  }

  async setUrl(id) {
    if(id !== '') {
      var response = await CmsHelper.get({
        type: 'attachments',
        id: id
      })
      var src = CmsHelper.getDirectUrl({
        file: response ? response.file.path : ''
      })
      this.setState({
        src: src
      })
    }
  }

  render() {
    return(
      <audio src={this.state.src}></audio>
    )
  }
}

export default Audio
