import React from 'react'

import ElementMultipleChoiceAnswersItem from './ElementMultipleChoiceAnswersItem'

class ElementMultipleChoiceAnswersList extends React.Component {
  handleChangeText(id, text, e) {
    this.props.onChangeAnswer({
      itemId: id,
      fieldName: 'text',
      fieldValue: text
    })
  }

  render() {
    if(this.props.answers !== undefined && this.props.answers.length > 0) {
      var answers = this.props.answers.map((answer, index) => {
        return(
          <ElementMultipleChoiceAnswersItem
            key={index}
            text={(answer.settings.text !== undefined ? answer.settings.text : '')}
            onChangeText={this.handleChangeText.bind(this, answer.id)}
          />
        )
      })
      return(
        <div className="answers-list">
          {answers}
        </div>
      )
    } else {
      return null
    }
  }
}

export default ElementMultipleChoiceAnswersList
