import React from 'react'

class InputBase extends React.Component {
  changeField(value) {
    if(this.props.columnElementId !== undefined) {
      this.props.onChangeField({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        itemId: this.props.itemId,
        columnElementId: this.props.columnElementId,
        fieldName: this.props.field.name,
        fieldValue: value
      })
    } else if(this.props.itemId !== undefined) {
      this.props.onChangeField({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        itemId: this.props.itemId,
        fieldName: this.props.field.name,
        fieldValue: value
      })
    } else {
      this.props.onChangeField({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        fieldName: this.props.field.name,
        fieldValue: value
      })
    }
  }

  html() {
    return(
      <>
        Feld
      </>
    )
  }

  render() {
    return(
      <div className="field">
        {this.html()}
      </div>
    )
  }
}

export default InputBase
