import React from 'react'

class Elements extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hover: false,
      active: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.isElementDragging) {
      this.setState({
        hover: false,
        active: false
      })
    }
  }

  handleMouseOver() {
    this.setState({
      hover: true
    })
  }

  handleMouseLeave() {
    this.setState({
      hover: false
    })
  }

  handleMouseDown() {
    this.setState({
      active: true
    })
  }

  handleMouseUp() {
    this.setState({
      active: false
    })
  }

  render() {
    return(
      <div
        className={'element element-' + this.props.category + ' drag-preview' + (this.state.hover && !this.props.isElementDragging ? ' hover' : '') + (this.state.hover && this.state.active && !this.props.isElementDragging ? ' active' : '')}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseUp={this.handleMouseUp.bind(this)}
      >
        <div className="drag-preview-container">
          <div className="icon">{this.props.icon}</div>
          <div className="label">{this.props.label}</div>
          <div className="background"></div>
        </div>
      </div>
    )
  }
}

export default Elements
