import React from 'react'

import CmsHelper from '../helpers/Cms'
import ConfigHelper from '../helpers/Config'

class Tooltip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false
    }
  }

  componentDidMount() {
    this.checkTooltipState()
  }

  async checkTooltipState() {
    var user = await CmsHelper.get({
      type: 'users',
      id: JSON.parse(sessionStorage.getItem('userId'))
    })
    var closedTooltips = user.closed_tooltips
    if(user.show_tooltips && !closedTooltips.includes(this.props.tooltip)) {
      this.setState({
        show: true
      })
      closedTooltips.push(this.props.tooltip)
      await CmsHelper.put({
        type: 'users',
        id: JSON.parse(sessionStorage.getItem('userId')),
        body: JSON.stringify({
          closed_tooltips: closedTooltips
        })
      })
    }
  }

  getOptions(tooltip) {
    var options
    var elementConfig
    if(tooltip.includes('element_info_')) {
      var split = tooltip.split('element_info_');
      var type = split[1]
      tooltip = 'element_info'
      elementConfig = ConfigHelper.getElementConfig(type)
    }
    switch(tooltip) {
      case 'add_module':
        options = {
          text: 'Erstelle ein Modul durch das Klicken auf das Plus Symbol innerhalb des dunkelblauen Balken.',
          video: '',
          style: {
            left: '50%',
            bottom: 'calc(100% + 40px)'
          }
        }
        break
      case 'add_element':
        options = {
          text: 'Ziehe ein Element aus der Seitenleiste in das Modul. Du weißt, dass du das Element loslassen kannst, wenn die Modulfläche magentafarben aufleuchtet.',
          video: '',
          style: {
            left: '50%',
            bottom: '75%'
          }
        }
        break
      case 'edit_inline':
        options = {
          text: 'Das Ändern von Text kannst du auch vornehmen, ohne das Bearbeitungsfenster zu öffnen. Klicke in eine beliebige Textfläche um diese direkt zu bearbeiten.',
          video: '',
          style: {
            left: '50%',
            bottom: '75%'
          }
        }
        break
      case 'edit_element':
        options = {
          text: 'Fahre mit der Maus über das Element und klicke auf das lilafarbene Bleistift Symbol um das Bearbeitungsfenster zu öffnen.',
          video: '',
          style: {
            right: '-46px',
            bottom: 'calc(100% + 7px)'
          },
          arrowStyle: {
            left: 'auto',
            right: '12px'
          }
        }
        break
      case 'edit_end':
        options = {
          text: 'Im Bearbeitunsfenster kannst du alle Einstellungen des Elements ändern. Um den Bearbeitungsprozess abzuschließen, klicke auf den Fertig Button oder neben das Element.',
          video: '',
          style: {
            left: '50%',
            bottom: '100%'
          }
        }
        break
      case 'element_info':
        options = {
          text: 'Schau dir ein kleines Video zu ' + elementConfig.title + ' an.',
          video: elementConfig.helpVideo !== undefined ? elementConfig.helpVideo : '',
          style: {
            left: '50%',
            bottom: '100%'
          }
        }
        break
      default:
        options = {
          text: '',
          video: '',
          style: {
            left: '50%',
            bottom: '100%'
          }
        }
        break
    }
    return options
  }

  handleClose() {
    this.setState({
      show: false
    })
    if(this.props.onClose !== undefined) {
      this.props.onClose()
    }
  }

  html() {
    const options = this.getOptions(this.props.tooltip)
    if(options !== null && this.state.show) {
      return(
        <div className="tooltip" style={options !== undefined && options.style !== undefined  ? options.style : {}}>
          <p>{options.text}</p>
          {
            options.video !== '' ?
              <button
                className="watch-video"
                onClick={() => this.props.onOpenHelpVideo(options.video)}
              >
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                    <g transform="translate(-835 -104)">
                      <g transform="translate(835 104)" fill="none" stroke="#a500d9" strokeWidth="1">
                        <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
                        <circle cx="6.5" cy="6.5" r="6" fill="none" />
                      </g>
                      <path d="M840.533,104.941l2.731,2.731-2.731,2.731" transform="translate(-0.056 2.961)" fill="none" stroke="#8800c4" strokeLinecap="round" strokeWidth="1" />
                    </g>
                  </svg>
                </span>
                <span className="label">Ansehen</span>
              </button>
            :
              ''
          }
          <button className="button highlighted round tooltip-button" onClick={this.handleClose.bind(this)}>
            <span className="label">Verstanden</span>
          </button>
          <div className="arrow">
            <div className="before" style={options !== undefined && options.arrowStyle !== undefined  ? options.arrowStyle : {}}></div>
            <div className="after" style={options !== undefined && options.arrowStyle !== undefined  ? options.arrowStyle : {}}></div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    return this.html()
  }
}

export default Tooltip
