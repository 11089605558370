import React from 'react'
import {Editor, EditorState, ContentState, RichUtils, convertFromRaw, convertToRaw} from 'draft-js';

import InputBase from './InputBase'

class InputWYSIWYG extends InputBase {
  constructor(props) {
    super(props);

    var editorState
    if(this.props.value !== undefined && this.props.value !== '' && Object.prototype.toString.call(this.props.value) !== '[object String]') {
      editorState = EditorState.createWithContent(convertFromRaw(this.props.value))
    } else {
      editorState = EditorState.createEmpty()
    }
    this.state = {
      editorState: editorState
    }
    this.focus = false
    this.handleKeyCommand = this.handleKeyCommand.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.value !== undefined && props.value !== '' && props.value !== this.state.editorState) {
        selectionState = this.state.editorState.getSelection()
        this.setState({
          editorState: EditorState.createWithContent(convertFromRaw(props.value))
        })
      }
    }
  }

  handleChange(editorState) {
    this.setState({
      editorState: editorState
    })
    this.changeField(convertToRaw(editorState.getCurrentContent()))
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if(newState) {
      this.handleChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  handleFocus() {
    this.focus = true
  }

  handleBlur() {
    this.focus = false
  }

  toggleBlockType(blockStyle) {
    var editorState = RichUtils.toggleBlockType(this.state.editorState, blockStyle)
    this.handleChange(editorState);
  }

  toggleInlineStyle(inlineStyle) {
    var editorState = RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    this.handleChange(editorState);
  }

  html() {
    const {
      name = '',
      label = ''
    } = this.props.field
    return(
      <div className="input input-wysiwyg light">
        <div className="wysiwyg-buttons">
          <div className="row">
            <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('header-one')
                }
              }
            >
              H1
            </button>
            <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('header-two')
                }
              }
            >
              H2
            </button>
            <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('header-three')
                }
              }
            >
              H3
            </button>
            <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('paragraph')
                }
              }
            >
              p
            </button>
            { /* <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('unordered-list-item')
                }
              }
            >
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.065" height="12" viewBox="0 0 13.065 12">
                  <g transform="translate(-189 -259.5)">
                    <line x2="9.499" transform="translate(192.567 270.5)" strokeWidth="1" />
                    <line x2="9.499" transform="translate(192.567 265.5)" strokeWidth="1" />
                    <line x2="9.499" transform="translate(192.567 260.5)" strokeWidth="1" />
                    <circle cx="1" cy="1" r="1" transform="translate(189 269.5)" />
                    <circle cx="1" cy="1" r="1" transform="translate(189 264.5)" />
                    <circle cx="1" cy="1" r="1" transform="translate(189 259.5)" />
                  </g>
                </svg>
              </div>
            </button> */ }
            <button
              className="wysiwyg-button"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleBlockType('ordered-list-item')
                }
              }
            >
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="14.065" height="15" viewBox="0 0 14.065 15">
                  <g transform="translate(-159.933 -257.641)">
                    <text transform="translate(161.933 261.641)" fontSize="4" fontFamily="DIN2014-Demi, 'DIN 2014'" fontWeight="300" letterSpacing="0.06em"><tspan x="-1.044" y="0">1</tspan></text>
                    <text transform="translate(161.933 266.641)" fontSize="4" fontFamily="DIN2014-Demi, 'DIN 2014'" fontWeight="300" letterSpacing="0.06em"><tspan x="-1.044" y="0">2</tspan></text>
                    <text transform="translate(161.933 271.641)" fontSize="4" fontFamily="DIN2014-Demi, 'DIN 2014'" fontWeight="300" letterSpacing="0.06em"><tspan x="-1.044" y="0">3</tspan></text>
                    <line x2="9.499" transform="translate(164.5 270.5)" strokeWidth="1"/>
                    <line x2="9.499" transform="translate(164.5 265.5)" strokeWidth="1"/>
                    <line x2="9.499" transform="translate(164.5 260.5)" strokeWidth="1"/>
                  </g>
                </svg>
              </div>
            </button>
          </div>
          <div className="row">
            <button
              className="wysiwyg-button wysiwyg-button-bold"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleInlineStyle('BOLD')
                }
              }
            >
              B
            </button>
            <button
              className="wysiwyg-button wysiwyg-button-italic"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleInlineStyle('ITALIC')
                }
              }
            >
              I
            </button>
            <button
              className="wysiwyg-button wysiwyg-button-underline"
              onMouseDown={
                (e) => {
                  e.preventDefault()
                  this.toggleInlineStyle('UNDERLINE')
                }
              }
            >
              U
            </button>
          </div>
        </div>
        <Editor
          key={this.props.inputKey + '_' + name}
          placeholder={label}
          editorState={this.state.editorState}
          onChange={this.handleChange.bind(this)}
          handleKeyCommand={this.handleKeyCommand.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
      </div>
    )
  }
}

export default InputWYSIWYG
