import React from 'react'

import ElementTextarea from './components/ElementTextarea'
import ComponentImage from './components/Image'

class ElementBox extends React.Component {
  render() {
    var {
      box_image = '',
      box_audio = '',
      box_headline = '',
      box_text = '',
      box_show = true
    } = this.props.element.settings
    if(box_show) {
      return(
        <div className="element-box">
          {
            box_image !== '' ?
              <ComponentImage
                id={box_image}
              />
            :
              null
          }
          {
            box_audio !== '' ?
              <button className="button audio">Audio auswählen</button>
            :
              null
          }
          <div className="headline h2">
            <ElementTextarea
              {...this.props}
              fieldName="box_headline"
              text={box_headline}
              placeholder="Titel..."
            />
          </div>
          <div className="text">
            <ElementTextarea
              {...this.props}
              fieldName="box_text"
              text={box_text}
              placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
            />
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ElementBox
