import React from 'react'

class EditorDropIndicator extends React.Component {
  render() {
    return(
      <div className={'editor-drop-indicator editor-drop-indicator-' + this.props.position + (this.props.show ? ' show' : '') + (this.props.type !== undefined ? ' type-' + this.props.type : '')}></div>
    )
  }
}

export default EditorDropIndicator
