import React from 'react'
import {DropTarget} from 'react-dnd'
import $ from 'jquery'

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

class Wbt extends React.Component {
  componentDidUpdate(prevProps) {
    if(prevProps.isOver !== this.props.isOver) {
      this.props.onDragOverPreview(this.props.isOver)
    }
  }

  handleClick(e) {
    if($(e.target).hasClass('wbt')) {
      this.props.onEditEnd()
    }
  }

  render() {
    return this.props.connectDropTarget(
      <div
        id="wbt"
        className={'wbt' + (this.props.isOver ? ' is-over' : '') + (this.props.previewMode ? ' preview-mode' : '')}
        onClick={this.handleClick.bind(this)}
      >
        <div className="wbt-container">
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DropTarget(
  ['module', 'element', 'column-element'],
  {},
  collect
)(Wbt)
