import React from 'react'
import {Editor, EditorState, ContentState} from 'draft-js'

import ElementBase from './ElementBase'
import ComponentImage from './components/Image'
import ElementTextarea from './components/ElementTextarea'

class ElementInstruction extends ElementBase {
  html() {
    var settings = this.props.element.settings
    return(
      <div className="instruction">
        <div className="image">
          <ComponentImage
            id={settings.image}
          />
        </div>
        <div className="content">
          {
            settings.audio !== undefined && settings.audio !== '' ?
              <button className="button audio">Lies mir diesen Text vor</button>
            :
              ''
          }
          <div className="text">
            <ElementTextarea
              {...this.props}
              fieldName="text"
              text={this.props.element.settings.text}
              placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ElementInstruction
