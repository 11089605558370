import React from 'react'

class WindowSettingsInputImage extends React.Component {
  changeField(value) {
    this.props.onChangeField({
      fieldName: 'wbt_image',
      fieldValue: value
    })
  }

  html() {
    return(
      <>
        Feld
      </>
    )
  }

  render() {
    return(
      this.html()
    )
  }
}

export default WindowSettingsInputImage
