import React from 'react'

import ElementBase from './ElementBase'
import Video from './components/Video'

class ElementVideo extends ElementBase {
  html() {
    var settings = this.props.element.settings
    return(
      <div className="video">
        <Video id={settings.video} />
      </div>
    )
  }
}

export default ElementVideo
