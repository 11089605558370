import React from 'react'

class AddButton extends React.Component {
  render() {
    if(this.props.previewMode || this.props.type === 'module_template') {
      return ''
    } else {
      return(
        <button className="add-button" onClick={this.props.onAdd}>
          <svg xmlns="http://www.w3.org/2000/svg" width="11.5" height="11.5" viewBox="0 0 11.5 11.5">
            <g transform="translate(-446 -5)">
              <rect width="11.5" height="1.5" transform="translate(446 10)" />
              <rect width="1.5" height="11.5" transform="translate(451 5)" />
            </g>
          </svg>
        </button>
      )
    }
  }
}

export default AddButton
