import React from 'react'
import $ from 'jquery'

import Element from './elements/Element'
import Image from './elements/Image'
import draftToHtml from 'draftjs-to-html';

function fpDraftToHtml(rawContentState) {
  if(rawContentState && rawContentState.blocks) {
    rawContentState.blocks.map((block, i) => {
      if(block.type === 'paragraph') {
        rawContentState.blocks[i].type = 'unstyled'
      }
    })
  }
  return draftToHtml(rawContentState);
}

class ExportPreview extends React.Component {
  constructor(props) {
    super(props)

    this.wbt = this.props.wbt
  }

  componentDidMount() {
    var document = this.props.document
    const script = document.createElement("script");
    script.id = 'jquery-js';
    script.src = "/dist/wbt/scripts/jquery.min.js?v=2.5.1";
    script.async = false;

    document.body.appendChild(script);

    var document = this.props.document
    const scriptt = document.createElement("script");
    scriptt.id = 'jquery-ui-js';
    scriptt.src = "/dist/wbt/scripts/jquery-ui.min.js?v=2.5.1";
    scriptt.async = false;

    document.body.appendChild(scriptt);

    var document = this.props.document
    const script1 = document.createElement("script");
    script1.id = 'daggable-js';
    script1.src = "/dist/wbt/scripts/draggable.bundle.legacy.min.js?v=2.5.1";
    script1.async = false;

    document.body.appendChild(script1);

    var document = this.props.document
    const script11 = document.createElement("script");
    script11.id = 'slick-js';
    script11.src = "/dist/wbt/scripts/slick.min.js?v=2.5.1";
    script11.async = false;

    document.body.appendChild(script11);

    const script2 = document.createElement("script");
    script2.id = 'main-js';
    script2.src = "/dist/wbt/scripts/main.min.js?v=2.5.1";
    script2.async = false;

    document.body.appendChild(script2);
  }

  modules() {
    var modules = this.wbt.modules.map((module) => {
      var elements = module.elements.map((element) => {
        return(
          <Element
            key={element.id}
            {...element}
          />
        )
      })
      var introslider = false
      if(elements.length === 1 && module.elements[0].type === 'introslider' && module.settings.headline == '') {
        introslider = true
      }
      const rawContentState = module.settings.motivation_text
      const markup = rawContentState ? fpDraftToHtml(
        rawContentState
      ) : null;
      return(
        <div key={module.id} className={'module' + (module.settings.highlighted ? ' dark-background' : '') + (introslider ? ' full-width completed' : '')} data-module-id={module.id}>
          <div className="module-container preview-mode">
            {
              module.settings.headline_show && module.settings.headline !== '' ?
                <h1 className="module-container-child">{module.settings.headline}</h1>
              :
                ''
            }
            <div className="elements module-container-child">{elements}</div>
            {
              !introslider ?
                <button className="button next module-container-child" data-completed-label="Fertig">Weiter</button>
              :
                ''
            }
          </div>
        </div>
      )
    })
    return modules
  }

  moduleOverlays() {
    var modules = this.wbt.modules.map((module) => {
      const rawContentState = module.settings.motivation_text
      const markup = rawContentState ? fpDraftToHtml(
        rawContentState
      ) : null;
      return module.settings.motivation_show && module.settings.motivation_headline ? (
        <div className={'motivation-overlay motivation-overlay-' + module.id}>
          <div className="motivation-overlay-container motivation-overlay-container-success">
            {
              module.settings.motivation_animation !== 'custom_image' ? (
                <video src={'/dist/wbt/videos/' + module.settings.motivation_animation + '.mp4'} loop muted autoPlay="true"></video>
              ) : (
                <div className="image">
                  <Image id={module.settings.motivation_image} />
                </div>
              )
            }
            <div class="content">
              <div className="headline">{module.settings.motivation_headline}</div>
              {
                markup ? (
                  <div className="text"><div dangerouslySetInnerHTML={{ __html: markup }} /></div>
                ) : null
              }
            </div>
          </div>
        </div>
      ) : null
    })
    return modules
  }

  render() {
    var modules = this.modules();
    var moduleOverlays = this.moduleOverlays();
    return(
      <div id="export-preview">
        <div id="wbt" className="wbt export-preview">
          <div className="wbt-container wbt-container-success">
            <div className="modules">
              {modules}
            </div>
            <div className="motivation-overlay motivation-overlay-quiz">
              <div className="motivation-overlay-container motivation-overlay-container-success">
                <video src="/dist/wbt/videos/success.mp4" loop muted autoPlay="true"></video>
                <div class="content">
                  <div className="headline">Glückwunsch!</div>
                  <div className="text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</div>
                </div>
              </div>
              <div className="motivation-overlay-container motivation-overlay-container-failure">
                <video src="/dist/wbt/videos/failure.mp4" loop muted autoPlay="true"></video>
                <div class="content">
                  <div className="headline">Leider nicht richtig!</div>
                  <div className="text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr.</div>
                </div>
              </div>
            </div>
            {moduleOverlays}
          </div>
        </div>
      </div>
    )
  }
}

export default ExportPreview
