import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'

import Field from './fields/Field'

import WbtHelper from '../../../../helpers/Wbt'
import ConfigHelper from '../../../../helpers/Config'
import Tooltip from '../../../../tooltip/Tooltip'

class Edit extends React.Component {
  render() {
    var icon = ''
    var title = ''
    var sections = []
    var type = ''
    var helpVideo
    if(this.props.elementId !== '') {
      var type = 'element'
      var element
      if(this.props.overlayId) {
        element = WbtHelper.getElement({
          overlayId: this.props.overlayId,
          elementId: this.props.elementId
        }, this.props.wbt)
      } else {
        element = WbtHelper.getElement({
          moduleId: this.props.moduleId,
          elementId: this.props.elementId
        }, this.props.wbt)
      }
      if(element !== undefined) {
        var elementConfig = ConfigHelper.getElementConfig(element.type)
        icon = elementConfig.icon
        title = elementConfig.title
        if(elementConfig.helpVideo !== undefined) {
          helpVideo = elementConfig.helpVideo
        }
        var fieldsSettings = {}
        sections = elementConfig.settings.sections.map((section, sectionIndex) => {
          var fields = section.fields.map((field, fieldIndex) => {
            var value = element.settings[field.name]
            fieldsSettings[field.name] = value
            return(
              <Field
                key={this.props.elementId + '_' + sectionIndex + '_' + fieldIndex}
                {...this.props}
                inputKey={this.props.elementId + '_' + sectionIndex + '_' + fieldIndex}
                field={field}
                value={value}
                fieldsSettings={fieldsSettings}
              />
            )
          })

          const {
            headline = '',
            description = ''
          } = section

          return(
            <div
              key={this.props.elementId + '_' + sectionIndex}
              className="section"
            >
              {
                headline !== '' ?
                  <div className="headline">{headline}</div>
                :
                  ''
              }
              {
                description !== '' ?
                  <div className="description">{description}</div>
                :
                  ''
              }
              {fields}
            </div>
          )
        })
      }
    } else if(this.props.moduleId !== '') {
      var type = 'module'
      var module = WbtHelper.getModule({
        moduleId: this.props.moduleId
      }, this.props.wbt)
      if(module !== undefined) {
        var moduleConfig = ConfigHelper.getModuleConfig()
        var fieldsSettings = {}
        sections = moduleConfig.settings.sections.map((section, sectionIndex) => {
          if((this.props.view === 'edit-motivation' && section.type === 'motivation') || (this.props.view === 'edit' && section.type !== 'motivation')) {
            var fields = section.fields.map((field, fieldIndex) => {
              var value = module.settings[field.name]
              fieldsSettings[field.name] = value
              return(
                <Field
                  key={this.props.moduleId + '_' + sectionIndex + '_' + fieldIndex}
                  {...this.props}
                  inputKey={this.props.moduleId + '_' + sectionIndex + '_' + fieldIndex}
                  field={field}
                  value={value}
                  fieldsSettings={fieldsSettings}
                />
              )
            })

            const {
              headline = '',
              description = ''
            } = section

            return(
              <div
                key={this.props.moduleId + '_' + sectionIndex}
                className="section"
              >
                {
                  headline !== '' ?
                    <div className="headline">{headline}</div>
                  :
                    ''
                }
                {
                  description !== '' ?
                    <div className="description">{description}</div>
                  :
                    ''
                }
                {fields}
              </div>
            )
          } else {
            return null
          }
        })
      }
    }
    return(
      <div className={'panel panel-edit' + (type !== '' ? ' type-' + type : '') + (this.props.view === 'edit-motivation' ? ' type-motivation' : '')}>
        <div className="panel-header">
          {
            type === 'element' ?
              <>
                <div className="icon">{icon}</div>
                <div className="label">{title}</div>
              </>
            :
              <div className="label">{this.props.view === 'edit-motivation' ? 'Motivation' : 'Modul'}</div>
          }
        </div>
        <div className="panel-container" data-simplebar data-simplebar-auto-hide="false">
          <div className="sections">
            {
              helpVideo !== undefined ?
                <button
                  className="watch-video"
                  onClick={() => this.props.onOpenHelpVideo(helpVideo)}
                >
                  <span className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                      <g id="Gruppe_2115" data-name="Gruppe 2115" transform="translate(-36 -213)">
                        <g id="Ellipse_73" data-name="Ellipse 73" transform="translate(36 213)" fill="none" stroke="#fc45f0" strokeWidth="1">
                          <circle cx="7.5" cy="7.5" r="7.5" stroke="none"/>
                          <circle cx="7.5" cy="7.5" r="7" fill="none"/>
                        </g>
                        <path id="Pfad_1144" data-name="Pfad 1144" d="M42.768,35.546l-6.213-4.077a.364.364,0,0,0-.4,0,.442.442,0,0,0-.2.369v8.149a.442.442,0,0,0,.2.369.368.368,0,0,0,.194.057.362.362,0,0,0,.2-.061l6.213-4.077a.439.439,0,0,0,.189-.364.43.43,0,0,0-.189-.359Zm-6.041,3.689v-6.65L41.8,35.91Z" transform="translate(5.043 184.592)" fill="#fc45f0" stroke="#fc45f0" strokeWidth="0.2"/>
                      </g>
                    </svg>
                  </span>
                  <span className="label">Erklärvideo Ansehen</span>
                </button>
              :
                ''
            }
            {sections}
          </div>
        </div>
        <div className="panel-footer">
          {
            this.props.view === 'edit' ?
              <Tooltip
                tooltip="edit_end"
              />
            :
              ''
          }
          <button className="button highlighted round" onClick={this.props.onEditEnd}><span className="label">Fertig</span></button>
        </div>
      </div>
    )
  }
}

export default Edit
