import React from 'react'

import ElementHotspotsPOI from './ElementHotspotsPOI'

class ElementHotspotsPOIs extends React.Component {
  constructor(props) {
    super(props)

    var pois = []
    if(this.props.pois !== undefined) {
      pois = this.props.pois.map((poi, index) => {
        return {
          id: poi.id,
          active: false
        }
      })
    }
    this.state = {
      pois: pois
    }
  }

  handleToggle(id, e) {
    var pois = this.state.pois
    var found = false
    pois.map((poi, index) => {
      if(poi.id === id) {
        pois[index].active = !pois[index].active
        found = true
      } else {
        pois[index].active = false
      }
      return null
    })
    if(found === false) {
      pois.push({
        id: id,
        active: true
      })
    }
    this.setState({
      pois: pois
    })
  }

  handleChangePosition(id, x, y, e) {
    this.props.onHotspotChange({
      itemId: id,
      fieldName: 'position',
      fieldValue: {
        x: x,
        y: y
      }
    })
  }

  handleChangeText(id, text, e) {
    this.props.onHotspotChange({
      itemId: id,
      fieldName: 'text',
      fieldValue: text
    })
  }

  render() {
    var pois = []
    if(this.props.pois !== undefined) {
      pois = this.props.pois.map((poi, poiIndex) => {
        var active = false
        this.state.pois.map((statePoi, statePoiIndex) => {
          if(statePoi.id === poi.id) {
            active = statePoi.active
          }
          return null
        })
        var position = poi.settings.position
        if(position === undefined) {
          position = {
            x: 0,
            y: 0
          }
        } else {
          if(position.x === '') {
            position.x = 0
          }
          if(position.y === '') {
            position.y = 0
          }
        }
        return (
          <ElementHotspotsPOI
            key={poiIndex}
            position={position}
            type={this.props.type ? this.props.type : ''}
            text={poi.settings.text ? poi.settings.text : ''}
            active={active}
            onToggle={this.handleToggle.bind(this, poi.id)}
            onChangePosition={this.handleChangePosition.bind(this, poi.id)}
            onChangeText={this.handleChangeText.bind(this, poi.id)}
          />
        )
      })
    }
    return(
      <div className="pois">
        {pois}
      </div>
    )
  }
}

export default ElementHotspotsPOIs
