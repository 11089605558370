import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';

import Field from './fields/Field'

import WbtHelper from '../../../../helpers/Wbt'
import CommonHelper from '../../../../helpers/Common'
import CmsHelper from '../../../../helpers/Cms'
import ConfigHelper from '../../../../helpers/Config'
import Tooltip from '../../../../tooltip/Tooltip'


registerLocale('de', de)

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      comment: '',
      deadline: null,
      author: '',
      authors: [{
        name: 'Autor',
        value: ''
      }],
      comments: []
    }
  }

  componentDidMount() {
    this.props.onResetCommentFiled()
    this.getAuthors()
    this.getComments()
  }

  handleChangeField(e) {
    if(e.fieldName == 'comment') {
      this.setState({
        'comment': e.fieldValue
      })
    }
    if(e.fieldName == 'deadline') {
      this.setState({
        'deadline': e.fieldValue
      })
    }
    if(e.fieldName == 'author') {
      this.setState({
        author: e.fieldValue
      })
    }
  }

  async getAuthors() {
    var response = await CmsHelper.get({
      type: 'users',
      sort: {
        _created: -1
      }
    })
    var defaultAuthors = [{
      name: 'Autor',
      value: ''
    }]
    var authors = []
    authors = response.map((value, index) =>
      {
        return {
          value: value._id,
          name: value.first_name + ' ' + value.last_name
        };
      }
    );
    var concatAuthor = defaultAuthors.concat(authors);
    this.setState({
      authors: concatAuthor
    })
  }

  async getComments() {
    var response = await CmsHelper.get({
      type: 'comments',
      filter: {
        wbt_id: this.props.wbt.id,
        element_id: this.props.elementId
      },
      sort: {
        _created: -1
      }
    })
    this.setState({
      comments: response
    })
  }

  handleOpenModal() {
    this.props.onOpenModal({
      elementId: this.props.elementId,
      comment: true
    })
  }

  handleOpenModalAudio() {
    this.props.onOpenModal({
      elementId: this.props.elementId,
      filter: 'audio',
      comment: true
    })
  }

  async submitComment() {
    var state = this.state;
    var deadline = null;
    if(state.deadline != null) {
      deadline = Date.parse(state.deadline) / 1000;
    }
    var test = await CmsHelper.put({
      type: 'comments',
      body: JSON.stringify({
        comment: state.comment,
        deadline: deadline,
        by: JSON.parse(sessionStorage.getItem('userId')),
        for: state.author,
        wbt_id: this.props.wbt.id,
        element_id: this.props.elementId,
        files: this.props.commentFiles.join(';')
      })
    })
    this.setState({
      comment: '',
      deadline: '',
      author: ''
    })
    this.props.onResetCommentFiled()
    this.props.onUpdateComments()
    this.getComments();
  }

  handleSubmit() {
    if(this.state.comment != '' && this.state.author != '') {
      this.submitComment()
    }
  }

  handleUpdate() {
    this.getComments()
    this.props.onUpdateComments()
  }

  changeDeadline(e) {
    this.setState({
      deadline: e
    })
  }

  render() {
    var icon = ''
    var title = ''
    var sections = []
    var type = ''
    var helpVideo
    if(this.props.elementId !== '') {
      var type = 'element'
      var element
      if(this.props.overlayId) {
        element = WbtHelper.getElement({
          overlayId: this.props.overlayId,
          elementId: this.props.elementId
        }, this.props.wbt)
      } else {
        element = WbtHelper.getElement({
          moduleId: this.props.moduleId,
          elementId: this.props.elementId
        }, this.props.wbt)
      }
      if(element !== undefined) {
        var elementConfig = ConfigHelper.getElementConfig(element.type)
        icon = elementConfig.icon
        title = elementConfig.title
        if(elementConfig.helpVideo !== undefined) {
          helpVideo = elementConfig.helpVideo
        }
        var fieldsSettings = {}
        var options = this.state.authors
        sections = [
          this.state.comments !== undefined && this.state.comments.length > 0 ?
          (
            <div
              key="comments"
              className="section"
            >
              <div className="comments">
                {
                  this.state.comments.map((comment) => {
                    return (
                      <Comment
                        key={comment._id}
                        id={comment._id}
                        comment={comment.comment}
                        by={comment.by}
                        for={comment.for}
                        deadline={comment.deadline}
                        checked={comment.checked}
                        created={comment._created}
                        onUpdate={this.handleUpdate.bind(this)}
                        files={comment.files}
                        onOpenModal={this.props.onOpenModal}
                      />
                    )
                  })
                }
              </div>
            </div>
          ) : null,
          (
            <div
              key="comments_form"
              className="section"
            >
              <Field
                key={'comments_texarea_' + this.props.elementId}
                inputKey={'comments_texarea_' + this.props.elementId}
                field={{'type': 'textarea', 'name': 'comment', 'label': 'Kommentar'}}
                value={this.state.comment}
                fieldsSettings=''
                onChangeField={this.handleChangeField.bind(this)}
              />
              {
                this.props.commentFiles !== undefined && this.props.commentFiles.length > 0 ?
                  <div className="comment-files">
                    {
                      this.props.commentFiles.map(file => {
                        return (
                          <File
                            id={file}
                            onDeleteCommentFile={this.props.onDeleteCommentFile}
                            onOpenModal={this.props.onOpenModal}
                          />
                        )
                      })
                    }
                  </div>
                :
                  null
              }
              <div className="double-button">
                <Field
                  key={'comments_images_' + this.props.elementId}
                  inputKey={'comments_texarea_' + this.props.elementId}
                  field={{'type': 'image', 'name': 'comment', 'label': 'Kommentar'}}
                  value={this.state.comment}
                  fieldsSettings=''
                  onChangeField={this.handleChangeField.bind(this)}
                  onOpenModal={this.handleOpenModal.bind(this)}
                />
                <Field
                  key={'comments_audio_' + this.props.elementId}
                  inputKey={'comments_texarea_' + this.props.elementId}
                  field={{'type': 'audio', 'name': 'comment', 'label': 'Kommentar'}}
                  value={this.state.comment}
                  fieldsSettings=''
                  onChangeField={this.handleChangeField.bind(this)}
                  onOpenModal={this.handleOpenModalAudio.bind(this)}
                />
              </div>
              <DatePicker
                selected={this.state.deadline}
                onChange={this.changeDeadline.bind(this)}
                placeholderText="Deadline setzen"
                dateFormat="dd.MM.yyyy"
                locale="de"
              />
              <Field
                key={'comments_author_' + this.props.elementId}
                inputKey={'comments_author_' + this.props.elementId}
                field={{'type': 'select', 'name': 'author', 'label': 'Autor', 'options': options}}
                value={this.state.author}
                fieldsSettings=''
                onChangeField={this.handleChangeField.bind(this)}
              />
              <a className="button highlighted round send-comment" onClick={this.handleSubmit.bind(this)}>
                <span className="label">Senden</span>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15.021" viewBox="0 0 18 15.021">
                    <path id="np_send_3615811_000000" d="M17.839,8.444a.375.375,0,0,0-.374-.079L.223,14.592a.375.375,0,0,0-.053.686L5.041,17.9v4.758a.073.073,0,0,0,0,.03.187.187,0,0,0,0,.048.34.34,0,0,0,.03.086v.026l.019.019.022.034a.336.336,0,0,0,.056.048l.03.022a.386.386,0,0,0,.086.041.3.3,0,0,0,.094,0h.2l3.765-2.248,3.859,2.54h0a.378.378,0,0,0,.207.06.376.376,0,0,0,.36-.27l4.2-14.274a.376.376,0,0,0-.12-.379ZM6.461,21.591,7.63,19.582l1.038.682Zm6.744.772-3.63-2.386-1.5-.981,7.98-8.242h0a.375.375,0,0,0-.483-.57L6.644,16.454a.381.381,0,0,0,.45.615l5.721-4.009L7.236,18.8a.043.043,0,0,1-.026.037l-.03.034-1.4,2.405v-3.6a.375.375,0,0,0-.18-.341L1.265,15.015,17.018,9.321Z" transform="translate(0.029 -8.346)" fill="#fff"/>
                  </svg>
                </span>
              </a>
            </div>
          )
        ]
      }
    }
    return(
      <div className={'panel panel-comment'}>
        <div className="panel-header">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="32.001" viewBox="0 0 36 32.001">
              <g id="Gruppe_2049" data-name="Gruppe 2049" transform="translate(-292.637 -560.047)">
                <path id="Pfad_1133" data-name="Pfad 1133" d="M342.811,563.422c0-.012,0-.025,0-.037a3.355,3.355,0,0,0-1.17-2.518,3.476,3.476,0,0,0-2.2-.818c-.255,0-.506,0-.761,0H318.231a3.455,3.455,0,0,0-2.2.818,3.405,3.405,0,0,0-1.172,2.681v24.528c0,.2,0,.4,0,.594a3.365,3.365,0,0,0,3.363,3.365c.81.007,1.62,0,2.434,0h17.122a6.163,6.163,0,0,0,3.455-.517,3.431,3.431,0,0,0,1.554-2.554,4.393,4.393,0,0,0,.017-.474V563.575l-.013.213C342.8,563.559,342.811,563.422,342.811,563.422Zm-1.76,1.722v21.781a3.841,3.841,0,0,1-.015.414,3,3,0,0,1-1.358,2.233,5.386,5.386,0,0,1-3.021.451H321.689c-.711,0-1.42.006-2.128,0a2.941,2.941,0,0,1-2.94-2.941c0-.173,0-.346,0-.519V565.121a2.977,2.977,0,0,1,1.024-2.344,3.022,3.022,0,0,1,1.92-.715h17.876c.223,0,.443,0,.665,0a3.037,3.037,0,0,1,1.92.715,2.933,2.933,0,0,1,1.023,2.2c0,.011,0,.021,0,.032s0,.12-.014.32Z" transform="translate(-14.174)" fill="#fff" style={{'fill': '#fff'}}/>
                <path id="Pfad_1134" data-name="Pfad 1134" d="M329.6,578.668h13.927c.644,0,1.291.015,1.936,0h.025a1.061,1.061,0,1,0,0-2.122H331.563c-.644,0-1.291-.014-1.936,0H329.6a1.061,1.061,0,1,0,0,2.122Z" transform="translate(-22.897 -10.518)" fill="#fff" style={{'fill': '#fff'}}/>
                <path id="Pfad_1135" data-name="Pfad 1135" d="M352.958,593.03H341.313a1.061,1.061,0,0,0,0,2.123h11.646a1.061,1.061,0,0,0,0-2.123Z" transform="translate(-30.366 -21.035)" fill="#fff" style={{'fill': '#fff'}}/>
                <path id="Pfad_1136" data-name="Pfad 1136" d="M362.931,609.495H356.95a1.061,1.061,0,1,0,0,2.123h5.981a1.061,1.061,0,1,0,0-2.123Z" transform="translate(-40.338 -31.535)" fill="#fff" style={{'fill': '#fff'}}/>
                <path id="Pfad_1137" data-name="Pfad 1137" d="M365.423,625.971h-4.566a1.061,1.061,0,0,0,0,2.123h4.566a1.061,1.061,0,1,0,0-2.123Z" transform="translate(-42.83 -42.043)" fill="#fff" style={{'fill': '#fff'}}/>
                <g id="Gruppe_2047" data-name="Gruppe 2047" transform="translate(292.637 565.531)">
                  <path id="Pfad_1138" data-name="Pfad 1138" d="M313.671,592.419a3.02,3.02,0,0,1,.107.761l.01.154c.025.327.033.65.041.992l0,.149c.007.405.007.817.008,1.228v.2c0,.185,0,.374,0,.52l-.513,0c-.58,0-1.345,0-2.123-.041l-.191-.008a2.4,2.4,0,0,1-1.539-.373c-.462-.449-.919-.911-1.375-1.371-.228-.23-.456-.46-.684-.689l-3.85-3.85c-.417-.417-.842-.828-1.266-1.24-.82-.794-1.669-1.616-2.442-2.467-.185-.2-.42-.427-.669-.663a13.757,13.757,0,0,1-1.05-1.072l-.027-.032-.062-.058-.013-.01-3.019-3.019c-.021-.021-.038-.036-.054-.049l-.091-.122a1.144,1.144,0,0,1,.106-1.491c.105-.109.212-.214.318-.32l1.763-1.761c.1-.1.182-.182.27-.263l.006-.005a1.358,1.358,0,0,1,.238-.179l.134-.075a1.033,1.033,0,0,1,.377-.071,1.277,1.277,0,0,1,.828.334l.205.178,2.814,2.814c.7.7,1.4,1.4,2.1,2.1l6.128,6.128q.523.523,1.048,1.043c.742.736,1.51,1.5,2.247,2.258a.793.793,0,0,1,.207.342" transform="translate(-293.916 -576.461)" fill="#22a6d6" style={{'fill': '#22a6d6'}}/>
                  <path id="Pfad_1139" data-name="Pfad 1139" d="M311.71,591.387a2.867,2.867,0,0,1,.066.562l.011.171c.023.3.031.617.039.949l0,.137c.007.4.007.806.008,1.208-.544,0-1.213-.009-1.879-.039l-.2-.009a2.587,2.587,0,0,1-1.061-.166c-.456-.443-.918-.909-1.364-1.36-.23-.233-.46-.465-.691-.7l-3.85-3.85c-.422-.422-.855-.841-1.273-1.246-.85-.823-1.653-1.6-2.41-2.435-.2-.224-.448-.456-.707-.7a13.231,13.231,0,0,1-.994-1.012l-.054-.065-.062-.058-.05-.046-2.67-2.67-.164-.164-.163-.163-.017-.017-.007-.007-.019-.026-.03-.039a.42.42,0,0,1,.046-.561c.1-.1.2-.2.3-.3l.174-.172,1.6-1.6c.074-.074.148-.149.225-.219l.015-.013a.668.668,0,0,1,.105-.083l.078-.043a.292.292,0,0,1,.079-.011h0Zm-14.907-16.2a1.78,1.78,0,0,0-.906.249l.041-.011a2.046,2.046,0,0,0-.37.273h0v0c-.1.093-.2.192-.3.291l-1.593,1.593c-.166.166-.334.328-.5.5a1.862,1.862,0,0,0-.177,2.413c.013.021.03.039.045.059.038.049.07.1.112.148.02.022.045.038.067.058l.327.327,2.724,2.724c.009.009.019.014.028.023.577.685,1.324,1.3,1.737,1.755,1.183,1.3,2.489,2.489,3.732,3.732l3.85,3.85c.687.687,1.371,1.391,2.07,2.07a3.24,3.24,0,0,0,2.2.583c.718.033,1.436.041,2.156.041.209,0,.383,0,.528,0,.716,0,.716-.1.714-1.246,0-.48,0-.96-.008-1.441-.01-.391-.016-.783-.046-1.172a4.164,4.164,0,0,0-.157-1.109l0,.025a1.512,1.512,0,0,0-.388-.662c-1.085-1.119-2.2-2.207-3.3-3.308l-2.619-2.619-3.507-3.507c-.745-.75-1.488-1.494-2.238-2.244l-2.2-2.2-.684-.685h-.03a2,2,0,0,0-1.3-.512Z" transform="translate(-292.637 -575.186)" fill="#fff" style={{'fill': '#fff'}}/>
                </g>
                <g id="Gruppe_2048" data-name="Gruppe 2048" transform="translate(295.889 568.848)">
                  <rect id="Rechteck_422" data-name="Rechteck 422" width="5.264" height="1.449" transform="translate(0 3.722) rotate(-45)" fill="#fff" style={{'fill': '#fff'}}/>
                </g>
              </g>
            </svg>

          </div>
          <div className="label">Kommentare</div>
        </div>
        <div className="panel-container" data-simplebar data-simplebar-auto-hide="false">
          <div className="sections">
            {sections}
          </div>
        </div>
        <div className="panel-footer">
          <button className="button highlighted round" onClick={this.props.onEditEnd}><span className="label">Fertig</span></button>
        </div>
      </div>
    )
  }
}

class File extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      file: {},
      url: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.id)
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = CmsHelper.getImageUrl({
        file: response.file.path,
        width: 100
      })
      this.setState({
        file: response.file,
        url: url
      })
    } else {
      this.setState({
        url: ''
      })
    }
  }

  deleteCommentFile() {
    this.props.onDeleteCommentFile(this.props.id)
  }

  handleOpenModal() {
    this.props.onOpenModal({
      id: this.props.id,
      commentFile: true
    })
  }

  render() {
    return (
      <div class="comment-file">
        {
          this.state.url != '' ?
            this.state.file.mime == 'audio/mpeg' || this.state.file.mime === 'audio/x-wav' ?
              <div className="audio" onClick={this.handleOpenModal.bind(this)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="10.276" height="8.43" viewBox="0 0 10.276 8.43">
                  <g id="speaker" transform="translate(0.05 -42.61)">
                    <g id="Gruppe_863" data-name="Gruppe 863" transform="translate(0 42.664)">
                      <g id="Gruppe_862" data-name="Gruppe 862" transform="translate(0 0)">
                        <path id="Pfad_803" data-name="Pfad 803" d="M4.486,42.683a.23.23,0,0,0-.25.044l-2.382,2.25H.231A.231.231,0,0,0,0,45.208v3.238a.231.231,0,0,0,.231.231H1.854l2.382,2.25a.231.231,0,0,0,.159.063.227.227,0,0,0,.091-.019.231.231,0,0,0,.14-.212V42.9A.231.231,0,0,0,4.486,42.683Zm-.323,7.539L2.1,48.278a.231.231,0,0,0-.159-.063H.463V45.44H1.946a.231.231,0,0,0,.159-.063l2.059-1.944Z" transform="translate(0 -42.664)" fill="#142847" stroke="#142847" stroke-width="0.1"/>
                        <path id="Pfad_804" data-name="Pfad 804" d="M355.5,42.71a.231.231,0,1,0-.271.374,4.614,4.614,0,0,1,0,7.489.231.231,0,1,0,.271.374,5.077,5.077,0,0,0,0-8.238Z" transform="translate(-347.435 -42.666)" fill="#142847" stroke="#142847" stroke-width="0.1"/>
                        <path id="Pfad_805" data-name="Pfad 805" d="M298.018,106.706a.231.231,0,1,0-.246.392,2.769,2.769,0,0,1,0,4.7.231.231,0,0,0,.246.392,3.232,3.232,0,0,0,0-5.48Z" transform="translate(-291.21 -105.283)" fill="#142847" stroke="#142847" stroke-width="0.1"/>
                        <path id="Pfad_806" data-name="Pfad 806" d="M256.078,181.381a.231.231,0,0,0-.276.371.916.916,0,0,1,0,1.479.231.231,0,1,0,.276.371,1.378,1.378,0,0,0,0-2.221Z" transform="translate(-250.165 -178.328)" fill="#142847" stroke="#142847" stroke-width="0.1"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            :
              <div onClick={this.handleOpenModal.bind(this)} className="image" style={{backgroundImage: 'url(' + this.state.url + ')'}}></div>
          :
            null
        }
        <div className="delete-file" onClick={this.deleteCommentFile.bind(this)}>
          <svg id="np_bin_2956146_000000" xmlns="http://www.w3.org/2000/svg" width="13.8" height="15.278" viewBox="0 0 13.8 15.278">
            <path id="Pfad_872" data-name="Pfad 872" d="M30.393,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,30.393,46.546Z" transform="translate(-26.041 -34.935)" fill="#fff"/>
            <path id="Pfad_873" data-name="Pfad 873" d="M47.065,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,1,0-1.059,0v4.256A.53.53,0,0,0,47.065,46.546Z" transform="translate(-40.165 -34.935)" fill="#fff"/>
            <path id="Pfad_874" data-name="Pfad 874" d="M63.733,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,63.733,46.546Z" transform="translate(-54.285 -34.935)" fill="#fff"/>
            <path id="Pfad_875" data-name="Pfad 875" d="M18.129,2.565h-3.39L14.3,1.1A1.557,1.557,0,0,0,12.824,0H10.7A1.557,1.557,0,0,0,9.219,1.1L8.783,2.565H5.39a.53.53,0,1,0,0,1.06H6.02v10.2a1.464,1.464,0,0,0,1.461,1.454h8.573a1.465,1.465,0,0,0,1.462-1.462V3.607h.613a.53.53,0,0,0,.53-.53.516.516,0,0,0-.53-.513ZM10.243,1.406h0A.492.492,0,0,1,10.7,1.06h2.142a.472.472,0,0,1,.46.35l.34,1.156H9.877Zm6.213,12.526h-.037a.41.41,0,0,1-.379.276H7.481a.41.41,0,0,1-.4-.409V3.607h9.378Z" transform="translate(-4.859 0)" fill="#fff"/>
          </svg>
        </div>
      </div>
    )
  }
}

class Comment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      image: '',
      by: '',
      for: ''
    }
  }

  componentDidMount() {
    this.loadData()
  }

  async loadData() {
    var userFor = await CmsHelper.get({
      type: 'users',
      id: this.props.for
    })
    var user = await CmsHelper.get({
      type: 'users',
      id: this.props.by
    })
    if(user !== undefined) {
      var profileImage = CmsHelper.getImageUrl({
        file: user.image.path,
        width: 62,
        height: 62
      })
      var first_name = user.first_name
      var last_name = user.last_name
      if(last_name !== '') {
        last_name = last_name.substring(0, 1) + '.';
      }
      var name = first_name + ' ' + last_name
      var forName = ''
      if(userFor !== undefined) {
        var first_name = userFor.first_name
        var last_name = userFor.last_name
        if(last_name !== '') {
          last_name = last_name.substring(0, 1) + '.';
        }
        var forName = first_name + ' ' + last_name
      }
      this.setState({
        image: profileImage,
        by: name,
        for: forName
      })
    }
  }

  handleCheck() {
    this.checkComment()
    this.props.onUpdate();
  }

  handleDelete() {
    this.deleteComment()
    this.props.onUpdate();
  }

  async checkComment() {
    await CmsHelper.put({
      type: 'comments',
      id: this.props.id,
      body: JSON.stringify({
        checked: true
      })
    })
  }

  async deleteComment() {
    await CmsHelper.delete({
      type: 'comments',
      id: this.props.id
    })
  }

  render() {
    var file = this.props.files
    var commentFiles = []
    if(file != '') {
      commentFiles = this.props.files.split(';')
    }
    return(
      <div className="comment">
        {
          this.props.checked ?
            <div className="checked">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g id="Gruppe_2176" data-name="Gruppe 2176" transform="translate(-246 -185)">
                  <g id="Ellipse_69" data-name="Ellipse 69" transform="translate(246 185)" fill="#22a6d6" stroke="#22a6d6" stroke-width="1">
                    <circle cx="12" cy="12" r="12" stroke="none"/>
                    <circle cx="12" cy="12" r="11.5" fill="none"/>
                  </g>
                  <path id="Pfad_785" data-name="Pfad 785" d="M3262,3687.811l3,2.857,7-6.667" transform="translate(-3009.158 -3490.828)" fill="none" stroke="#fff" stroke-width="2"/>
                </g>
              </svg>
            </div>
          :
            ''
        }
        <div className="comment-head">
          <div className="image" style={{backgroundImage: 'url(' + this.state.image + ')'}}></div>
          <div className="column-left">
            {
              this.state.by != ' ' && this.state.by != '' ?
                <div className="line">von <span className="strong">{this.state.by}</span></div>
              :
                ''
            }
            <div className="line">am <span className="strong">{CommonHelper.formatDate(this.props.created)}</span></div>
          </div>
          <div className="column-right">
            {
              this.state.for != ' ' && this.state.for != '' ?
                <div className="line">für <span className="strong">{this.state.for}</span></div>
              :
                ''
            }
            {
              this.props.deadline != ' ' && this.props.deadline != '' ?
                <div className="line">bis <span className="strong">{CommonHelper.formatDate(this.props.deadline)}</span></div>
              :
                ''
            }
          </div>
        </div>
        <div className="comment-body">
          <div className="text">
            {this.props.comment.split('\n').map(function(item, key) {
              return (
                <span key={key}>
                  {item}
                  <br/>
                </span>
              )
            })}
          </div>
          {
            commentFiles.length > 0 && commentFiles.length != '' ?
              <div className="comment-files">
                <div className="strong">Anhang</div>
                {
                  commentFiles.map(file => {
                    return (
                      <File
                        id={file}
                        onDeleteCommentFile={this.props.onDeleteCommentFile}
                        onOpenModal={this.props.onOpenModal}
                      />
                    )
                  })
                }
              </div>
            :
              null
          }
          {
            this.props.for == JSON.parse(sessionStorage.getItem('userId')) && !this.props.checked ?
              <a className="button highlighted round" onClick={this.handleCheck.bind(this)}><span className="label">Erledigt</span></a>
            :
              ''
          }
          {
            this.props.by == JSON.parse(sessionStorage.getItem('userId')) ?
              <a className="delete-comment" onClick={this.handleDelete.bind(this)}>
                <svg id="np_bin_2956146_000000" xmlns="http://www.w3.org/2000/svg" width="13.8" height="15.278" viewBox="0 0 13.8 15.278">
                  <path id="Pfad_872" data-name="Pfad 872" d="M30.393,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,30.393,46.546Z" transform="translate(-26.041 -34.935)" fill="#8800c4"/>
                  <path id="Pfad_873" data-name="Pfad 873" d="M47.065,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,1,0-1.059,0v4.256A.53.53,0,0,0,47.065,46.546Z" transform="translate(-40.165 -34.935)" fill="#8800c4"/>
                  <path id="Pfad_874" data-name="Pfad 874" d="M63.733,46.546a.53.53,0,0,0,.53-.522V41.768a.53.53,0,0,0-1.06,0v4.256A.53.53,0,0,0,63.733,46.546Z" transform="translate(-54.285 -34.935)" fill="#8800c4"/>
                  <path id="Pfad_875" data-name="Pfad 875" d="M18.129,2.565h-3.39L14.3,1.1A1.557,1.557,0,0,0,12.824,0H10.7A1.557,1.557,0,0,0,9.219,1.1L8.783,2.565H5.39a.53.53,0,1,0,0,1.06H6.02v10.2a1.464,1.464,0,0,0,1.461,1.454h8.573a1.465,1.465,0,0,0,1.462-1.462V3.607h.613a.53.53,0,0,0,.53-.53.516.516,0,0,0-.53-.513ZM10.243,1.406h0A.492.492,0,0,1,10.7,1.06h2.142a.472.472,0,0,1,.46.35l.34,1.156H9.877Zm6.213,12.526h-.037a.41.41,0,0,1-.379.276H7.481a.41.41,0,0,1-.4-.409V3.607h9.378Z" transform="translate(-4.859 0)" fill="#8800c4"/>
                </svg>
              </a>
            :
              ''
          }
        </div>
      </div>
    )
  }
}

export default Edit
