import React from 'react'

import InputBase from './InputBase'

class InputText extends InputBase {
  handleChangeField(e) {
    this.changeField(e.target.value)
  }

  html() {
    const {
      name = '',
      label = ''
    } = this.props.field
    const {
      value = ''
    } = this.props

    return(
      <input
        key={this.props.inputKey + '_' + name}
        className="light"
        type="text"
        placeholder={label}
        value={value}
        onChange={this.handleChangeField.bind(this)}
      />
    )
  }
}

export default InputText
