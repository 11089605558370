import React from 'react'
import {Link} from 'react-router-dom';
import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      wbts: [],
      authorNames: {},
      profileImage: '',
      wbtsCount: 0,
      tasksCount: 0,
      tasksCountCompleted : 0,
      items: [],
      comments: [],
      commentsMyCount: 9,
      commentsMyCountCompleted: 5
    }
  }

  componentDidMount() {
    this.getWBTs()
    var profileImage = CmsHelper.getImageUrl({
      file: JSON.parse(sessionStorage.getItem('profileImage')),
      width: 155,
      height: 155
    })
    this.setState({
      profileImage: profileImage
    })
    this.getItems()
    this.loadComments()
  }

  async getItems(id) {
    var response = await CmsHelper.get({
      type: 'wbts',
      filter: {
        type: 'wbt'
      },
      sort: {
        _modified: -1
      }
    })
    this.setState({
      items: response
    })
    var i = 0
    var thisDashboard = this
    this.setState({
      items: response
    })
  }

  async loadComments() {
    var response = await CmsHelper.get({
      type: 'comments',
      filter: {
        for: JSON.parse(sessionStorage.getItem('userId'))
      },
      sort: {
        _created: -1
      }
    })

    var thisTable = this;

    this.setState({
      comments: response
    });
  }

  async getWBTs(id) {
    var response = await CmsHelper.get({
      type: 'wbts',
      filter: {
        created_by: JSON.parse(sessionStorage.getItem('userId')),
        type: 'wbt'
      },
      sort: {
        _modified: -1
      },
      limit: 5
    })
    var i = 0
    var thisDashboard = this
    response.forEach(async function() {
      var response2 = await CmsHelper.get({
        type: 'users',
        id: response[i].created_by
      })
      var name = response2.first_name + ' ' + response2.last_name
      var wbts = thisDashboard.state.wbts
      wbts[i].author = name.trim()
      thisDashboard.setState({
        wbts: wbts
      })
      i++
    })
    this.setState({
      wbts: response
    })
  }

  handleOpenWbtModal() {
    this.props.onOpenModal('add_wbt')
  }

  render() {
    var countMyWBTs = 0
    var itemsIndex = 0
    var items = this.state.items
    items.forEach(function() {
      if(items[itemsIndex].created_by == JSON.parse(sessionStorage.getItem('userId'))) {
        countMyWBTs++
      }
      itemsIndex++
    })
    var completedTasks = 0;
    var openTasks = 0;
    this.state.comments.map((item, index) => {
      if(item.checked) {
        completedTasks++;
      } else {
        openTasks++;
      }
    })
    return(
      <>
        <div id="headerimage">
          <div className="image"></div>
          <div className="headerimage-container">
            <div className="user-dashboard">
              <div className="column">
                <div className="circle">
                  <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.001" viewBox="0 0 16 18.001">
                      <path id="np_trophy_863380_000000" d="M21.453,14a.5.5,0,0,0-.453.5V15H18.453a.5.5,0,0,0-.453.5v1.375a3.636,3.636,0,0,0,3,3.578V20.5a5,5,0,0,0,4.5,4.976V29H22a1.508,1.508,0,0,0-1.5,1.5v1a.5.5,0,0,0,.5.5H31a.5.5,0,0,0,.5-.5v-1A1.508,1.508,0,0,0,30,29H26.5V25.474A5,5,0,0,0,31,20.5v-.047a3.637,3.637,0,0,0,3-3.578V15.5a.5.5,0,0,0-.5-.5H31v-.5a.5.5,0,0,0-.5-.5H21.453ZM22,15h8v5.5a4,4,0,1,1-8,0Zm-3,1h2V19.42a2.6,2.6,0,0,1-2-2.547Zm12,0h2v.875a2.613,2.613,0,0,1-2,2.555ZM22,30h8a.488.488,0,0,1,.5.5V31h-9v-.5A.488.488,0,0,1,22,30Z" transform="translate(-17.999 -13.997)" fill="#fff"/>
                    </svg>
                  </div>
                  <div className="number">{countMyWBTs}</div>
                  <div className="label-bottom">Deine WBTS</div>
                </div>
              </div>
              <div className="column">
                <div className="circle profile-image">
                  <img src={this.state.profileImage} />
                </div>
                <div className="profile-name">{JSON.parse(sessionStorage.getItem('firstName'))} {JSON.parse(sessionStorage.getItem('lastName'))}</div>
              </div>
              <div className="column">
                <div className="circle">
                  <div className="label-top">Offene Aufgaben</div>
                  <div className="number">{openTasks}</div>
                  <div class="completed-to-dos-count">{completedTasks}</div>
                  <div class="completed-to-dos">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="39" viewBox="0 0 35 39">
                      <g id="Gruppe_2067" data-name="Gruppe 2067" transform="translate(-957 -258)">
                        <circle id="Ellipse_71" data-name="Ellipse 71" cx="16" cy="16" r="16" transform="translate(957 265)" fill="#fff"/>
                        <circle id="Ellipse_72" data-name="Ellipse 72" cx="8" cy="8" r="8" transform="translate(976 258)" fill="#22a6d6"/>
                        <path id="Pfad_1141" data-name="Pfad 1141" d="M3262,3687.049l2.4,2.285L3270,3684" transform="translate(-2281.5 -3421.5)" fill="none" stroke="#fff" stroke-width="2"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main" className="dashboard">
          <div className="sections">
            <div className="section">
              <h2>Was möchtest du heute tun?</h2>
              <div className="quick-actions">
                <button
                  className="button quick-action"
                  onClick={this.handleOpenWbtModal.bind(this)}
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 11.5 11.5">
                      <g transform="translate(-446 -5)">
                        <rect width="11.5" height="1.5" transform="translate(446 10)" />
                        <rect width="1.5" height="11.5" transform="translate(451 5)" />
                      </g>
                    </svg>
                  </div>
                  <div className="label">Neues WBT erstellen</div>
                </button>
                <Link
                  to="/wbts"
                  className="button quick-action"
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="31.682" viewBox="0 0 34 31.682">
                      <path d="M11.336,9a.773.773,0,0,0-.7.773v3.091H9.863a.58.58,0,0,0-.072,0,.773.773,0,0,0-.7.773v3.091H8.318a.58.58,0,0,0-.072,0,.773.773,0,0,0-.7.773v3.091H6.772a.58.58,0,0,0-.072,0,.773.773,0,0,0-.7.773V39.909a.773.773,0,0,0,.773.773H39.227A.773.773,0,0,0,40,39.909V21.364a.772.772,0,0,0-.773-.773h-.773V17.5a.772.772,0,0,0-.773-.773h-.773V13.636a.772.772,0,0,0-.773-.773h-.773V9.773A.773.773,0,0,0,34.59,9H11.336Zm.845,1.545H33.818v2.318H12.181Zm-1.545,3.864H35.363v2.318H10.636ZM9.09,18.273H36.909v2.318H9.09ZM7.545,22.136H38.454v17H7.545Z" transform="translate(-5.999 -9)" />
                    </svg>
                  </div>
                  <div className="label">Alle WBTs ansehen</div>
                </Link>
                <Link
                  to="/vorlagen"
                  className="button quick-action"
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                      <path d="M10.362,9.609H43.6v34H9.6v-34Zm10.2,32.362V20.943H11.117V41.971Zm1.512-21.028V41.971h19.9V20.943ZM11.119,19.307H41.97V11.121H11.119Z" transform="translate(-9.605 -9.609)" />
                    </svg>
                  </div>
                  <div className="label">Vorlagen bearbeiten</div>
                </Link>
                <Link
                  to="/mediathek"
                  className="button quick-action"
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                      <g transform="translate(-6 -5.715)">
                        <path d="M37.682,5.715H8.318A2.321,2.321,0,0,0,6,8.033V37.4a2.321,2.321,0,0,0,2.318,2.318H37.682A2.321,2.321,0,0,0,40,37.4V8.033a2.321,2.321,0,0,0-2.318-2.318ZM8.318,38.169a.772.772,0,0,1-.773-.773V28.59L14.3,21.841a.775.775,0,0,1,1.093,0L31.718,38.169ZM38.455,37.4a.772.772,0,0,1-.773.773H33.9L21.977,26.242l9.649-9.649a.772.772,0,0,1,1.093,0l5.737,5.737Zm0-17.253L33.81,15.5a2.323,2.323,0,0,0-3.278,0l-9.649,9.65-4.4-4.4a2.32,2.32,0,0,0-3.278,0L7.545,26.4V8.033a.772.772,0,0,1,.773-.773H37.681a.772.772,0,0,1,.773.773Z" transform="translate(0 0)" />
                        <path d="M22.729,14.844a3.948,3.948,0,1,0,3.948,3.948,3.948,3.948,0,0,0-3.948-3.948Zm0,6.35v0a2.4,2.4,0,1,1,2.4-2.4,2.4,2.4,0,0,1-2.4,2.4Z" transform="translate(-7.842 -5.602)" />
                      </g>
                    </svg>
                  </div>
                  <div className="label">Mediathek verwalten</div>
                </Link>
                <Link
                  to="/hilfe"
                  className="button quick-action"
                >
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                      <g transform="translate(-1 -1)">
                        <path d="M18,33.612A15.612,15.612,0,1,0,2.388,18,15.613,15.613,0,0,0,18,33.612ZM18,35A17,17,0,1,1,35,18,17,17,0,0,1,18,35Z" />
                        <path d="M42.163,36.327a.694.694,0,1,1,0-1.388,2.776,2.776,0,1,0-2.776-2.776.694.694,0,0,1-1.388,0,4.163,4.163,0,1,1,4.163,4.163Z" transform="translate(-24.163 -17.633)" />
                        <path d="M48,48.694a.694.694,0,0,1,1.388,0V52.51a.694.694,0,1,1-1.388,0Z" transform="translate(-30.694 -30.694)" />
                        <path d="M48,68.694a.694.694,0,1,1,1.388,0v.694a.694.694,0,1,1-1.388,0Z" transform="translate(-30.694 -43.755)" />
                      </g>
                    </svg>
                  </div>
                  <div className="label">Hilfe</div>
                </Link>
              </div>
            </div>
            <CommentsTable />
            {
              this.state.wbts.length > 0 ?
                <div className="section">
                  <h2>Deine neusten WBTs</h2>
                  <div className="table wbts-table">
                    <div className="thead">
                      <div className="tr">
                        <div className="td">Kurstitel</div>
                        <div className="td">Zuletzt geändert</div>
                        <div className="td">Erstellt</div>
                        <div className="td">Autor</div>
                      </div>
                    </div>
                    <div className="tbody">
                      {
                        this.state.wbts.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="tr"
                            >
                              <div className="td">
                                <Link
                                  to={'/editor/' + item._id}
                                >
                                  {item.title}
                                </Link>
                              </div>
                              <div className="td">{CommonHelper.formatDate(item._modified)}</div>
                              <div className="td">{CommonHelper.formatDate(item._created)}</div>
                              <div className="td">{item.author}</div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              :
                null
            }
          </div>
        </div>
      </>
    )
  }
}

class CommentsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: []
    }
  }

  componentDidMount() {
    this.loadComments()
  }

  async loadComments() {
    var response = await CmsHelper.get({
      type: 'comments',
      filter: {
        for: JSON.parse(sessionStorage.getItem('userId'))
      },
      sort: {
        _created: -1
      }
    })

    var thisTable = this;

    this.setState({
      comments: response
    });
  }

  render() {
    if(this.state.comments !== undefined && this.state.comments.length > 0) {
      return <div className="section">
        <h2>Deine Aufgaben</h2>
        <div className="table comments-table">
          <div className="thead">
            <div className="tr">
              <div className="td">WBT</div>
              <div className="td">Von</div>
              <div className="td">Am</div>
              <div className="td">Bis</div>
              <div className="td">Aufgabe</div>
              <div className="td">Status</div>
            </div>
          </div>
          <div className="tbody">
            {
              this.state.comments.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="tr"
                  >
                    <div className="td">
                      <Link
                        to={'/editor/' + item.wbt_id + '/comment/' + item.element_id}
                      >
                        <WBTName id={item.wbt_id} />
                      </Link>
                    </div>
                    <div className="td"><UserName id={item.by} /></div>
                    <div className="td">{CommonHelper.formatDate(item._created)}</div>
                    <div className="td">
                      {
                        item.deadline != '' ?
                          CommonHelper.formatDate(item.deadline)
                        :
                          ''
                      }
                    </div>
                    <div className="td"><div class="comment-text">{item.comment}</div></div>
                    <div className="td">
                      {
                        item.checked ?
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Gruppe_2059" data-name="Gruppe 2059" transform="translate(-1009 -893)">
                                <circle id="Ellipse_70" data-name="Ellipse 70" cx="10" cy="10" r="10" transform="translate(1009 893)" fill="#22a6d6"/>
                                <path id="Pfad_785" data-name="Pfad 785" d="M3262,3687.811l3,2.857,7-6.667" transform="translate(-2247.5 -2784.5)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                            </svg>
                          </div>
                        :
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <path id="np_clock_780481_000000" d="M31,21A10,10,0,1,0,41,31,10.011,10.011,0,0,0,31,21Zm0,1.379A8.621,8.621,0,1,1,22.379,31,8.61,8.61,0,0,1,31,22.379Zm0,2.069a.689.689,0,0,0-.69.69V31a.683.683,0,0,0,.2.485l3.653,3.664a.693.693,0,0,0,.981-.981L31.69,30.72V25.138a.689.689,0,0,0-.69-.69Z" transform="translate(-21 -21)" fill="#22a6d6"/>
                            </svg>
                          </div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    } else {
      return null
    }
  }
}

class WBTName extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: ''
    }
  }

  componentDidMount() {
    this.laodName()
  }

  async laodName() {
    var response = await CmsHelper.get({
      type: 'wbts',
      id: this.props.id
    })
    if(response !== undefined) {
      this.setState({
        name: response.title
      });
    }
  }

  render() {
    return this.state.name
  }
}

class UserName extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: ''
    }
  }

  componentDidMount() {
    this.laodName()
  }

  async laodName() {
    var response = await CmsHelper.get({
      type: 'users',
      id: this.props.id
    })

    var name = response.first_name + ' ' + response.last_name

    this.setState({
      name: name.trim()
    });
  }

  render() {
    return this.state.name
  }
}

export default Dashboard
