import React from 'react'
import { DropTarget } from 'react-dnd'

import Tooltip from '../../../../../tooltip/Tooltip'

const elementTarget = {
  drop(props, monitor, component) {
    var item = monitor.getItem()
    var moduleIndex = props.moduleIndex
    var elementIndex = props.elementIndex
    var columnIndex = props.columnIndex
    if(item.elementId !== undefined) {
      var source = {
        moduleId: item.moduleId,
        elementId: item.elementId,
      }
      if(item.columnElementId !== undefined) {
        source.columnId = item.columnId
        source.columnElementId = item.columnElementId
      }
      var target = {
        moduleIndex: moduleIndex,
        elementIndex: elementIndex
      }
      if(columnIndex !== undefined) {
        target.columnIndex = columnIndex
        target.columnElementIndex = 0
      }
      props.onMove(source, target)
    } else {
      if(props.overlayId !== undefined && props.overlayId !== '') {
        if(columnIndex !== undefined) {
          props.onAdd({
            overlayId: props.overlayId,
            elementIndex: elementIndex,
            columnIndex: columnIndex,
            columnElementIndex: 0,
            elementType: monitor.getItem().type
          })
        } else {
          props.onAdd({
            overlayId: props.overlayId,
            elementIndex: 0,
            elementType: monitor.getItem().type
          })
        }
      } else {
        if(columnIndex !== undefined) {
          props.onAdd({
            moduleIndex: moduleIndex,
            elementIndex: elementIndex,
            columnIndex: columnIndex,
            columnElementIndex: 0,
            elementType: monitor.getItem().type
          })
        } else {
          props.onAdd({
            moduleIndex: moduleIndex,
            elementIndex: 0,
            elementType: monitor.getItem().type
          })
        }
      }
    }
  }
}

function elementCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

class ElementEmpty extends React.Component {
  render() {
    return this.props.connectDropTarget(
      <div className="element-empty">
        <div className={'element-empty-container' + (this.props.isOver ? ' is-over' : '')}>
          Ziehe ein Element hierhin
        </div>
        <Tooltip
          tooltip="add_element"
        />
      </div>
    )
  }
}

export default DropTarget(
  ['column-element', 'element'],
  elementTarget,
  elementCollect
)(ElementEmpty)
