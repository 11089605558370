import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ComponentImage from './components/Image'

class ElementSlider extends ElementBase {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0
    }
  }

  slidePrev() {
    var nextActiveSlide = this.state.activeSlide - 1
    if(this.props.element.settings === undefined || this.props.element.settings.slides === undefined) {
      nextActiveSlide = 0
    } else if(nextActiveSlide < 0) {
      nextActiveSlide = this.props.element.settings.slides.length - 1
    }
    this.setState({
      activeSlide: nextActiveSlide
    })
  }

  slideNext() {
    var nextActiveSlide = this.state.activeSlide + 1
    if(this.props.element.settings === undefined || this.props.element.settings.slides === undefined || nextActiveSlide >= this.props.element.settings.slides.length) {
      nextActiveSlide = 0
    }
    this.setState({
      activeSlide: nextActiveSlide
    })
  }

  handleClickDot(index) {
    this.setState({
      activeSlide: index
    })
  }

  html() {
    var slidesContent = []
    if(this.props.element.settings !== undefined && this.props.element.settings.steps !== undefined && this.props.element.settings.steps.length > 0) {
      slidesContent = this.props.element.settings.steps.map((step, index) => {
        return(
          <div
            key={index}
            className={'slide' + (index === this.state.activeSlide ? ' active' : '')}
          >
            <div className="image">
              <ComponentImage
                id={step.settings.image}
              />
            </div>
            <div className="main">
              <div className="headline h2">
                <ElementTextarea
                  {...this.props}
                  fieldName="headline"
                  text={step.settings.headline}
                  placeholder="Titel..."
                  repeaterFieldName="steps"
                  itemId={step.id}
                />
              </div>
              <div className="text">
                <ElementTextarea
                  {...this.props}
                  fieldName="text"
                  text={step.settings.text}
                  placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                  repeaterFieldName="steps"
                  itemId={step.id}
                />
              </div>
            </div>
          </div>
        )
      })
    }
    return(
      <div className="process">
        <div className="slider-content">
          <div className="slides">{slidesContent}</div>
        </div>
        {
          this.props.element.settings !== undefined && this.props.element.settings.steps !== undefined && this.props.element.settings.steps.length > 0 ?
            <div className="dots-wrapper">
              <div className="dots">
                {
                  this.props.element.settings.steps.map((step, index) => {
                    return (
                      <div
                        key={index}
                        className={'dot' + (index === this.state.activeSlide ? ' active' : '')}
                        onClick={this.handleClickDot.bind(this, index)}
                      >
                      </div>
                    )
                  })
                }
              </div>
              <div className="arrow-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="9.201" height="16.849" viewBox="0 0 9.201 16.849">
                  <path id="Rechteck_9_Kopie" data-name="Rechteck 9 Kopie" d="M7.93,16,0,8,7.93,0" transform="translate(8.356 16.427) rotate(180)" fill="none" stroke="#fff" stroke-width="1.2"/>
                </svg>
              </div>
            </div>
          : null
        }
      </div>
    )
  }
}

export default ElementSlider
