import React from 'react'
import {Editor, EditorState, ContentState} from 'draft-js'

class ElementPairsPairsItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      text1Content: EditorState.createWithContent(ContentState.createFromText(this.props.text1)),
      text1: this.props.text1,
      text2Content: EditorState.createWithContent(ContentState.createFromText(this.props.text2)),
      text2: this.props.text2,
      focus: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.state.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.text1 !== this.state.text1) {
        selectionState = this.state.text1Content.getSelection()
        newContentState = ContentState.createFromText(props.text1)
        newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          text1Content: newEditorState,
          text1: props.text1
        })
      }
      if(props.text2 !== this.state.text2) {
        selectionState = this.state.text2Content.getSelection()
        newContentState = ContentState.createFromText(props.text2)
        newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          text2Content: newEditorState,
          text2: props.text2
        })
      }
    }
  }

  handleChangeText1(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      text1Content: textContent,
      text1: text
    })
    this.props.onChangeText1(text)
  }

  handleChangeText2(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      text2Content: textContent,
      text2: text
    })
    this.props.onChangeText2(text)
  }

  handleFocus() {
    this.setState({
      focus: true
    })
  }

  handleBlur() {
    this.setState({
      focus: false
    })
  }

  render() {
    return(
      <>
        <div className="button pair-item no-hover">
          <Editor
            placeholder="Text..."
            editorState={this.state.text1Content}
            onChange={this.handleChangeText1.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        </div>
        <div className="button pair-item no-hover">
          <Editor
            placeholder="Text..."
            editorState={this.state.text2Content}
            onChange={this.handleChangeText2.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        </div>
      </>
    )
  }
}

export default ElementPairsPairsItem
