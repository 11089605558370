import React from 'react'
import {Link, NavLink, useHistory} from 'react-router-dom';
import {getToken, removeUserSession, setUserSession} from '../../Utils/Common';
import CmsHelper from '../../helpers/Cms'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      profileImage: ''
    }
  }

  componentDidMount() {
    var profileImage = CmsHelper.getImageUrl({
      file: JSON.parse(sessionStorage.getItem('profileImage')),
      width: 62,
      height: 62
    })
    this.setState({
      profileImage: profileImage
    })
  }

  handleLogOut() {
    removeUserSession()
  }

  render() {
    return(
      <header id="header">
        <div className="logo">
          <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" width="106.881" height="31.994" viewBox="0 0 106.881 31.994">
              <g transform="translate(-13 -113)">
                <text transform="translate(55.881 136.612)" fontSize="17" fontFamily="DIN2014-Light, 'DIN 2014'" fontWeight="300" letterSpacing="0.08em"><tspan x="0" y="0">eauthor</tspan></text>
                <g transform="translate(13 113)">
                  <g>
                    <path d="M28.557,31.994H3.443A3.446,3.446,0,0,1,0,28.557V3.44A3.446,3.446,0,0,1,3.443,0H28.557A3.446,3.446,0,0,1,32,3.44V28.557a3.446,3.446,0,0,1-3.443,3.436ZM3.443,1.9A1.539,1.539,0,0,0,1.9,3.44V28.557a1.539,1.539,0,0,0,1.539,1.536H28.557a1.539,1.539,0,0,0,1.533-1.536V3.44A1.539,1.539,0,0,0,28.557,1.9Z" />
                    <rect width="8.516" height="1.345" transform="translate(6.054 17.16)" />
                    <path d="M19.08,72.1h1.875V68.432H27.6V67.09l-8.523,0Z" transform="translate(-13.026 -45.802)" />
                    <path d="M57.837,54.08H52.76v1.345h4.95c.749,0,2.494.022,2.494,1.39,0,1.418-1.9,1.4-2.494,1.4H52.773v5.01h1.882V59.563h3.173c2.538,0,4.246-1.028,4.246-2.748S60.375,54.08,57.837,54.08Z" transform="translate(-36.019 -36.92)" />
                    <path d="M76.281,76.46a1.1,1.1,0,1,0,1.1,1.1,1.1,1.1,0,0,0-1.1-1.1Z" transform="translate(-51.325 -52.199)" />
                  </g>
                </g>
              </g>
            </svg>
          </Link>
        </div>
        {
          this.props.page !== 'login' ?
            <>
              <div className="navigation">
                <ul>
                  <li>
                    <NavLink
                      exact
                      to="/"
                      activeClassName="active"
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/wbts"
                      activeClassName="active"
                    >
                    WBTs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/vorlagen"
                      activeClassName="active"
                    >
                    Vorlagen
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/mediathek"
                      activeClassName="active"
                    >
                      Mediathek
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      exact
                      to="/hilfe"
                      activeClassName="active"
                    >
                      Hilfe
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="profile">
                <button className="button highlighted">
                  <div className="image">
                    <img src={this.state.profileImage} />
                  </div>
                </button>
                <div className="profile-menu">
                  <ul>
                    <li>
                      <NavLink
                        exact
                        to="/profil"
                      >
                        <button>
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                              <g transform="translate(-13.34 -14.34)">
                                <path d="M20.34,14.34a7,7,0,1,0,7,7,7.008,7.008,0,0,0-7-7Zm0,13.411a6.383,6.383,0,0,1-4.082-1.471,3.2,3.2,0,0,1,3-2.113h2.152a3.209,3.209,0,0,1,3,2.122,6.382,6.382,0,0,1-4.07,1.462Zm4.535-1.884a3.8,3.8,0,0,0-3.469-2.289H19.253a3.8,3.8,0,0,0-3.459,2.278,6.411,6.411,0,1,1,9.08.011Z" transform="translate(0)" />
                                <path d="M37.569,31.7a2.792,2.792,0,1,0,2.792,2.792A2.8,2.8,0,0,0,37.569,31.7Zm0,4.995a2.2,2.2,0,1,1,2.2-2.2A2.206,2.206,0,0,1,37.569,36.69Z" transform="translate(-17.229 -13.948)" />
                              </g>
                            </svg>
                          </span>
                          <span className="label">Mein Profil</span>
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <Link to="/login">
                        <button
                          onClick={this.handleLogOut.bind(this)}
                        >
                          <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10.535" viewBox="0 0 12 10.535">
                              <g transform="translate(-7.481 -12.672)">
                                <path d="M14.865,22.643H8.045V13.236h6.82a.282.282,0,0,0,0-.564h-7.1a.282.282,0,0,0-.282.282v9.971a.281.281,0,0,0,.282.282h7.1a.282.282,0,0,0,0-.564Z" />
                                <path d="M41.3,33.049l.011-.021a.16.16,0,0,0,.023-.055.033.033,0,0,1,.007-.024.283.283,0,0,0,0-.11.033.033,0,0,0-.007-.024.16.16,0,0,0-.023-.055c0-.007-.007-.014-.011-.021a.271.271,0,0,0-.035-.044l-2.532-2.53a.282.282,0,0,0-.4.4l2.049,2.046H33.224a.282.282,0,1,0,0,.564h7.162L38.34,35.221a.282.282,0,1,0,.4.4l2.53-2.527a.313.313,0,0,0,.037-.044Z" transform="translate(-21.868 -14.953)" />
                              </g>
                            </svg>
                          </span>
                          <span className="label">Abmelden</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          :
            null
        }
      </header>
    )
  }
}

export default Header
