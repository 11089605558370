import React from 'react'
import Select from 'react-select';
import { Form } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize'

import CommonHelper from '../../../../helpers/Common'
import CmsHelper from '../../../../helpers/Cms'
import Modal from './Modal'

const selectStyles = {
  option: (provided, state) => ({
    ...provided
  }),
  control: () => ({
    display: 'flex'
  }),
  singleValue: (provided, state) => {
    return {
      ...provided
    }
  }
}

class ListTableItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      image: '',
      name: ''
    }
  }

  componentDidMount() {
    this.loadImage()
  }

  async loadImage() {
    var user = await CmsHelper.get({
      type: 'users',
      id: this.props.item.created_by
    })
    if(user !== undefined) {
      var name = user.first_name + ' ' + user.last_name
      this.setState({
        name: name.trim()
      })
    }
  }

  toggleMoreOptions(id, e) {
    e.preventDefault()

    this.props.toggleMoreOptions(id)
  }

  handleDuplicate(id, e) {
    e.preventDefault()

    this.props.handleDuplicate(id)
  }

  handleDelete(id, e) {
    e.preventDefault()

    this.props.handleDelete(id)
  }

  render() {
    var date = CommonHelper.formatDate(this.props.item._created)
    return(
      <div
        className="tr"
      >
        <div className="td">
          <button
            onClick={this.props.onClick}
          >
            {this.props.item.title}
          </button>
        </div>
        <div className="td">{CommonHelper.formatDate(this.props.item._created)}</div>
      </div>
    )
  }
}


class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      editAttachment: '',
      editAttachmentImage: '',
      editAttachmentCategories: [],
      editAttachmentTags: [],
      editAttachmentTitle: '',
      editAttachmentDescription: '',
      categories: [],
      tags: [],
      filterSearch: '',
      view: 'teasers',
      order: {
        value: 'last-created',
        label: 'Zuletzt hochgeladen'
      },
      showEditImageModal: false
    }
  }

  componentDidMount() {
    this.getItems()
    this.getCategories()
    this.getTags()
  }

  async getCategories() {
    var response = await CmsHelper.get({
      type: 'categories',
      sort: {
        category: 1
      }
    })
    var categories = []
    response.forEach(function(category, i) {
      categories.push({
        key: category._id,
        value: category._id,
        text: category.category
      })
    })
    this.setState({
      categories: categories
    })
  }

  async getTags() {
    var response = await CmsHelper.get({
      type: 'tags',
      sort: {
        tag: 1
      }
    })
    var tags = []
    response.forEach(function(tag, i) {
      tags.push({
        key: tag._id,
        value: tag._id,
        text: tag.tag
      })
    })
    this.setState({
      tags: tags
    })
  }

  async getItems(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      sort: {
        _created: -1
      }
    })
    this.setState({
      items: response
    })
  }

  editAttachment(id) {
    this.setState({
      editAttachment: id
    })
    this.loadEditAttachment(id)
  }

  async loadEditAttachment(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    var url = CmsHelper.getImageUrl({
      file: response.file.path,
      width: 1600
    })
    response.url = url
    var categories = response.categories !== undefined ? response.categories : []
    var tags = response.tags !== undefined ? response.tags : []
    this.setState({
      editAttachmentImage: response,
      editAttachmentCategories: categories,
      editAttachmentTags: tags,
      editAttachmentTitle: response.custom_title,
      editAttachmentDescription: response.description
    })
  }

  editAttachmentEnd() {
    this.setState({
      editAttachment: ''
    })
  }

  handleOpenAttachmentsModal() {
    this.props.onOpenModal('add_attachments')
  }

  handleDeleteAttachment() {
    this.deleteAttachment(this.state.editAttachment)
    this.editAttachmentEnd()
  }

  async handleSaveAttachment() {
    await CmsHelper.put({
      type: 'attachments',
      id: this.state.editAttachment,
      body: JSON.stringify({
        custom_title: this.state.editAttachmentTitle,
        description: this.state.editAttachmentDescription,
        categories: this.state.editAttachmentCategories,
        tags: this.state.editAttachmentTags
      })
    })
    this.editAttachmentEnd()
  }

  async deleteAttachment(id) {
    var test = await CmsHelper.delete({
      type: 'attachments',
      id: id
    })
    this.getItems()
  }

  handleView(view) {
    this.setState({
      view: view
    })
  }

  handleChangeOrder(option) {
    this.setState({
      order: option
    })
  }

  handleChangeSearch(e) {
    this.setState({
      filterSearch: e.target.value
    })
  }

  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  handleChangeTitle(e) {
    this.setState({
      editAttachmentTitle: e.target.value
    })
  }

  handleChangeDescription(e) {
    this.setState({
      editAttachmentDescription: e.target.value
    })
  }

  handleChangeCategories(e, {value}) {
    this.setState({
      editAttachmentCategories: value
    })
  }

  handleChangeTags(e, {value}) {
    this.setState({
      editAttachmentTags: value
    })
  }

  handleEditImage() {
    this.setState({
      showEditImageModal: true
    })
  }

  setShowEditImageModal(value) {
    this.setState({
      showEditImageModal: value
    })
  }

  render() {
    const {
      filtersCategory,
      filterSearch,
      order,
      view
    } = this.state
    const file = this.state.editAttachmentImage

    var items = this.state.items

    var countMyWBTs = 0
    var itemsIndex = 0
    items.forEach(function() {
      if(items[itemsIndex].created_by == JSON.parse(sessionStorage.getItem('userId'))) {
        countMyWBTs++
      }
      itemsIndex++
    })

    var newItems = []
    var newItemsIndex = 0
    items.forEach(function() {
      var push = true
      var customTitle = items[newItemsIndex].custom_title
      if(!customTitle) {
        customTitle = ''
      }
      var description = items[newItemsIndex].description
      if(!description) {
        description = ''
      }
      if(items[newItemsIndex].title.toLowerCase().indexOf(filterSearch.toLowerCase()) === -1 && customTitle.toLowerCase().indexOf(filterSearch.toLowerCase()) === -1 && description.toLowerCase().indexOf(filterSearch.toLowerCase()) === -1) {
        push = false
      }
      if(push) {
        var newItem = items[newItemsIndex]
        newItems.push(items[newItemsIndex])
      }
      newItemsIndex++
    })

    var orderValue = order.value
    if(orderValue == 'alphabetical') {
      newItems.sort(this.dynamicSort('title'));
    } else if('last-created') {
      newItems.sort(this.dynamicSort('-_created'));
    }

    var size = 0
    var width = 0
    var height = 0
    if(file.file !== undefined) {
      size = parseInt(file.file.size / 1000 + 0.5)
      width = file.file.width
      height = file.file.height
    }

    return(
      <div
        className={'list attachments' + (this.state.editAttachment !== '' ? ' edit-attachment' : '')}
      >
        <div className="filters">
          <button
            className="button plus highlighted round"
            onClick={this.handleOpenAttachmentsModal.bind(this)}
          >
            <span className="icon">+</span>
            <span className="label">Datei hochladen</span>
          </button>

          <div className="main-filters">
            <div className="input input-select search">
              <input
                type="search"
                placeholder="Suchen"
                onChange={this.handleChangeSearch.bind(this)}
              />
              <button>
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10.962" height="10.976" viewBox="0 0 10.962 10.976">
                    <path d="M13.339,12.807a4.04,4.04,0,1,0-.533.533l3.034,3.034a.367.367,0,0,0,.518-.518ZM6.93,10.236A3.306,3.306,0,1,1,7.9,12.573a3.305,3.305,0,0,1-.968-2.337Z" transform="translate(-5.844 -5.844)" strokeWidth="0.65" />
                  </svg>
                </div>
              </button>
            </div>
            <div className="input input-select simple">
              <Select
                options={
                  [
                    {
                      value: 'last-edited',
                      label: 'Zuletzt hochgeladen'
                    },
                    {
                      value: 'alphabetical',
                      label: 'Alphabetisch'
                    }
                  ]
                }
                value={this.state.order}
                styles={selectStyles}
                onChange={this.handleChangeOrder.bind(this)}
              />
            </div>
            <div className="view">
              <span className="label">Darstellung</span>
              <div className="view-buttons">
                <button
                  className={this.state.view === 'teasers' ? 'active' : ''}
                  onClick={this.handleView.bind(this, 'teasers')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17">
                    <g id="Gruppe_752" data-name="Gruppe 752" transform="translate(-636 -220)">
                      <g id="Rechteck_77" data-name="Rechteck 77" transform="translate(636 220)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="11" height="17" stroke="none"/>
                        <rect x="0.5" y="0.5" width="10" height="16" fill="none"/>
                      </g>
                      <line id="Linie_143" data-name="Linie 143" x2="10" transform="translate(636.5 225.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_144" data-name="Linie 144" x2="6" transform="translate(638.5 230.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_147" data-name="Linie 147" x2="6" transform="translate(638.5 228.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_145" data-name="Linie 145" x2="6" transform="translate(638.5 232.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                    </g>
                  </svg>
                </button>
                <button
                  className={this.state.view === 'table' ? 'active' : ''}
                  onClick={this.handleView.bind(this, 'table')}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="17" viewBox="0 0 20.5 17">
                    <g id="Gruppe_751" data-name="Gruppe 751" transform="translate(-635 -215)">
                      <g id="Rechteck_78" data-name="Rechteck 78" transform="translate(635 215)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <g id="Rechteck_79" data-name="Rechteck 79" transform="translate(635 221)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <g id="Rechteck_80" data-name="Rechteck 80" transform="translate(635 227)" fill="none" stroke="#142847" strokeWidth="1">
                        <rect width="5" height="5" stroke="none"/>
                        <rect x="0.5" y="0.5" width="4" height="4" fill="none"/>
                      </g>
                      <line id="Linie_148" data-name="Linie 148" x2="16" transform="translate(639.5 217.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_149" data-name="Linie 149" x2="16" transform="translate(639.5 223.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                      <line id="Linie_150" data-name="Linie 150" x2="16" transform="translate(639.5 229.5)" fill="none" stroke="#142847" strokeWidth="1"/>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="attachments">
          {
            view === 'teasers' ?
              <>
                {
                  newItems.map((item, index) => {
                    var url
                    var ext = item.file.mime
                    if(ext === 'image/jpeg' || ext === 'image/png' || ext === 'image/svg+xml') {
                      url = CmsHelper.getImageUrl({
                        file: item.file.path,
                        width: 320,
                        height: 320
                      })
                    }
                    return(
                      <div
                        key={item._id}
                        className={'attachment' + (ext === 'image/svg+xml' ? ' svg' : '')}
                        style={{backgroundImage: 'url(' + url + ')'}}
                        onClick={this.editAttachment.bind(this, item._id)}
                      >
                        {
                          ext === 'audio/mpeg' || ext === 'audio/x-wav' ?
                            <div className="audio">
                              <div className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="34.788" height="31.63" viewBox="0 0 34.788 31.63">
                                  <path id="Pfad_209" data-name="Pfad 209" d="M15.161,29.552a1.462,1.462,0,0,1-.963-.369h0l-6.468-6.83H1.475A1.475,1.475,0,0,1,0,20.831V12.191a1.475,1.475,0,0,1,1.475-1.475H7.7l6.454-6.9A1.482,1.482,0,0,1,16.643,4.9V28.118a1.462,1.462,0,0,1-.867,1.366,1.489,1.489,0,0,1-.615.068Zm-.41-.977a.683.683,0,0,0,1.072-.505V4.849a.683.683,0,0,0-1.093-.492L8.059,11.536H1.475a.683.683,0,0,0-.683.683v8.612a.683.683,0,0,0,.683.683H8.059Z" transform="translate(0.001 -1.084)" />
                                  <path d="M28.547,25.742a.451.451,0,0,0,.328-.048,6.051,6.051,0,0,0,.983-.813,5.648,5.648,0,0,0,0-7.977,5.511,5.511,0,0,0-1.045-.826.4.4,0,0,0-.225-.068h-.1a.4.4,0,0,0-.266.2.41.41,0,0,0-.055.321.423.423,0,0,0,.2.273,4.8,4.8,0,0,1,.881,7.472,5.136,5.136,0,0,1-.826.683.4.4,0,0,0-.191.266.423.423,0,0,0,.055.328A.444.444,0,0,0,28.547,25.742Z" transform="translate(-8.925 -5.074)" />
                                  <path d="M38.7,18.375A11.692,11.692,0,0,0,32.435,7.994a.4.4,0,0,0-.2,0h-.13a.458.458,0,0,0-.26.219.43.43,0,0,0,.184.581,10.818,10.818,0,0,1,2.636,17.224,10.641,10.641,0,0,1-2.616,1.933.41.41,0,0,0-.212.253.423.423,0,0,0,.034.328.41.41,0,0,0,.253.212.451.451,0,0,0,.328,0h0a11.863,11.863,0,0,0,2.827-2.083A11.61,11.61,0,0,0,38.7,18.375Z" transform="translate(-10.081 -2.529)" />
                                  <path d="M36.793,31.559A17.846,17.846,0,0,0,36.69.047.4.4,0,0,0,36.458,0a.437.437,0,0,0-.382.232.423.423,0,0,0-.034.328.41.41,0,0,0,.212.253,17.17,17.17,0,0,1,4.1,3.032,16.978,16.978,0,0,1-3.975,26.95.444.444,0,0,0-.212.26.423.423,0,0,0,0,.328.437.437,0,0,0,.628.178Z" transform="translate(-11.42 0.003)" />
                                </svg>
                              </div>
                              <div className="label">{item.title}</div>
                            </div>
                          :
                            null
                        }
                        {
                          ext === 'video/mp4' ?
                            <div className="video">
                              <div className="label">{item.title}</div>
                            </div>
                          :
                            null
                        }
                      </div>
                    )
                  })
                }
              </>
            :
              <div className="table wbts-table">
                <div className="thead">
                  <div className="tr">
                    <div className="td">Dateiname</div>
                    <div className="td">Hochgeladen am</div>
                  </div>
                </div>
                <div className="tbody">
                  {
                    newItems.map((item, index) => {
                      return (
                        <ListTableItem
                          key={index}
                          item={item}
                          onClick={this.editAttachment.bind(this, item._id)}
                        />
                      )
                    })
                  }
                </div>
              </div>
          }
        </div>
        <div className="attachment-info-box">
          <button
            className="button close highlighted"
            onClick={this.editAttachmentEnd.bind(this)}
          >
            <span className="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
                <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
              </svg>
            </span>
          </button>
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'image/jpeg' || file.file.mime === 'image/png') ?
              <div
                className="image"
              >
                {
                  file !== undefined && file.url !== '' ?
                    <div
                      className="img"
                      style={{backgroundImage: 'url(' + file.url + ')'}}
                    >
                    </div>
                  :
                    null
                }
              </div>
            :
              ''
          }
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'video/mp4') ?
              <div
                className="video"
              >
                <video
                  src={
                    CmsHelper.getDirectUrl({
                      file: file.file.path
                    })
                  }
                  controls
                >
                </video>
              </div>
            :
              ''
          }
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'audio/mpeg' || file.file.mime === 'audio/x-wav') ?
              <div
                className="audio"
              >
                <audio controls>
                  <source
                    src={
                      CmsHelper.getDirectUrl({
                        file: file.file.path
                      })
                    }
                    type={file.file.mime}
                  />
                </audio>
              </div>
            :
              ''
          }
          {
            file !== undefined && file.url !== '' ?
              <div className="info">
                <div className="headline">{file.title}</div>
                {
                  width !== undefined && height !== undefined ?
                    <div className="top-buttons">
                      <button
                        className="button white transparent round"
                        onClick={this.handleEditImage.bind(this)}
                      >
                        <span className="label">Bild bearbeiten</span>
                      </button>
                    </div>
                  : null
                }
                <div className="key-info">
                  <div className="column">
                    <div className="label">Hochgeladen</div>
                    <div className="text">
                      {
                        CommonHelper.formatDate(file._created)
                      }
                    </div>
                  </div>
                  <div className="column">
                    <div className="label">Dateigröße</div>
                    <div className="text">{size + ' KB'}</div>
                  </div>
                  {
                    width !== undefined && height !== undefined ?
                      <div className="column">
                        <div className="label">Dimensionen</div>
                        <div className="text">{width + ' x ' + height}</div>
                      </div>
                    :
                      ''
                  }
                </div>
                <div className="inputs">
                  <div className="input w50">
                    <Form.Select
                      className="light"
                      options={this.state.categories}
                      value={this.state.editAttachmentCategories}
                      multiple
                      placeholder="Kategorien"
                      onChange={this.handleChangeCategories.bind(this)}
                    />
                  </div>
                  <div className="input w50">
                    <Form.Select
                      className="light"
                      options={this.state.tags}
                      value={this.state.editAttachmentTags}
                      multiple
                      placeholder="Tags"
                      onChange={this.handleChangeTags.bind(this)}
                    />
                  </div>
                  <div className="input">
                    <input
                      className="light"
                      type="text"
                      placeholder="Titel"
                      value={this.state.editAttachmentTitle}
                      onChange={this.handleChangeTitle.bind(this)}
                    />
                  </div>
                  <div className="input">
                    <TextareaAutosize
                      className="light"
                      placeholder="Beschreibung"
                      value={this.state.editAttachmentDescription}
                      onChange={this.handleChangeDescription.bind(this)}
                    />
                  </div>
                </div>
              </div>
            :
              null
          }
          <div className="bottom-buttons">
            <button
              className="button white transparent round"
              onClick={this.handleDeleteAttachment.bind(this)}
            >
              <span className="label">Endgültig löschen</span>
            </button>
            <button
              className="button white highlighted round"
              onClick={this.handleSaveAttachment.bind(this)}
            >
              <span className="label">Speichern</span>
            </button>
          </div>
        </div>
        <Modal
          type="edit_image"
          show={this.state.showEditImageModal}
          options={{
            fileId: file?.file?._id,
            imageUrl: file.url
          }}
          onClose={this.setShowEditImageModal.bind(this, false)}
        />
      </div>
    )
  }
}

export default List
