import React from 'react'
import { Form } from 'semantic-ui-react';
import {withRouter} from 'react-router'
import axios from 'axios';
import TextareaAutosize from 'react-textarea-autosize'
import xliff from 'xliff'

import InputImage from './WindowSettingsInputImage'

import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'
import ConfigHelper from '../../../helpers/Config'
import Audio from './Audio'
import InputAudio from './InputAudio'

var sanitize = require("sanitize-filename");

class WindowSettingsTabLanguagesTabAudio extends React.Component {
  constructor(props) {
    super(props)

    this.secondaryLang = ''

    this.state = {
      originalStrings: [],
      translations: this.props.wbt.settings.translatedStrings,
      secondaryLang: ''
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(this.state.secondaryLang == '' && props.modalArgs.stringLang !== undefined) {
      this.setState({
        secondaryLang: props.modalArgs.stringLang
      }, () => {
        this.changeValue(props.modalArgs.stringLang, props.modalArgs.stringKey, props.modalArgs.stringValue)
      })
    }
  }

  componentDidMount() {
    this.loadOriginalStrings();
  }

  loadOriginalStrings() {
    var fields = []
    this.props.wbt.modules.map((module) => {
      module.elements.map((element) => {
        var type = element.type
        var elementConfig = ConfigHelper.getElementConfig(type)
        if(elementConfig.settings.sections !== undefined) {
          elementConfig.settings.sections.map((section) => {
            if(section.fields !== undefined) {
              section.fields.map((sectionField) => {
                if(sectionField.type == 'audio') {
                  if(element.settings[sectionField.name] != '') {
                    var field = {};
                    field.type = sectionField.type;
                    field.name = '';
                    field.key = element.id + '_' + sectionField.name;
                    field.value = element.settings[sectionField.name];
                    fields.push(field);
                  }
                }
              })
            }
          })
        }
      })
    });
    this.setState({
      originalStrings: fields
    }, () => {
      this.loadTranslations();
    })
  }

  loadTranslations() {
    var translationStrings = [];
    this.state.originalStrings.map((string) => {
      translationStrings.push({
        key: string.key,
        value: ''
      })
    });
    var translations = {};
    if(this.state.secondaryLang !== '') {
      translations[this.state.secondaryLang] = translationStrings;
      this.setState({
        translations: translations
      })
    }
  }

  handleChangeAudio(lang, key, value) {
    this.changeValue(lang, key, value)
  }

  changeValue(lang, key, value) {
    if(this.state.secondaryLang != '') {
      var translations = this.state.translations;
      var translationSet = translations[lang]
      if(translationSet == undefined) {
        var translationStrings = [];
        this.state.originalStrings.map((string) => {
          translationStrings.push({
            key: string.key,
            value: ''
          })
        });
        translations[this.state.secondaryLang] = translationStrings;
        translationSet = translations[lang]
      }
      var translation = translationSet.find(x => x.key === key);
      if(translation == undefined) {
        var nTranslationStrings = translations[this.state.secondaryLang];
        nTranslationStrings.push({
          key: key,
          value: ''
        })
        translationSet = nTranslationStrings
        translation = translationSet.find(x => x.key === key);
      }
      let index = translationSet.indexOf(translation);
      translationSet[index].value = value;
      translations[lang] = translationSet;
      this.setState({
        translations: translations
      }, () => {
        this.props.onChangeTranslation(translations);
      });
    }
  }

  handleChangeSecondaryLang(e, {value}) {
    this.setState({
      secondaryLang: value
    })
  }

  onChangeFile(e) {
    e.stopPropagation();
    e.preventDefault();
    var file = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = e => {
      var xliffCode = e.target.result;
      xliff.xliff12ToJs(xliffCode, (err, res) => {
        if(res.resources !== undefined && res.resources[this.props.wbt.id] !== undefined) {
          var strings = res.resources[this.props.wbt.id];
          this.state.originalStrings.map((oString) => {
            var key = oString.key;
            if(strings[key] !== undefined) {
              this.changeValue(this.state.secondaryLang, key, strings[key].target)
            }
          });
        } else {
          alert('Ungültige WBT-ID!');
        }
      })
    };
    e.target.value = null;
  }

  render() {
    let dLanguages = this.props.defaultLanguageOptions.find(x => x.key === this.props.defaultLanguage);
    let dIndex = this.props.defaultLanguageOptions.indexOf(dLanguages);
    var defaultLangShort = ''
    if(this.props.defaultLanguageOptions[dIndex] !== undefined) {
      defaultLangShort = this.props.defaultLanguageOptions[dIndex].text;
    }
    var selectedLanguageOptions = [];
    if(this.props.defaultLanguageOptions !== undefined) {
      this.props.defaultLanguageOptions.map((option) => {
        if(this.props.selectedLanguageOptions.includes(option.key) && option.key != this.props.defaultLanguage) {
          selectedLanguageOptions.push(option)
        }
      });
    }
    return(
      <>
        <div className="table">
          <div className="table-head">
            <div className="row">
              <div className="column">Element</div>
              <div className="column">{defaultLangShort}</div>
              <div className="column">
                <Form.Select
                  options={selectedLanguageOptions}
                  value={this.state.secondaryLang}
                  placeholder="Sprache"
                  onChange={this.handleChangeSecondaryLang.bind(this)}
                />
              </div>
            </div>
          </div>
          {
            this.state.originalStrings.length > 0 ?
              <div className="table-body">
                {
                  this.state.originalStrings.map((string) => {
                    var translatedValue = '';
                    var translationSet = this.state.translations[this.state.secondaryLang];
                    if(translationSet !== undefined) {
                      let translation = translationSet.find(x => x.key === string.key);
                      let index = translationSet.indexOf(translation);
                      if(translatedValue = translationSet[index] !== undefined) {
                        translatedValue = translationSet[index].value;
                      }
                    }
                    return (
                      <div className="row" key={string.key + '_' + this.state.secondaryLang}>
                        <div className="column">
                          {
                            string.name != '' ?
                              string.name
                            :
                              ''
                          }
                          {
                            string.name == '' && string.type == 'audio' ?
                              'Audio'
                            :
                              ''
                          }
                        </div>
                        <div className="column"><Audio id={string.value} /></div>
                        <div className="column" style={this.state.secondaryLang == '' ? {display: 'none'} : {}}>
                          <InputAudio
                            {...this.props}
                            value={translatedValue}
                            stringKey={string.key}
                            stringLang={this.state.secondaryLang}
                            onChangeField={this.handleChangeAudio.bind(this, this.state.secondaryLang, string.key)}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            :
              null
          }
        </div>
      </>
    )
  }
}

export default WindowSettingsTabLanguagesTabAudio
