import React from 'react'

const CONFIG_MODULE = {
  settings: {
    sections: [
      {
        headline: 'Überschrift',
        fields: [
          {
            type: 'switch',
            name: 'headline_show',
            label: 'Anzeigen',
            defaultValue: true
          },
          {
            type: 'textarea',
            name: 'headline',
            label: 'Headline'
          }
        ]
      },
      {
        headline: 'Hintergrund',
        fields: [
          {
            type: 'switch',
            name: 'highlighted',
            label: 'Hervorheben'
          }
        ]
      },
      {
        headline: 'Titel',
        type: 'motivation',
        fields: [
          {
            type: 'switch',
            name: 'motivation_show',
            label: 'Anzeigen'
          },
          {
            type: 'text',
            name: 'motivation_headline',
            label: 'Titel'
          },
          {
            type: 'wysiwyg',
            name: 'motivation_text',
            label: 'Beschreibung'
          }
        ]
      },
      {
        headline: 'Animation',
        type: 'motivation',
        fields: [
          {
            type: 'select',
            name: 'motivation_animation',
            label: 'Animation',
            options: [
              {
                value: 'star',
                name: 'Stern'
              },
              {
                value: 'success',
                name: 'Erfolg'
              },
              {
                value: 'custom_image',
                name: 'Benutzerdefiniertes Bild'
              }
            ]
          },
          {
            type: 'image',
            name: 'motivation_image',
            label: 'Bild'
          },
        ]
      }
    ]
  }
}

export default CONFIG_MODULE
