import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementMultipleChoiceAnswersList from './ElementMultipleChoiceAnswersList'
import ElementText from './components/ElementText'

import ElementHotspotsPOIs from './ElementHotspotsPOIs'
import ComponentImage from './components/Image'

class ElementMultipleChoice extends ElementBase {
  handleChangeAnswers(args) {
    var answers = this.props.element.settings.answers
    answers.map((answer, index) => {
      if(answer.id === args.itemId) {
        answers[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'answers',
      fieldValue: answers
    })
  }

  handleChangeBoxHeadline(headline, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_headline',
      fieldValue: headline
    })
  }

  handleChangeBoxText(text, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_text',
      fieldValue: text
    })
  }

  html() {
    return(
      <div className="chat-simulator">
        <div className="chat-history">
          {
            this.props.element.settings.messages.map(message => {
              return (
                <div className="chat-section">
                  <div className="chat-message">
                    <div className="avatar">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                        <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                          <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.5" fill="none" />
                          </g>
                          <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                            <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                            <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="message">
                      {
                        message.settings.image ?
                          <ComponentImage
                            id={message.settings.image}
                          />
                        : null
                      }
                      <ElementText
                        {...this.props}
                        fieldName="text"
                        text={message.settings.text}
                        placeholder="Titel..."
                        repeaterFieldName="messages"
                        itemId={message.id}
                      />
                    </div>
                  </div>
                  <div className="chat-message chat-message-me">
                    <div className="avatar">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                        <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                          <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.5" fill="none" />
                          </g>
                          <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                            <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                            <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="answers">
                      {
                        message.settings.answer_1 ? (
                          <div class="answer">
                            <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_1'} value="1" />
                            <label className="message" htmlFor={'radio_' + message.id + '_1'}>
                              <ElementText
                                {...this.props}
                                fieldName="answer_1"
                                text={message.settings.answer_1}
                                placeholder="Titel..."
                                repeaterFieldName="messages"
                                itemId={message.id}
                              />
                              <div className="check-mark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                  <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                </svg>
                              </div>
                            </label>
                          </div>
                        ) : ''
                      }
                      {
                        message.settings.answer_2 ? (
                          <div class="answer">
                            <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_2'} value="2" />
                            <label className="message" htmlFor={'radio_' + message.id + '_2'}>
                              <ElementText
                                {...this.props}
                                fieldName="answer_2"
                                text={message.settings.answer_2}
                                placeholder="Titel..."
                                repeaterFieldName="messages"
                                itemId={message.id}
                              />
                              <div className="check-mark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                  <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                </svg>
                              </div>
                            </label>
                          </div>
                        ) : ''
                      }
                      {
                        message.settings.answer_3 ? (
                          <div class="answer">
                            <input type="radio" name={'radio_' + message.id} id={'radio_' + message.id + '_3'} value="3" />
                            <label className="message" htmlFor={'radio_' + message.id + '_3'}>
                              <ElementText
                                {...this.props}
                                fieldName="answer_3"
                                text={message.settings.answer_3}
                                placeholder="Titel..."
                                repeaterFieldName="messages"
                                itemId={message.id}
                              />
                              <div className="check-mark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                  <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                                </svg>
                              </div>
                            </label>
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default ElementMultipleChoice
