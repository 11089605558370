import React from 'react'
import {Editor, EditorState, ContentState} from 'draft-js'

class ElementText extends React.Component {
  constructor(props) {
    super(props)

    var text = (this.props.text !== undefined ? this.props.text : '')
    this.state = {
      textContent: EditorState.createWithContent(ContentState.createFromText(text)),
      text: text,
      focus: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.state.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.text !== undefined && props.text !== this.state.text) {
        selectionState = this.state.textContent.getSelection()
        newContentState = ContentState.createFromText(props.text)
        newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          textContent: newEditorState,
          text: props.text
        })
      }
    }
  }

  handleChange(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      textContent: textContent,
      text: text
    })
    var fieldName
    var fieldValue
    if(this.props.itemId !== undefined) {
      var items = this.props.element.settings[this.props.repeaterFieldName]
      items.map((item, index) => {
        if(item.id === this.props.itemId) {
          items[index].settings[this.props.fieldName] = text
        }
        return null
      })
      fieldName = this.props.repeaterFieldName
      fieldValue = items
    } else {
      fieldName = this.props.fieldName
      fieldValue = text
    }
    this.props.onChangeField({
      moduleId: this.props.module.id,
      elementId: this.props.element.id,
      fieldName: fieldName,
      fieldValue: fieldValue
    })
  }

  handleFocus() {
    this.setState({
      focus: true
    })
  }

  handleBlur() {
    this.setState({
      focus: false
    })
  }

  render() {
    return(
      <div className="element-text">
        <Editor
          placeholder={this.props.placeholder}
          editorState={this.state.textContent}
          onChange={this.handleChange.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
      </div>
    )
  }
}

export default ElementText
