import React from 'react'
import {withRouter} from 'react-router'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.url = window.location.protocol + "//" + window.location.host + "/preview/" + this.props.modalData.id
  }

  copyToClipboard = (e) => {
    this.url.select();
    document.execCommand('copy');
    e.target.focus();
  };

  render() {
    return(
      <div className="modal-window external-link">
        <div className="headline">Externer Link</div>
        <div className="main">
          <input
            ref={(url) => this.url = url}
            type="text"
            value={this.url}
          />
          <button
            className="button highlighted round"
            onClick={this.copyToClipboard}
          >
            <span className="label">In Zwischenablage kopieren</span>
          </button>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
