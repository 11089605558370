import React from 'react'

import InputBase from './InputBase'

class InputTeaserTypes extends InputBase {
  handleChangeField(e) {
    this.changeField(e.target.name)
  }

  html() {
    const {
      label = ''
    } = this.props.field
    const {
      value = ''
    } = this.props

    return(
      <div className="input input-teaser-types">
        <div className="label">{label}</div>
        <div className="teaser-types">
          <button
            className={'teaser-type' + (value === 'small-image-text' ? ' selected' : '')}
            name="small-image-text"
            onClick={this.handleChangeField.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="36" viewBox="0 0 25 36">
              <g transform="translate(-49 -731)">
                <g transform="translate(49 731)" fill="none" stroke="#fff" strokeWidth="0.5">
                  <rect width="25" height="36" stroke="none" />
                  <rect x="0.25" y="0.25" width="24.5" height="35.5" fill="none" />
                </g>
                <g transform="translate(49 731)" fill="none" stroke="#fff" strokeWidth="0.5">
                  <rect width="25" height="12" stroke="none" />
                  <rect x="0.25" y="0.25" width="24.5" height="11.5" fill="none" />
                </g>
                <path d="M12350.96,893.643h18.607" transform="translate(-12299 -147)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12299 -143)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12299 -139)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12299 -135)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12299 -131)" strokeWidth="0.5" />
                <path d="M12372.553,878.4l-24.014,11.175" transform="translate(-12299 -147)" strokeWidth="0.5" />
                <path d="M12348.539,878.4l24.014,11.175" transform="translate(-12299 -147)" strokeWidth="0.5" />
              </g>
            </svg>
          </button>
          <button
            className={'teaser-type' + (value === 'large-image-text' ? ' selected' : '')}
            name="large-image-text"
            onClick={this.handleChangeField.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="36" viewBox="0 0 25 36">
              <g transform="translate(-130 -427)">
                <g transform="translate(130 427)" fill="none" stroke="#fff" strokeWidth="0.5">
                  <rect width="25" height="36" stroke="none" />
                  <rect x="0.25" y="0.25" width="24.5" height="35.5" fill="none" />
                </g>
                <path d="M12348.539,878.4l24.014,35.445" transform="translate(-12218 -451)" strokeWidth="0.5" />
                <path d="M12372.553,878.4l-24.014,35.445" transform="translate(-12218 -451)" strokeWidth="0.5" />
                <rect width="17" height="9" transform="translate(134 440)" fill="#2b2f4d" />
                <path d="M12350.96,893.643h12.717" transform="translate(-12215 -451)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h12.717" transform="translate(-12215 -447)" strokeWidth="0.5" />
              </g>
            </svg>
          </button>
          <button
            className={'teaser-type' + (value === 'text' ? ' selected' : '')}
            name="text"
            onClick={this.handleChangeField.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="36" viewBox="0 0 25 36">
              <g transform="translate(-210 -427)">
                <g transform="translate(210 427)" strokeWidth="0.5">
                  <rect width="25" height="36" stroke="none" />
                  <rect x="0.25" y="0.25" width="24.5" height="35.5" fill="none" />
                </g>
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -451)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -459)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -463)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -447)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -455)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -443)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -439)" strokeWidth="0.5" />
                <path d="M12350.96,893.643h18.607" transform="translate(-12138 -435)" strokeWidth="0.5" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

export default InputTeaserTypes
