import React from 'react'
import {Editor, EditorState, ContentState} from 'draft-js'

class ElementMultipleChoiceAnswersItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textContent: EditorState.createWithContent(ContentState.createFromText(this.props.text)),
      text: this.props.text,
      focus: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.state.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.text !== this.state.text) {
        selectionState = this.state.textContent.getSelection()
        newContentState = ContentState.createFromText(props.text)
        newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          textContent: newEditorState,
          text: props.text
        })
      }
    }
  }

  handleChangeText(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      textContent: textContent,
      text: text
    })
    this.props.onChangeText(text)
  }

  handleFocus() {
    this.setState({
      focus: true
    })
  }

  handleBlur() {
    this.setState({
      focus: false
    })
  }

  render() {
    return(
      <>
        <div className="button answer-item no-hover">
          <Editor
            placeholder="Text..."
            editorState={this.state.textContent}
            onChange={this.handleChangeText.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
        </div>
      </>
    )
  }
}

export default ElementMultipleChoiceAnswersItem
