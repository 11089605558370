import React from 'react'

import ElementBase from './ElementBase'
import ElementWYSIWYG from './components/ElementWYSIWYG'

class ElementText extends ElementBase {
  html() {
    var placeholder
    if(this.props.column !== undefined) {
      placeholder = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.'
    } else {
      placeholder = 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
    }
    return(
      <div className="text">
        <ElementWYSIWYG
          {...this.props}
          fieldName="text"
          text={this.props.element.settings.text}
          placeholder={placeholder}
        />
      </div>
    )
  }
}

export default ElementText
