import React from 'react'
import ReactDOM from 'react-dom'
import { DropTarget } from 'react-dnd'

import ElementBase from './ElementBase'
import ElementColumns from './ElementColumns'
import ElementText from './ElementText'
import ElementImage from './ElementImage'
import ElementAudio from './ElementAudio'
import ElementVideo from './ElementVideo'
import ElementIntroslider from './ElementIntroslider'
import ElementInstruction from './ElementInstruction'
import ElementTeasers from './ElementTeasers'
import ElementHotspots from './ElementHotspots'
import ElementSlider from './ElementSlider'
import ElementProcess from './ElementProcess'
import ElementLenticularSlider from './ElementLenticularSlider'
import ElementAccordion from './ElementAccordion'
import ElementTabs from './ElementTabs'
import ElementDragAndDrop from './ElementDragAndDrop'
import ElementMultipleChoice from './ElementMultipleChoice'
import ElementFreeTextField from './ElementFreeTextField'
import ElementPairs from './ElementPairs'
import ElementSlideControl from './ElementSlideControl'
import ElementProfile from './ElementProfile'
import ElementHotspotsQuiz from './ElementHotspotsQuiz'
import ElementChatSimulator from './ElementChatSimulator'
import ElementButton from './ElementButton'
import ElementScrollableText from './ElementScrollableText'
import ElementFlashcards from './ElementFlashcards'
import ElementImageZoom from './ElementImageZoom'
import ElementInteractiveVideo from './ElementInteractiveVideo'

const target = {
  canDrop(props, monitor) {
    if(monitor.getItem().itemType === 'module' || (monitor.getItem().itemType === 'element' && props.columnElementIndex !== undefined)) {
      return false
    } else {
      return true
    }
  },
  hover(props, monitor, component) {
    var componentRect = ReactDOM.findDOMNode(component).getBoundingClientRect()
    var x = monitor.getClientOffset().x - componentRect.x
    var y = monitor.getClientOffset().y - componentRect.y
    if(x <= componentRect.width / 10 && props.elementItemType === 'column-element' && monitor.getItem().itemType === 'column-element') {
      if(component.state.isDragOver !== 'left') {
        component.setState({
          isDragOver: 'left'
        })
      }
    } else if(x >= componentRect.width / 10 * 9 && props.elementItemType === 'column-element' && monitor.getItem().itemType === 'column-element') {
      if(component.state.isDragOver !== 'right') {
        component.setState({
          isDragOver: 'right'
        })
      }
    } else {
      if(y < componentRect.height / 2) {
        if(component.state.isDragOver !== 'top') {
          component.setState({
            isDragOver: 'top'
          })
        }
      } else {
        if(component.state.isDragOver !== 'bottom') {
          component.setState({
            isDragOver: 'bottom'
          })
        }
      }
    }
  },
  drop(props, monitor, component) {
    if(monitor.canDrop()) {
      var item = monitor.getItem()
      var moduleIndex = props.moduleIndex
      var elementIndex = props.elementIndex
      var columnIndex = props.columnIndex
      var columnElementIndex = props.columnElementIndex
      if(component.state.isDragOver === 'bottom') {
        if(columnElementIndex !== undefined) {
          columnElementIndex += 1
        } else {
          elementIndex += 1
        }
      }
      if(item.elementId !== undefined) {
        var source = {
          moduleId: item.moduleId,
          elementId: item.elementId,
        }
        if(item.columnElementId !== undefined) {
          source.columnId = item.columnId
          source.columnElementId = item.columnElementId
        }
        var target = {
          moduleIndex: moduleIndex,
          elementIndex: elementIndex
        }
        if(component.state.isDragOver === 'left' || component.state.isDragOver === 'right') {
          target.newColumn = component.state.isDragOver
        }
        if(columnElementIndex !== undefined) {
          target.columnIndex = columnIndex
          target.columnElementIndex = columnElementIndex
        }
        props.onMove(source, target)
      } else {
        var args = {
          elementIndex: elementIndex,
          elementType: monitor.getItem().type
        }
        if(props.overlayId !== undefined && props.overlayId !== '') {
          args.overlayId = props.overlayId
        } else {
          args.moduleIndex = moduleIndex
        }
        if(component.state.isDragOver === 'left' || component.state.isDragOver === 'right') {
          args.newColumn = component.state.isDragOver
        }
        if(columnElementIndex !== undefined) {
          args.columnIndex = columnIndex
          args.columnElementIndex = columnElementIndex
        }
        props.onAdd(args)
      }
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver({
      shallow: (monitor.getItemType() === 'column-element')
    })
  }
}

class Element extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      isDragOver: false
    }
  }

  render() {
    var props = {}
    props.isDragOver = (this.props.isOver && this.props.canDrop) ? this.state.isDragOver : false
    switch(this.props.element.type) {
      case 'columns':
        return this.props.connectDropTarget(
          <div>
            <ElementColumns
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'text':
        return this.props.connectDropTarget(
          <div>
            <ElementText
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'image':
        return this.props.connectDropTarget(
          <div>
            <ElementImage
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'image_zoom':
        return this.props.connectDropTarget(
          <div>
            <ElementImageZoom
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'audio':
        return this.props.connectDropTarget(
          <div>
            <ElementAudio
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'video':
        return this.props.connectDropTarget(
          <div>
            <ElementVideo
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'introslider':
        return this.props.connectDropTarget(
          <div>
            <ElementIntroslider
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'instruction':
        return this.props.connectDropTarget(
          <div>
            <ElementInstruction
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'teasers':
        return this.props.connectDropTarget(
          <div>
            <ElementTeasers
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'hotspots':
        return this.props.connectDropTarget(
          <div>
            <ElementHotspots
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'slider':
        return this.props.connectDropTarget(
          <div>
            <ElementSlider
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'process':
        return this.props.connectDropTarget(
          <div>
            <ElementProcess
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'lenticular_slider':
        return this.props.connectDropTarget(
          <div>
            <ElementLenticularSlider
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'accordion':
        return this.props.connectDropTarget(
          <div>
            <ElementAccordion
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'tabs':
        return this.props.connectDropTarget(
          <div>
            <ElementTabs
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'drag_and_drop':
        return this.props.connectDropTarget(
          <div>
            <ElementDragAndDrop
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'multiple_choice':
        return this.props.connectDropTarget(
          <div>
            <ElementMultipleChoice
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'free_text_field':
        return this.props.connectDropTarget(
          <div>
            <ElementFreeTextField
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'pairs':
        return this.props.connectDropTarget(
          <div>
            <ElementPairs
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'slide_control':
        return this.props.connectDropTarget(
          <div>
            <ElementSlideControl
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'profile':
        return this.props.connectDropTarget(
          <div>
            <ElementProfile
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'hotspots_quiz':
        return this.props.connectDropTarget(
          <div>
            <ElementHotspotsQuiz
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'chat_simulator':
        return this.props.connectDropTarget(
          <div>
            <ElementChatSimulator
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'button':
        return this.props.connectDropTarget(
          <div>
            <ElementButton
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'scrollable_text':
        return this.props.connectDropTarget(
          <div>
            <ElementScrollableText
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'flashcards':
        return this.props.connectDropTarget(
          <div>
            <ElementFlashcards
              {...this.props}
              {...props}
            />
          </div>
        )
      case 'interactive_video':
        return this.props.connectDropTarget(
          <div>
            <ElementInteractiveVideo
              {...this.props}
              {...props}
            />
          </div>
        )
      default:
        return this.props.connectDropTarget(
          <div>
            <ElementBase
              {...this.props}
              {...props}
            />
          </div>
        )
    }
  }
}

export default DropTarget(
  ['element', 'column-element'],
  target,
  collect
)(Element)
