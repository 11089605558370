import React from 'react'

import Field from '../Field'
import InputBase from './InputBase'

import ConfigHelper from '../../../../../../helpers/Config'

class InputColumnElements extends InputBase {
  html() {
    const elements = this.props.value.map((columnElement, columnElementIndex) => {
      var elementConfig = ConfigHelper.getElementConfig(columnElement.type)
      var sections = elementConfig.settings.sections.map((section, sectionIndex) => {
        var fields = section.fields.map((field, fieldIndex) => {
          var value = columnElement.settings[field.name]
          return(
            <Field
              key={this.props.inputKey + '_' + columnElement.id + '_' + field.name}
              {...this.props}
              inputKey={this.props.inputKey + '_' + columnElement.id + '_' + field.name}
              columnElementId={columnElement.id}
              field={field}
              value={value}
            />
          )
        })
        return fields
      })
      return sections
    })
    return(
      <div className="elements">
        {elements}
        <button className="button add-edit" onClick={this.props.onEditEnd}>
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="9.232" height="9.232" viewBox="0 0 9.232 9.232">
              <g data-name="Gruppe 1350" transform="translate(-64.269 -5.384)">
                <path d="M2780.614,1198.017h9.232" transform="translate(-2716.346 -1188.017)" strokeWidth="1" />
                <path d="M2780.614,1198.017h9.232" transform="translate(1266.902 -2775.23) rotate(90)" strokeWidth="1" />
              </g>
            </svg>
          </div>
          <div className="label">Element hinzufügen</div>
        </button>
      </div>
    )
  }
}

export default InputColumnElements
