import React from 'react';

import VideoPlayer from './VideoPlayer'

import CmsHelper from '../../../../../../helpers/Cms'

class Image extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: '',
      ext: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.id !== undefined && props.id !== this.props.id) {
      var thisThis = this
      this.setState({
        url: ''
      }, () => {
        thisThis.setUrl(props.id)
      })
    }
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = ''
      if(response.file.mime === 'video/mp4') {
        url = CmsHelper.getDirectUrl({
          file: response.file.path
        })
      } else {
        url = CmsHelper.getImageUrl({
          file: response.file.path,
          width: 800
        })
      }
      this.setState({
        url: url,
        ext: response.file.mime
      })
    } else {
      this.setState({
        url: '',
        ext: ''
      })
    }
  }

  render() {
    const videoJsOptions = {
      autoplay: true,
      source: {
        src: this.state.url,
        type: this.state.ext
      }
    }
    return <VideoPlayer { ...videoJsOptions } />
  }
}

export default Image
