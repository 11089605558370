import React from 'react'

import ElementBase from './ElementBase'
import Video from './components/Video'

class ElementVideo extends ElementBase {
  html() {
    var settings = this.props.element.settings
    return(
      <div className="interactive-video">
        <Video id={settings.video} />
        <div className="questions">
          <div className="question">
            <div className="headline h2">{settings.text}</div>
            <div className="answers">
              {
                settings.answers.map((answer) => {
                  return(
                    <div className="answer">{answer.settings.answer}</div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ElementVideo
