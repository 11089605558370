import React from 'react'

import InputBase from './InputBase'

import CmsHelper from '../../../../../../helpers/Cms'

class InputOverlay extends InputBase {
  constructor(props) {
    super(props)

    this.state = {
      imageName: '',
      url: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.value)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.value !== undefined && props.value !== this.props.value) {
      this.setUrl(props.value)
    }
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = CmsHelper.getImageUrl({
        file: response.file.path,
        width: 100
      })
      this.setState({
        imageName: response.title,
        url: url
      })
    } else {
      this.setState({
        imageName: '',
        url: ''
      })
    }
  }

  handleSelectImage() {
    /*if(this.props.columnElementId !== undefined) {
      this.props.onOpenModal({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        columnId: this.props.itemId,
        columnElementId: this.props.columnElementId,
        fieldName: this.props.field.name,
        filter: 'audio'
      })
    } else if(this.props.itemId !== undefined) {
      this.props.onOpenModal({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        itemId: this.props.itemId,
        fieldName: this.props.field.name,
        outerFieldName: this.props.outerFieldName,
        filter: 'audio'
      })
    } else {
      this.props.onOpenModal({
        moduleId: this.props.moduleId,
        elementId: this.props.elementId,
        fieldName: this.props.field.name,
        filter: 'audio'
      })
    }*/
  }

  handleDeleteImage() {
    this.changeField('')
  }

  html() {
    return (
      this.state.url !== '' ?
        <div className="input input-image">
          <div
            className="preview"
            onClick={this.handleSelectImage.bind(this)}
          >
            <img src={this.state.url} alt="" />
          </div>
          <div
            className="title"
            onClick={this.handleSelectImage.bind(this)}
          >
            {this.state.imageName}
          </div>
          <button onClick={this.handleDeleteImage.bind(this)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="8.553" height="10.53" viewBox="0 0 8.553 10.53">
              <g transform="translate(0.003 0.001)">
                <path d="M222.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,222.645,154.7Zm0,0" transform="translate(-216.917 -150.889)"></path>
                <path d="M104.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,104.645,154.7Zm0,0" transform="translate(-101.827 -150.889)"></path>
                <path d="M.7,3.134V9.21a1.361,1.361,0,0,0,.362.938,1.214,1.214,0,0,0,.881.381H6.606a1.214,1.214,0,0,0,.881-.381,1.361,1.361,0,0,0,.362-.938V3.134a.942.942,0,0,0-.242-1.853H6.345V.973A.969.969,0,0,0,5.368,0H3.178A.969.969,0,0,0,2.2.973v.308H.939A.942.942,0,0,0,.7,3.134Zm5.909,6.9H1.94a.781.781,0,0,1-.75-.826V3.155H7.356V9.21A.781.781,0,0,1,6.606,10.036ZM2.695.973A.475.475,0,0,1,3.178.492h2.19a.475.475,0,0,1,.483.481v.308H2.695Zm-1.756.8H7.607a.444.444,0,0,1,0,.888H.939a.444.444,0,1,1,0-.888Zm0,0" transform="translate(0 0)"></path>
                <path d="M163.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,163.645,154.7Zm0,0" transform="translate(-159.372 -150.889)"></path>
              </g>
            </svg>
          </button>
        </div>
      :
        <button className="button add-edit" onClick={this.handleSelectImage.bind(this)}>
          <div className="icon icon-image">
            <svg xmlns="http://www.w3.org/2000/svg" width="9.426" height="9.424" viewBox="0 0 9.426 9.424">
              <path d="M8.978,1.5,7.72.24a.857.857,0,0,0-1.186,0L.9,5.931a.211.211,0,0,0-.053.09L.008,8.958a.21.21,0,0,0,.259.259L3.2,8.378a.211.211,0,0,0,.09-.053l5.686-5.64a.839.839,0,0,0,0-1.187ZM5.62,1.766l.772.772L2.363,6.566l-.289-.578a.21.21,0,0,0-.188-.116H1.551ZM.515,8.711l.273-.956.683.683Zm2.42-.691-1,.287L.919,7.294l.287-1h.551l.361.723a.21.21,0,0,0,.094.094l.723.361v.551Zm.419-.345V7.339a.21.21,0,0,0-.116-.188l-.578-.289L6.688,2.834l.772.772ZM8.682,2.394l-.924.916L5.915,1.468,6.831.544a.429.429,0,0,1,.593,0L8.681,1.8a.419.419,0,0,1,0,.592Z" transform="translate(0.102 0.098)" fill="#fff" stroke="#fff" strokeWidth="0.2" />
            </svg>
          </div>
          <div className="label">Overlay bearbeiten</div>
        </button>
    )
  }
}

export default InputOverlay
