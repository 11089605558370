import React from 'react'

import Input from './inputs/Input'

class Field extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if(!this.props.hidden) {
      return(
        <Input
          {...this.props}
        />
      )
    } else {
      return null
    }
  }
}

export default Field
