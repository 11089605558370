import React from 'react'

import ElementBase from './ElementBase'
import ElementHotspotsPOIs from './ElementHotspotsPOIs'
import ComponentImage from './components/Image'

class ElementHotspots extends ElementBase {
  handleHotspotChange(args) {
    var hotspots = this.props.element.settings.hotspots
    hotspots.map((hotspot, index) => {
      if(hotspot.id === args.itemId) {
        hotspots[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'hotspots',
      fieldValue: hotspots
    })
  }

  html() {
    return(
      <div className="hotspots">
        <div className="image">
          <ComponentImage
            id={this.props.element.settings.image}
          />
        </div>
        <ElementHotspotsPOIs
          pois={this.props.element.settings.hotspots}
          onHotspotChange={this.handleHotspotChange.bind(this)}
        />
      </div>
    )
  }
}

export default ElementHotspots
