import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ElementWYSIWYG from './components/ElementWYSIWYG'
import ComponentImage from './components/Image'

class ElementFlashcards extends ElementBase {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  openTab(index) {
    this.setState({
      activeTab: index
    })
  }

  html() {
    var cards = []
    if(this.props.element.settings !== undefined && this.props.element.settings.cards !== undefined && this.props.element.settings.cards.length > 0) {
      cards = this.props.element.settings.cards.map((card, index) => {
        return(
          <div
            key={index}
            className={'card' + (index === this.state.activeTab ? ' active' : '')}
          >
            <div className="card-inner">
              <div className="card-front">
                <div className="headline h2">
                  <ElementTextarea
                    {...this.props}
                    fieldName="headline_front"
                    text={card.settings.headline_front}
                    placeholder="Titel..."
                    repeaterFieldName="cards"
                    itemId={card.id}
                  />
                </div>
                <div className="text">
                  <ElementWYSIWYG
                    {...this.props}
                    fieldName="text_front"
                    text={card.settings.text_front}
                    placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                    repeaterFieldName="cards"
                    itemId={card.id}
                  />
                </div>
              </div>
              <div className="card-back">
                <div className="headline h2">
                  <ElementTextarea
                    {...this.props}
                    fieldName="headline_back"
                    text={card.settings.headline_back}
                    placeholder="Titel..."
                    repeaterFieldName="cards"
                    itemId={card.id}
                  />
                </div>
                <div className="text">
                  <ElementWYSIWYG
                    {...this.props}
                    fieldName="text_back"
                    text={card.settings.text_back}
                    placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                    repeaterFieldName="cards"
                    itemId={card.id}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    return(
      <div className="flashcards">
        {cards}
      </div>
    )
  }
}

export default ElementFlashcards
