import React from 'react'
import {DragSource} from 'react-dnd'
import {getEmptyImage} from 'react-dnd-html5-backend'

import EditorOverlayDragButton from './EditorOverlayDragButton'

const elementSource = {
  beginDrag(props, monitor, component) {
    props.onDragStart()
    return {
      itemType: 'element',
      moduleId: props.moduleId,
      elementId: props.elementId,
      type: props.elementType,
      icon: props.elementIcon,
      label: props.elementLabel
    }
  },
  endDrag(props, monitor, component) {
    props.onDragEnd()
  }
}

function elementCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  }
}

class EditorOverlayDragButtonSourceElement extends React.Component {
  componentDidMount() {
    const {connectDragPreview} = this.props
    connectDragPreview(getEmptyImage())
  }

  render() {
    return this.props.connectDragSource(
      <div className="drag-button-source">
        <EditorOverlayDragButton />
      </div>
    )
  }
}

export default DragSource(
  'element',
  elementSource,
  elementCollect
)(EditorOverlayDragButtonSourceElement)
