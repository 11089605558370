import React from 'react'

import Element from './elements/Element'
import ElementEmpty from './elements/ElementEmpty'

import ConfigHelper from '../../../../helpers/Config'

class Elements extends React.Component {
  render() {
    const elements = this.props.elements.map((element, index) => {
      var elementConfig = ConfigHelper.getElementConfig(element.type)
      return(
        <Element
          key={element.id}
          {...this.props}
          elementIndex={index}
          elementId={element.id}
          elementItemType={elementConfig.itemType}
          element={element}
        />
      )
    })
    return(
      <div className="elements">
        {
          elements.length > 0 ?
            elements
          :
            <ElementEmpty
              {...this.props}
            />
        }
      </div>
    )
  }
}

export default Elements
