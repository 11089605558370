import React from 'react'

import ElementBase from './ElementBase'
import ComponentImage from './components/Image'

class ElementImage extends ElementBase {
  html() {
    return(
      <div className="image">
        <ComponentImage
          id={this.props.element.settings.image}
        />
      </div>
    )
  }
}

export default ElementImage
