import React from 'react'

import CmsHelper from '../../../../../helpers/Cms'

import PrismaZoom from 'react-prismazoom'

class Audio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: '',
      ext: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.id !== undefined && props.id !== this.props.id) {
      var thisThis = this
      this.setState({
        url: ''
      }, () => {
        thisThis.setUrl(props.id)
      })
    }
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = ''
      if(response.file.mime === 'video/mp4') {
        url = CmsHelper.getDirectUrl({
          file: response.file.path
        })
      } else {
        url = CmsHelper.getImageUrl({
          file: response.file.path,
          width: 800
        })
      }
      this.setState({
        url: url,
        ext: response.file.mime
      })
    } else {
      this.setState({
        url: '',
        ext: ''
      })
    }
  }

  onClickOnZoomOut = event => {
    this.refs.prismaZoom.zoomOut(1)
  }

  onClickOnZoomIn = event => {
    this.refs.prismaZoom.zoomIn(1)
  }

  render() {
    return(
      <div className="image-zoom">
        <PrismaZoom ref="prismaZoom">
          {
            this.state.url !== '' ?
              [
                this.state.ext === 'video/mp4' ?
                  <video key="1" className="image-video" src={this.state.url} loop autoPlay muted></video>
                :
                  <img key="1" className={this.state.ext === 'image/svg+xml' ? 'svg' : ''} src={this.state.url} alt="" />
              ]
            :
              <div key="1" className="image-placeholder"></div>
          }
        </PrismaZoom>
        <div className="zoom-buttons">
          <button className="zoom-button" onClick={this.onClickOnZoomIn}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <g id="Gruppe_1779" data-name="Gruppe 1779" transform="translate(-1109.5 -516.5)">
                <path id="Pfad_1178" data-name="Pfad 1178" d="M0,0H14" transform="translate(1109.5 523.5)" fill="none" stroke="#fff" stroke-width="1"/>
                <line id="Linie_312" data-name="Linie 312" x2="14" transform="translate(1116.5 516.5) rotate(90)" fill="none" stroke="#fff" stroke-width="1"/>
              </g>
            </svg>
          </button>
          <button className="zoom-button" onClick={this.onClickOnZoomOut}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="1" viewBox="0 0 14 1">
              <line id="Linie_310" data-name="Linie 310" x2="14" transform="translate(0 0.5)" fill="none" stroke="#fff" stroke-width="1"/>
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

export default Audio
