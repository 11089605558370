import React from 'react'
import {withRouter} from 'react-router'
import ReactDropzone from 'react-dropzone';
import $ from 'jquery'

import CmsHelper from '../../../helpers/Cms'
import CommonHelper from '../../../helpers/Common'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editAttachmentImage: '',
      editAttachmentCategories: [],
      editAttachmentTags: [],
      editAttachmentTitle: '',
      editAttachmentDescription: '',
    }
  }

  componentDidMount() {
    this.loadEditAttachment(this.props.modalArgs.id)
  }

  async loadEditAttachment(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    var url = CmsHelper.getImageUrl({
      file: response.file.path,
      width: 475
    })
    response.url = url
    var categories = response.categories !== undefined ? response.categories : []
    var tags = response.tags !== undefined ? response.tags : []
    this.setState({
      editAttachmentImage: response,
      editAttachmentCategories: categories,
      editAttachmentTags: tags,
      editAttachmentTitle: response.custom_title,
      editAttachmentDescription: response.description
    })
  }

  render() {
    const {
      items
    } = this.state

    var file = this.state.editAttachmentImage

    var size = 0
    var width = 0
    var height = 0
    if(file.file !== undefined) {
      size = parseInt(file.file.size / 1000 + 0.5)
      width = file.file.width
      height = file.file.height
    }

    return(
      <div className="modal-window comment-file">
        <div className="headline">Mediathek</div>
        <div className="attachment-info-box">
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'image/jpeg' || file.file.mime === 'image/png') ?
              <div
                className="image"
              >
                {
                  file !== undefined && file.url !== '' ?
                    <div
                      className="img"
                      style={{backgroundImage: 'url(' + file.url + ')'}}
                    >
                    </div>
                  :
                    null
                }
              </div>
            :
              ''
          }
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'video/mp4') ?
              <div
                className="video"
              >
                <video
                  src={
                    CmsHelper.getDirectUrl({
                      file: file.file.path
                    })
                  }
                  controls
                >
                </video>
              </div>
            :
              ''
          }
          {
            file !== undefined && file.file !== undefined && (file.file.mime === 'audio/mpeg' || file.file.mime === 'audio/x-wav') ?
              <div
                className="audio"
              >
                <audio controls>
                  <source
                    src={
                      CmsHelper.getDirectUrl({
                        file: file.file.path
                      })
                    }
                    type={file.file.mime}
                  />
                </audio>
              </div>
            :
              ''
          }
          {
            file !== undefined && file.url !== '' ?
              <div className="info">
                <div className="headline">{file.title}</div>
                <div className="key-info">
                  <div className="column">
                    <div className="label">Hochgeladen</div>
                    <div className="text">
                      {
                        CommonHelper.formatDate(file._created)
                      }
                    </div>
                  </div>
                  <div className="column">
                    <div className="label">Dateigröße</div>
                    <div className="text">{size + ' KB'}</div>
                  </div>
                  {
                    width !== undefined && height !== undefined ?
                      <div className="column">
                        <div className="label">Dimensionen</div>
                        <div className="text">{width + ' x ' + height}</div>
                      </div>
                    :
                      ''
                  }
                </div>
              </div>
            :
              null
          }
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
