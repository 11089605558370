import React from 'react'

import Module from './Module'
import AddButton from '../components/AddButton'
import Tooltip from '../../../../tooltip/Tooltip'

class Modules extends React.Component {
  handleAdd(args) {
    this.props.onAdd(args)
  }

  render() {
    const modules = this.props.modules.map((module, index) => {
      return(
        <React.Fragment key={module.id}>
          <Module
            {...this.props}
            moduleIndex={index}
            module={module}
            onAddModule={
              this.handleAdd.bind(this, {
                moduleIndex: index + 1
              })
            }
          />
          <AddButton
            previewMode={this.props.previewMode}
            type={this.props.type}
            onAdd={
              this.handleAdd.bind(this, {
                moduleIndex: index + 1
              })
            }
          />
        </React.Fragment>
      )
    })
    return(
      <div className="modules">
        <AddButton
          previewMode={this.props.previewMode}
          type={this.props.type}
          onAdd={
            this.handleAdd.bind(this, {
              moduleIndex: 0
            })
          }
        />
        {
          modules.length === 0 ?
            <Tooltip
              tooltip="add_module"
            />
          :
            ''
        }
        {modules}
      </div>
    )
  }
}

export default Modules
