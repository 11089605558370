import React from 'react'
import ReactDOM from 'react-dom'

import ToastUiImageEditor from '@toast-ui/react-image-editor'
import 'tui-image-editor/dist/tui-image-editor.css'

const Modal = ({ type, show, options, onClose }) => {

  return show ? (
    <div id="modal">
      <div className="modal-window edit-image">
        <h2 className="headline">Bild bearbeiten</h2>
        <div className="main tui-editor-main">
          <div className="tui-editor">
            <ToastUiImageEditor
              includeUI={{
                loadImage: {
                  path: options.imageUrl,
                  name: options.imageUrl.split('/').reverse()[0]
                },
                theme: {
                  "header.display": "none"
                }
              }}
              cssMaxWidth="700"
              cssMaxHeight="281"
              usageStatistics={false}
            />
            <button
              className="button white highlighted round"
            >
              <span className="label">Speichern</span>
            </button>
          </div>
        </div>
        <button
          className="close-modal"
          onClick={() => onClose()}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
      <div className="modal-background"></div>
    </div>
  ) : null
}

export default Modal
