import React from 'react'

import ElementTextarea from './components/ElementTextarea'
import ComponentImage from './components/Image'

class ElementTeaser extends React.Component {
  render() {
    return(
      <div className={'teaser teaser-' + this.props.teaser.settings.type}>
        {
          (
            this.props.teaser.settings.type !== 'text' ?
              <div className="image">
                <ComponentImage
                  id={this.props.teaser.settings.image}
                />
              </div>
            :
              ''
          )
        }
        <div className="content">
          <div>
            <div className="headline h2">
              <ElementTextarea
                {...this.props}
                fieldName="headline"
                text={this.props.teaser.settings.headline}
                placeholder="Titel..."
                itemId={this.props.teaser.id}
              />
            </div>
            <hr />
            <div className="text">
              <ElementTextarea
                {...this.props}
                fieldName="text"
                text={this.props.teaser.settings.text}
                placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                itemId={this.props.teaser.id}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ElementTeaser
