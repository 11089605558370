import React from 'react'

import InputBase from './InputBase'

class InputCoordinates extends InputBase {
  handleChangeField(coordinate, e) {
    var coordinates = this.props.value
    var {
      x = '',
      y = ''
    } = coordinates
    if(coordinate === 'x') {
      x = e.target.value.replace(/\D/g,'')
      if(x < 0) {
        x = 0
      }
      if(x > 100) {
        x = 100
      }
    }
    if(coordinate === 'y') {
      y = e.target.value.replace(/\D/g,'')
      if(y < 0) {
        y = 0
      }
      if(y > 100) {
        y = 100
      }
    }
    this.changeField({
      x: x,
      y: y
    })
  }

  html() {
    const {
      name = ''
    } = this.props.field
    const {
      value = {
        x: 0,
        y: 0
      }
    } = this.props

    return(
      <div className="coordinates">
        <div className="coordinate coordinate-x">
          <div className="label">x</div>
          <input
            key={this.props.inputKey + '_' + name + '_x'}
            className="light"
            type="text"
            value={Math.round(value.x)}
            onChange={this.handleChangeField.bind(this, 'x')}
          />
        </div>
        <div className="coordinate coordinate-y">
          <div className="label">y</div>
          <input
            key={this.props.inputKey + '_' + name + '_y'}
            className="light"
            type="text"
            value={Math.round(value.y)}
            onChange={this.handleChangeField.bind(this, 'y')}
          />
        </div>
      </div>
    )
  }
}

export default InputCoordinates
