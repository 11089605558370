import React from 'react'

import EditorDropIndicator from '../../components/EditorDropIndicator'
import EditorOverlay from '../../components/EditorOverlay'
import Tooltip from '../../../../../tooltip/Tooltip'

import CmsHelper from '../../../../../helpers/Cms'

class ElementBase extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMouseOver: false,
      isDragging: false,
      tooltipMode: 0
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.checkTooltipMode()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async checkTooltipState() {
  }

  async checkTooltipMode() {
    var user = await CmsHelper.get({
      type: 'users',
      id: JSON.parse(sessionStorage.getItem('userId'))
    })
    var closedTooltips = user.closed_tooltips
    if(user.show_tooltips) {
      if(this.props.element.type === 'text' && !closedTooltips.includes('edit_inline') && this.props.modules.length === 1 && this.props.elements.length === 1) {
        this.setState({
          tooltipMode: 1
        })
      } else if(!closedTooltips.includes('edit_element') && this.props.modules.length === 1 && this.props.elements.length === 1) {
        this.setState({
          tooltipMode: 2
        })
      } else if(!closedTooltips.includes('edit_element_' + this.props.element.type)) {
        this.setState({
          tooltipMode: 3
        })
      }
    }
  }

  handleMouseOver() {
    this.setState({
      isMouseOver: true
    })
  }

  handleMouseLeave() {
    this.setState({
      isMouseOver: false
    })
  }

  handleDragStart() {
    this.setState({
      isDragging: true
    })
  }

  handleDragEnd() {
    if(this._isMounted) {
      this.setState({
        isDragging: false
      })
    }
  }

  handleDuplicate() {
    if(this.props.columnElementId !== undefined) {
      this.props.onDuplicate({
        moduleId: this.props.module.id,
        elementId: this.props.elementId,
        columnId: this.props.column.id,
        columnElementId: this.props.columnElementId
      })
    } else {
      this.props.onDuplicate({
        moduleId: this.props.module.id,
        elementId: this.props.elementId,
      })
    }
  }

  handleDelete() {
    if(this.props.overlayId !== undefined && this.props.overlayId !== '') {
      if(this.props.columnElementId !== undefined) {
        this.props.onDelete({
          overlayId: this.props.overlayId,
          elementId: this.props.elementId,
          columnId: this.props.column.id,
          columnElementId: this.props.columnElementId
        })
      } else {
        this.props.onDelete({
          overlayId: this.props.overlayId,
          elementId: this.props.elementId,
        })
      }
    } else {
      if(this.props.columnElementId !== undefined) {
        this.props.onDelete({
          moduleId: this.props.module.id,
          elementId: this.props.elementId,
          columnId: this.props.column.id,
          columnElementId: this.props.columnElementId
        })
      } else {
        this.props.onDelete({
          moduleId: this.props.module.id,
          elementId: this.props.elementId,
        })
      }
    }
  }

  handleEdit() {
    if(this.props.overlayId !== undefined && this.props.overlayId !== '') {
      this.props.onEdit({
        overlayId: this.props.overlayId,
        elementId: this.props.elementId
      })
    } else {
      this.props.onEdit({
        moduleId: this.props.module.id,
        elementId: this.props.elementId
      })
    }
  }

  handleComment() {
    if(this.props.overlayId !== undefined && this.props.overlayId !== '') {
    } else {
      this.props.onComment({
        moduleId: this.props.module.id,
        elementId: this.props.elementId
      })
    }
  }

  handleCloseTooltip1() {
    this.setState({
      tooltipMode: 2
    })
  }

  html() {
    return this.props.element.type
  }

  render() {
    var columnId
    var columnElementId
    if(this.props.columnElementId !== undefined) {
      columnId = this.props.column.id
      columnElementId = this.props.columnElementId
    }
    return (
      <div
        className={'element' + (this.state.isDragging ? ' is-dragging' : '')}
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
      >
        <EditorDropIndicator
          position="before"
          show={this.props.isDragOver === 'top' ? true : false}
        />
        <div
          className={'element-container element-container-' + this.props.element.type}
          onClick={this.handleEdit.bind(this)}
        >
          {this.html()}
        </div>
        <EditorDropIndicator
          position="left"
          show={this.props.isDragOver === 'left' ? true : false}
        />
        <EditorDropIndicator
          position="right"
          show={this.props.isDragOver === 'right' ? true : false}
        />
        <EditorDropIndicator
          position="after"
          show={this.props.isDragOver === 'bottom' ? true : false}
        />
        <EditorOverlay
          type={this.props.columnElementIndex !== undefined ? 'column-element' : 'element'}
          elementType={this.props.element.type}
          moduleId={this.props.module.id}
          elementId={this.props.elementId}
          columnId={columnId}
          columnElementId={columnElementId}
          show={this.state.isMouseOver || ((this.props.panelView === 'edit' || this.props.panelView === 'comment') && this.props.element.id === this.props.panelElementId) || this.state.tooltipMode === 2}
          onDragStart={this.handleDragStart.bind(this)}
          onDragEnd={this.handleDragEnd.bind(this)}
          onDuplicate={this.handleDuplicate.bind(this)}
          onDelete={this.handleDelete.bind(this)}
          onEdit={this.handleEdit.bind(this)}
          onComment={this.handleComment.bind(this)}
          tooltipMode={this.state.tooltipMode}
          comments={this.props.comments}
        />
        {
          this.state.tooltipMode === 1 ?
            <Tooltip
              tooltip="edit_inline"
              onClose={this.handleCloseTooltip1.bind(this)}
            />
          :
            ''
        }
        {
          this.state.tooltipMode === 3 ?
            <Tooltip
              tooltip={'element_info_' + this.props.element.type}
              onOpenHelpVideo={this.props.onOpenHelpVideo}
            />
          :
            ''
        }
      </div>
    )
  }
}

export default ElementBase
