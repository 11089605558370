import React from 'react'

import WindowAddWbt from './windows/WindowAddWbt'
import WindowAddTemplate from './windows/WindowAddTemplate'
import WindowUseTemplate from './windows/WindowUseTemplate'
import WindowAddAttachments from './windows/WindowAddAttachments'
import WindowExternalLink from './windows/WindowExternalLink'
import WindowHelpVideo from './windows/WindowHelpVideo'

class Modal extends React.Component {
  render() {
    var modalWindow
    switch(this.props.modal) {
      case 'add_wbt':
        modalWindow = (
          <WindowAddWbt
            {...this.props}
          />
        )
        break
      case 'add_template':
        modalWindow = (
          <WindowAddTemplate
            {...this.props}
          />
        )
        break
      case 'use_template':
        modalWindow = (
          <WindowUseTemplate
            {...this.props}
          />
        )
        break
      case 'add_attachments':
        modalWindow = (
          <WindowAddAttachments
            {...this.props}
          />
        )
        break
      case 'help_video':
        modalWindow = (
          <WindowHelpVideo
            {...this.props}
          />
        )
        break
      case 'external_link':
        modalWindow = (
          <WindowExternalLink
            {...this.props}
          />
        )
        break
      default:
        modalWindow = (
          <WindowAddWbt
            {...this.props}
          />
        )
        break
    }
    return(
      this.props.modalShow ?
        <div id="modal">
          {modalWindow}
          <div className="modal-background"></div>
        </div>
      :
        null
    )
  }
}

export default Modal
