import React from 'react';

import ReactPlayer from 'react-player'

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      playing: false,
      muted: false
    }
  }

  togglePlaying() {
    this.setState({
      playing: !this.state.playing
    })
  }

  toggleMuted() {
    this.setState({
      muted: !this.state.muted
    })
  }

  render() {
    return (
      <div className="video-player">
        <ReactPlayer
          playing={this.state.playing}
          muted={this.state.muted}
          url={this.props.source.src}
        />
        <div className="controls">
          <button
            className="control-button play"
            onClick={this.togglePlaying.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="13.435" height="13.436" viewBox="0 0 13.435 13.436">
              <path d="M0,8H8V0" transform="translate(1.061 6.718) rotate(-45)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5" />
            </svg>
          </button>
          <button
            className="control-button volume"
            onClick={this.toggleMuted.bind(this)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16.707" height="13.36" viewBox="0 0 16.707 13.36">
              <path d="M21.686,20.173a.447.447,0,0,0-.469.044l-4.995,3.769H13.431a.787.787,0,0,0-.786.786V28.84a.787.787,0,0,0,.786.786h2.791L21.217,33.4a.448.448,0,0,0,.716-.357V20.574a.448.448,0,0,0-.248-.4Zm-.646,11.969-4.4-3.32a.447.447,0,0,0-.269-.09H13.539V24.88h2.832a.445.445,0,0,0,.269-.09l4.4-3.32Zm2.728-8.164a.447.447,0,0,0-.632.632,3.1,3.1,0,0,1,0,4.39.447.447,0,1,0,.632.632,4,4,0,0,0,0-5.656Zm1.542-1.541a.447.447,0,0,0-.632.632,5.285,5.285,0,0,1,0,7.474.447.447,0,1,0,.632.632,6.179,6.179,0,0,0,0-8.739Zm1.581-1.582a.447.447,0,0,0-.632.632,7.531,7.531,0,0,1,0,10.637.447.447,0,1,0,.632.632,8.426,8.426,0,0,0,0-11.9Z" transform="translate(-12.645 -20.126)" fill="#fff"/>
            </svg>
          </button>
        </div>
      </div>
    );
  }
}
