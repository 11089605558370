import React from 'react'

import ElementBase from './ElementBase'
import ComponentImage from './components/Image'

import PrismaZoom from 'react-prismazoom'

class ElementImage extends ElementBase {
  onClickOnZoomOut = event => {
    this.refs.prismaZoom.zoomOut(1)
  }

  onClickOnZoomIn = event => {
    this.refs.prismaZoom.zoomIn(1)
  }


  html() {
    return(
      <div className="image-zoom">
        <PrismaZoom ref="prismaZoom">
          <ComponentImage
            id={this.props.element.settings.image}
          />
        </PrismaZoom>
        <div className="zoom-buttons">
          <button className="zoom-button" onClick={this.onClickOnZoomIn}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
              <g id="Gruppe_1779" data-name="Gruppe 1779" transform="translate(-1109.5 -516.5)">
                <path id="Pfad_1178" data-name="Pfad 1178" d="M0,0H14" transform="translate(1109.5 523.5)" fill="none" stroke="#fff" stroke-width="1"/>
                <line id="Linie_312" data-name="Linie 312" x2="14" transform="translate(1116.5 516.5) rotate(90)" fill="none" stroke="#fff" stroke-width="1"/>
              </g>
            </svg>
          </button>
          <button className="zoom-button" onClick={this.onClickOnZoomOut}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="1" viewBox="0 0 14 1">
              <line id="Linie_310" data-name="Linie 310" x2="14" transform="translate(0 0.5)" fill="none" stroke="#fff" stroke-width="1"/>
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

export default ElementImage
