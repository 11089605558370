import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementMultipleChoiceAnswersList from './ElementMultipleChoiceAnswersList'
import ElementText from './components/ElementText'

class ElementMultipleChoice extends ElementBase {
  handleChangeAnswers(args) {
    var answers = this.props.element.settings.answers
    answers.map((answer, index) => {
      if(answer.id === args.itemId) {
        answers[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'answers',
      fieldValue: answers
    })
  }

  handleChangeBoxHeadline(headline, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_headline',
      fieldValue: headline
    })
  }

  handleChangeBoxText(text, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_text',
      fieldValue: text
    })
  }

  html() {
    var ratings = []
    var rows = []
    if(this.props.element.settings !== undefined && this.props.element.settings.ratings !== undefined && this.props.element.settings.ratings.length > 0) {
      ratings = this.props.element.settings.ratings.map((rating, index) => {
        return(
          <th>
            <ElementText
              {...this.props}
              fieldName="text"
              text={rating.settings.text}
              placeholder="Titel..."
              repeaterFieldName="ratings"
              itemId={rating.id}
            />
          </th>
        )
      })
    }
    if(this.props.element.settings !== undefined && this.props.element.settings.rows !== undefined && this.props.element.settings.rows.length > 0) {
      rows = this.props.element.settings.rows.map((row, index) => {
        return(
          <tr>
            <td>
              <ElementText
                {...this.props}
                fieldName="text"
                text={row.settings.text}
                placeholder="Titel..."
                repeaterFieldName="rows"
                itemId={row.id}
              />
            </td>
            {
              ratings.map((rating, i) => (
                <td>
                  <input type="radio" name={'radio_' + row.id} id={'radio_' + row.id + '_' + i} value={i} />
                  <label htmlFor={'radio_' + row.id + '_' + i}></label>
                </td>
              ))
            }
          </tr>
        )
      })
    }
    return(
      <div className="profile">
        <ElementBox
          {...this.props}
        />
        <div className="profile-box">
          <table>
            <thead>
              <tr>
                <th></th>
                {
                  ratings
                }
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>
    )
  }
}

export default ElementMultipleChoice
