import React from 'react'

import Module from '../modules/Module'

class EditorOverlay extends React.Component {
  handleClose() {
    this.props.onCloseOverlay()
  }

  render() {
    if(this.props.overlayId !== '') {
      var module
      var thisOverlay = this
      this.props.wbt.overlays.map(function(overlay, index) {
        if(thisOverlay.props.overlayId === overlay.id) {
          module = overlay
        }
      })
      return (
        <div id="overlay">
          <div className="overlay-window">
            <div
              className="close-overlay"
              onClick={this.handleClose.bind(this)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="19.414" height="19.414" viewBox="0 0 19.414 19.414">
                <path d="M-1124-2402l-9,9,9-9-9-9,9,9,9-9-9,9,9,9Z" transform="translate(1133.707 2411.707)" fill="none" stroke="#000" strokeWidth="2" />
              </svg>
            </div>
            <Module
              {...this.props}
              type="overlay"
              moduleIndex={1}
              module={module}
            />
          </div>
          <div className="overlay-background"></div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default EditorOverlay
