class Common {
  static formatDate(date) {
    var object = new Date(date * 1000)
    return ('0' + object.getDate()).slice(-2) + '.' + ('0' + (object.getMonth() + 1)).slice(-2) + '.' + object.getFullYear()
  }

  static formatDateTime(date) {
    var object = new Date(date * 1000)
    return ('0' + object.getDate()).slice(-2) + '.' + ('0' + (object.getMonth() + 1)).slice(-2) + '.' + object.getFullYear() + ' @ ' + object.getHours() + ':' + ('0' + (object.getMinutes() + 1)).slice(-2) + ' Uhr'
  }
}

export default Common
