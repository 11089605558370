import React from 'react'

import Image from './Image'
import Audio from './Audio'

class ElementBox extends React.Component {
  constructor(element) {
    super()
  }

  render() {
    var {
      box_image = '',
      box_audio = '',
      box_headline = '',
      box_text = '',
      box_show = true
    } = this.props.settings
    if(box_show) {
      return(
        <div className="element-box">
          {
            box_image !== '' ?
              <Image
                id={box_image}
                element={this.props.element} urls={this.props.urls}
              />
            :
              null
          }
          {
            box_audio !== '' ?
              <button className="button audio">Audio auswählen<Audio id={box_audio} element={this.props.element} urls={this.props.urls} /></button>
            :
              null
          }
          <div className="headline h2">
            {box_headline}
          </div>
          <div className="text">
            {box_text}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ElementBox
