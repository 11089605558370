import React from 'react'
import {DragSource} from 'react-dnd'
import {getEmptyImage} from 'react-dnd-html5-backend'

import ElementsElement from './ElementsElement.js'

const source = {
  beginDrag(props, monitor, component) {
    props.onDragStart()
    return {
      itemType: 'element',
      type: props.type,
      icon: props.icon,
      label: props.label
    }
  },
  endDrag(props, monitor, component) {
    props.onDragEnd()
  }
}

function collect(connect, monitor) {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

class ElementsElementSourceElement extends React.Component {
  componentDidMount() {
    const {connectDragPreview} = this.props
    connectDragPreview(getEmptyImage())
  }

  render() {
    return this.props.connectDragSource(
      <div
        className={'element-source' + (this.props.isDragging ? ' is-dragging' : '')}
        style={{zIndex: this.props.zIndex}}
      >
        <ElementsElement
          category={this.props.category}
          icon={this.props.icon}
          label={this.props.label}
          isElementDragging={this.props.isElementDragging}
        />
      </div>
    )
  }
}

export default DragSource(
  'element',
  source,
  collect
)(ElementsElementSourceElement)
