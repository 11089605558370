import React from 'react'
import Draggable from 'react-draggable'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementSlideControlPoint from './ElementSlideControlPoint'
import ComponentImage from './components/Image'

class ElementSlideControl extends ElementBase {
  handleChangePoint(position, value) {
    this.props.onChangeField({
      moduleId: this.props.module.id,
      elementId: this.props.element.id,
      fieldName: 'point_' + position,
      fieldValue: value
    })
  }

  handleChangeBoxHeadline(headline, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_headline',
      fieldValue: headline
    })
  }

  handleChangeBoxText(text, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_text',
      fieldValue: text
    })
  }

  html() {
    return(
      <div className="slide-control">
        <ElementBox
          {...this.props}
        />
        {
          this.props.element.settings.image !== undefined && this.props.element.settings.image !== '' ?
            <div className="image">
              <ComponentImage
                id={this.props.element.settings.image}
              />
            </div>
          :
            ''
        }
        <div className="control">
          <ElementSlideControlPoint
            point={this.props.element.settings.point_left}
            position="left"
            onChangeText={this.handleChangePoint.bind(this, 'left')}
          />
          <div className="input">
            <div className="rail">
              <Draggable
                axis="x"
                bounds="parent"
                position={this.reset ? {x: 0, y: 0} : null}
              >
                <div className="handle"></div>
              </Draggable>
            </div>
          </div>
          <ElementSlideControlPoint
            point={this.props.element.settings.point_right}
            position="right"
            onChangeText={this.handleChangePoint.bind(this, 'right')}
          />
        </div>
        <div className="buttons">
          <div className="button resolve">Bestätigen</div>
        </div>
      </div>
    )
  }
}

export default ElementSlideControl
