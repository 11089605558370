import React from 'react'
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import InputBase from './InputBase'
import Field from '../Field'

const DragHandle = SortableHandle(() => (
  <button className="item-header-button move-item">
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.714" viewBox="0 0 12 7.714">
      <g id="Gruppe_1224" data-name="Gruppe 1224" transform="translate(-781 -163)">
        <rect id="Rechteck_201" data-name="Rechteck 201" width="12" height="1" transform="translate(781 163)" fill="#fff"/>
        <rect id="Rechteck_202" data-name="Rechteck 202" width="12" height="1" transform="translate(781 166.357)" fill="#fff"/>
        <rect id="Rechteck_203" data-name="Rechteck 203" width="12" height="1" transform="translate(781 169.714)" fill="#fff"/>
      </g>
    </svg>
  </button>
))

const SortableItem = SortableElement(({value}) => <>{value}</>);

const SortableList = SortableContainer(({items}) => {
  return (
    <div className="items">
      {items.map((value, index) => {
        return(
          <SortableItem key={value.key} index={index} value={value} />
        )
      })}
    </div>
  );
});

class SortableComponent extends React.Component {
  onSortEnd = ({oldIndex, newIndex}) => {
    this.props.onChangeOrder({
      moduleId: this.props.moduleId,
      elementId: this.props.elementId,
      fieldName: this.props.field.name,
      oldIndex: oldIndex,
      newIndex: newIndex
    })
  };
  render() {
    return <SortableList items={this.props.items} lockAxis="y" transitionDuration={250} onSortEnd={this.onSortEnd} useDragHandle="true" lockToContainerEdges="true" />;
  }
}

class InputRepeater extends InputBase {
  getItems() {
    const items = this.props.value.map((item, itemIndex) => {
      const fields = this.props.field.fields.map((field, fieldIndex) => {
        var value = item.settings[field.name]
        if(value === undefined) {
          if(field.defaultValue !== undefined) {
            value = field.defaultValue
          } else {
            value = ''
          }
        }
        return(
          <Field
            key={this.props.inputKey + '_' + item.id + '_' + field.name}
            {...this.props}
            inputKey={this.props.inputKey + '_' + item.id + '_' + field.name}
            itemId={item.id}
            field={field}
            outerFieldName={this.props.field.name}
            value={value}
            onChangeField={this.handleChangeField.bind(this)}
          />
        )
      })
      return(
        <div
          key={this.props.inputKey + '_' + item.id}
          className={'item repeater-item' + (this.props.field.simplified ? ' simplified' : '')}
        >
          <div className="item-header">
            <div className="item-headline">{itemIndex + 1}</div>
            <div className="item-header-button-group">
              <DragHandle />
              <button className="item-header-button" onClick={this.handleDeleteItem.bind(this, itemIndex)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8.553" height="10.53" viewBox="0 0 8.553 10.53">
                  <g transform="translate(0.003 0.001)">
                    <path d="M222.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,222.645,154.7Zm0,0" transform="translate(-216.917 -150.889)" />
                    <path d="M104.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,104.645,154.7Zm0,0" transform="translate(-101.827 -150.889)" />
                    <path d="M.7,3.134V9.21a1.361,1.361,0,0,0,.362.938,1.214,1.214,0,0,0,.881.381H6.606a1.214,1.214,0,0,0,.881-.381,1.361,1.361,0,0,0,.362-.938V3.134a.942.942,0,0,0-.242-1.853H6.345V.973A.969.969,0,0,0,5.368,0H3.178A.969.969,0,0,0,2.2.973v.308H.939A.942.942,0,0,0,.7,3.134Zm5.909,6.9H1.94a.781.781,0,0,1-.75-.826V3.155H7.356V9.21A.781.781,0,0,1,6.606,10.036ZM2.695.973A.475.475,0,0,1,3.178.492h2.19a.475.475,0,0,1,.483.481v.308H2.695Zm-1.756.8H7.607a.444.444,0,0,1,0,.888H.939a.444.444,0,1,1,0-.888Zm0,0" transform="translate(0 0)" />
                    <path d="M163.645,154.7a.247.247,0,0,0-.247.247v4.661a.247.247,0,1,0,.493,0V154.95A.247.247,0,0,0,163.645,154.7Zm0,0" transform="translate(-159.372 -150.889)" />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div className="item-body">
            {fields}
          </div>
        </div>
      )
    })
    if(items.length > 0) {
      return(
        <SortableComponent
          {...this.props}
          items={items}
        />
      )
    } else {
      return null
    }
  }

  handleChangeField(args) {
    var value = this.props.value
    this.props.value.map((item, itemIndex) => {
      if(item.id === args.itemId) {
        if(args.columnElementId !== undefined) {
          value[itemIndex].settings.elements.map((columnElement, columnElementIndex) => {
            if(columnElement.id === args.columnElementId) {
              value[itemIndex].settings.elements[columnElementIndex].settings[args.fieldName] = args.fieldValue
            }
            return null
          })
        } else {
          value[itemIndex].settings[args.fieldName] = args.fieldValue
        }
      }
      return null
    })
    this.changeField(value)
  }

  handleAddItem() {
    var {
      value = []
    } = this.props

    var item = {
      id: Math.random().toString(36).substr(2, 8),
      settings: {}
    }
    this.props.field.fields.map((field, fieldIndex) => {
      if(field.defaultValue !== undefined) {
        item.settings[field.name] = field.defaultValue
      } else {
        if(field.type === 'column_elements') {
          item.settings[field.name] = []
        } else {
          item.settings[field.name] = ''
        }
      }
      return null
    })
    value.push(item)
    this.changeField(value)
  }

  handleDeleteItem(index) {
    var value = this.props.value
    value.splice(index, 1)
    this.changeField(value)
  }

  html() {
    const {
      addItemLabel = 'Hinzufügen',
      maxItems = -1
    } = this.props.field

    return(
      <>
        {this.getItems()}
        {
          maxItems === -1 || this.props.value.length < maxItems ?
            <button className="button add-edit" onClick={this.handleAddItem.bind(this)}>
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="9.232" height="9.232" viewBox="0 0 9.232 9.232">
                  <g data-name="Gruppe 1350" transform="translate(-64.269 -5.384)">
                    <path d="M2780.614,1198.017h9.232" transform="translate(-2716.346 -1188.017)" strokeWidth="1" />
                    <path d="M2780.614,1198.017h9.232" transform="translate(1266.902 -2775.23) rotate(90)" strokeWidth="1" />
                  </g>
                </svg>
              </div>
              <div className="label">{addItemLabel}</div>
            </button>
          :
            ''
        }
      </>
    )
  }
}

export default InputRepeater
