import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementMultipleChoiceAnswersList from './ElementMultipleChoiceAnswersList'
import ElementText from './components/ElementText'

import ElementHotspotsPOIs from './ElementHotspotsPOIs'
import ComponentImage from './components/Image'



class ElementMultipleChoice extends ElementBase {
  handleChangeAnswers(args) {
    var answers = this.props.element.settings.answers
    answers.map((answer, index) => {
      if(answer.id === args.itemId) {
        answers[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'answers',
      fieldValue: answers
    })
  }

  handleChangeBoxHeadline(headline, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_headline',
      fieldValue: headline
    })
  }

  handleChangeBoxText(text, e) {
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'box_text',
      fieldValue: text
    })
  }

  chatSection(answer) {
    if(answer.settings.message) {
      return(
        <div className="chat-section">
          <div className="chat-message">
            <div className="avatar">
              <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                  <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                    <circle cx="5" cy="5" r="5" stroke="none" />
                    <circle cx="5" cy="5" r="4.5" fill="none" />
                  </g>
                  <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                    <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                    <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="message">
              {answer.settings.message}
            </div>
          </div>
          {
            answer.settings.answers && answer.settings.answers.length > 0 ? (
              <div className="chat-message chat-message-me">
                <div className="avatar">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                    <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                      <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                        <circle cx="5" cy="5" r="5" stroke="none" />
                        <circle cx="5" cy="5" r="4.5" fill="none" />
                      </g>
                      <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                        <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                        <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="answers">
                  {
                    answer.settings.answers.map(answer2 => {
                      return(
                        <div className="answer">
                          <input type="radio" name={'radio_message_' + answer.id} id={'radio_message_1_' + answer2.id} value={answer2.id} />
                          <label className="message" htmlFor={'radio_message_1_' + answer2.id}>
                            {answer.settings.answer}
                            <div className="check-mark">
                              <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                                <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                              </svg>
                            </div>
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            ) : null
          }
        </div>
      )
    } else {
      return ''
    }
  }

  html() {
    return(
      <div className="chat-simulator">
        <div className="chat-history">
          <div className="chat-section">
            <div className="chat-message">
              <div className="avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                  <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                      <circle cx="5" cy="5" r="5" stroke="none" />
                      <circle cx="5" cy="5" r="4.5" fill="none" />
                    </g>
                    <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                      <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                      <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="message">
                <ElementText
                  {...this.props}
                  fieldName="message"
                  text={this.props.element.settings.message}
                  placeholder="Nachricht..."
                />
              </div>
            </div>
            <div className="chat-message chat-message-me">
              <div className="avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                  <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                    <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                      <circle cx="5" cy="5" r="5" stroke="none" />
                      <circle cx="5" cy="5" r="4.5" fill="none" />
                    </g>
                    <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                      <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                      <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div className="answers">
                {
                  this.props.element.settings.answers.map(answer => {
                    return(
                      <div className="answer">
                        <input type="radio" name={'radio_message_1'} id={'radio_message_1_' + answer.id} value={answer.id} />
                        <label className="message" htmlFor={'radio_message_1_' + answer.id}>
                          {answer.settings.answer}
                          <div className="check-mark">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.061" height="14.924" viewBox="0 0 21.061 14.924">
                              <path id="Pfad_785" data-name="Pfad 785" d="M3262,3691.667l6.667,6.666L3282,3685" transform="translate(-3261.47 -3684.47)" fill="none" stroke="#fff" stroke-width="1.5"/>
                            </svg>
                          </div>
                        </label>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          { /*
          {
            this.props.element.settings.answers ? this.props.element.settings.answers.map(answer => {
              return this.chatSection(answer)
            }) : ''
          }
          {
            this.props.element.settings.answers? this.props.element.settings.answers.map(answer => {
              return answer.settings.answers ? answer.settings.answers.map(answer => {
                return this.chatSection(answer)
              }) : ''
            }) : ''
          }
          {
            this.props.element.settings.answers ? this.props.element.settings.answers.map(answer => {
              return answer.settings.answers ? answer.settings.answers.map(answer => {
                return answer.settings.answers ? answer.settings.answers.map(answer => {
                  return this.chatSection(answer)
                }) : ''
              }) : ''
            }) : ''
          }
          {
            this.props.element.settings.answers ? this.props.element.settings.answers.map(answer => {
              return answer.settings.answers ? answer.settings.answers.map(answer => {
                return answer.settings.answers ? answer.settings.answers.map(answer => {
                  return answer.settings.answers ? answer.settings.answers.map(answer => {
                    return this.chatSection(answer)
                  }) : ''
                }) : ''
              }) : ''
            }) : ''
          }
          {
            this.props.element.settings.answers ? this.props.element.settings.answers.map(answer => {
              return answer.settings.answers ? answer.settings.answers.map(answer => {
                return answer.settings.answers ? answer.settings.answers.map(answer => {
                  return answer.settings.answers ? answer.settings.answers.map(answer => {
                    return answer.settings.answers ? answer.settings.answers.map(answer => {
                      return this.chatSection(answer)
                    }) : ''
                  }) : ''
                }) : ''
              }) : ''
            }) : ''
          }
          */ }
          {
            /*this.props.element.settings.messages.map(message => {
              return (
                <div className="chat-section">
                  <div className="chat-message">
                    <div className="avatar">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15.742" height="15.908" viewBox="0 0 15.742 15.908">
                        <g id="Gruppe_1642" data-name="Gruppe 1642" transform="translate(-448 -186.908)">
                          <g id="Ellipse_41" data-name="Ellipse 41" transform="translate(450.871 186.908)" fill="none" stroke="#fff" stroke-width="1" >
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.5" fill="none" />
                          </g>
                          <g id="Pfad_930" data-name="Pfad 930" transform="translate(448 196.377)" fill="none">
                            <path d="M7.871,0c4.347,0,7.871,2.883,7.871,6.44H0C0,2.883,3.524,0,7.871,0Z" stroke="none"/>
                            <path d="M 7.871093273162842 1.000002861022949 C 4.513636589050293 1.000002861022949 1.710929870605469 2.916239500045776 1.11635684967041 5.439982891082764 L 14.62582969665527 5.439982891082764 C 14.03125667572021 2.916239500045776 11.22854995727539 1.000002861022949 7.871093273162842 1.000002861022949 M 7.871093273162842 2.86102294921875e-06 C 12.21818351745605 2.86102294921875e-06 15.74218368530273 2.883282899856567 15.74218368530273 6.439982891082764 L 2.86102294921875e-06 6.439982891082764 C 2.86102294921875e-06 2.883282899856567 3.524013519287109 2.86102294921875e-06 7.871093273162842 2.86102294921875e-06 Z" stroke="none" fill="#fff"/>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="message">
                      {
                        message.settings.image ?
                          <ComponentImage
                            id={message.settings.image}
                          />
                        : null
                      }
                      <ElementText
                        {...this.props}
                        fieldName="text"
                        text={message.settings.text}
                        placeholder="Titel..."
                        repeaterFieldName="messages"
                        itemId={message.id}
                      />
                    </div>
                  </div>
                </div>
              )
            })*/
          }
        </div>
      </div>
    )
  }
}

export default ElementMultipleChoice
