import React from 'react'

import InputBase from './InputBase'

class InputSwitch extends InputBase {
  handleChangeField(e) {
    this.changeField(e.target.checked)
  }

  html() {
    const {
      name = '',
      label = '',
      onLabel = 'An',
      offLabel = 'Aus'
    } = this.props.field
    const {
      value = ''
    } = this.props

    return(
      <div className="input input-switch">
        <input
          type="checkbox"
          id={this.props.inputKey + '_' + name}
          checked={value}
          onChange={this.handleChangeField.bind(this)}
        />
        <label htmlFor={this.props.inputKey + '_' + name}>
          <div className="label">{label}</div>
          <div className="switch">
            <div className="rail">
              <div className="label label-on">{onLabel}</div>
              <div className="label label-off">{offLabel}</div>
            </div>
            <div className="handle"></div>
          </div>
        </label>
      </div>
    )
  }
}

export default InputSwitch
