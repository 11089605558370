import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementPairsPairsList from './ElementPairsPairsList'

class ElementPairs extends ElementBase {
  handleChangePair(args) {
    var pairs = this.props.element.settings.pairs
    pairs.map((pair, index) => {
      if(pair.id === args.itemId) {
        pairs[index].settings[args.fieldName] = args.fieldValue
      }
      return null
    })
    this.props.onChangeField({
      elementId: this.props.element.id,
      fieldName: 'pair',
      fieldValue: pairs
    })
  }

  html() {
    return(
      <div className="pairs">
        <ElementBox
          {...this.props}
        />
        <ElementPairsPairsList
          pairs={this.props.element.settings.pairs}
          onChangePair={this.handleChangePair.bind(this)}
        />
        <div className="buttons">
          <div className="button resolve">Zur Auflösung</div>
        </div>
      </div>
    )
  }
}

export default ElementPairs
