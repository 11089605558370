// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  sessionStorage.removeItem('userId');
  sessionStorage.removeItem('mail');
  sessionStorage.removeItem('firstName');
  sessionStorage.removeItem('lastName');
  sessionStorage.removeItem('profileImage');
}

// set the token and user from the session storage
export const setUserSession = (token, id, mail, firstName, lastName, profileImage) => {
  localStorage.setItem('token', token)
  sessionStorage.setItem('userId', JSON.stringify(id))
  sessionStorage.setItem('mail', JSON.stringify(mail))
  sessionStorage.setItem('firstName', JSON.stringify(firstName))
  sessionStorage.setItem('lastName', JSON.stringify(lastName))
  sessionStorage.setItem('profileImage', JSON.stringify(profileImage))
}
