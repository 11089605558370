import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ElementWYSIWYG from './components/ElementWYSIWYG'
import ComponentImage from './components/Image'

class ElementTabs extends ElementBase {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0
    }
  }

  openTab(index) {
    this.setState({
      activeTab: index
    })
  }

  html() {
    var tabsButtons = []
    var tabsBoxes = []
    if(this.props.element.settings !== undefined && this.props.element.settings.tabs !== undefined && this.props.element.settings.tabs.length > 0) {
      tabsButtons = this.props.element.settings.tabs.map((tab, index) => {
        return(
          <div
            key={index}
            className={'tabs-button' + (index === this.state.activeTab ? ' active' : '')}
            onClick={this.openTab.bind(this, index)}
          >
            {tab.settings.headline}
          </div>
        )
      })
      tabsBoxes = this.props.element.settings.tabs.map((tab, index) => {
        var tabHeadline = tab.settings.headline;
        if(tabHeadline == '') {
          tabHeadline = 'Tab ' + (index + 1)
        }
        return(
          <div
            key={index}
            className={'tabs-box' + (index === this.state.activeTab ? ' active' : '')}
          >
            <div className="headline h2">
              <ElementTextarea
                {...this.props}
                fieldName="headline"
                text={tabHeadline}
                placeholder="Titel..."
                repeaterFieldName="tabs"
                itemId={tab.id}
              />
            </div>
            <div className="text">
              <ElementWYSIWYG
                {...this.props}
                fieldName="text"
                text={tab.settings.text}
                placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
                repeaterFieldName="tabs"
                itemId={tab.id}
              />
            </div>
          </div>
        )
      })
    }
    return(
      <div className="tabs">
        <div className="tabs-buttons">
          {tabsButtons}
        </div>
        <div className="tabs-boxes">
          {tabsBoxes}
        </div>
      </div>
    )
  }
}

export default ElementTabs
