import React from 'react'

class Help extends React.Component {
  render() {
    return(
      <iframe id="help" title="help" src="https://hilfe.fp-eauthor.fp-server.com/"></iframe>
    )
  }
}

export default Help
