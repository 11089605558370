import React from 'react'

import InputBase from './InputBase'

class InputSelect extends InputBase {
  handleChangeField(e) {
    this.changeField(e.target.value)
  }

  html() {
    var {
      name = '',
      label = '',
      options = []
    } = this.props.field
    const {
      value = ''
    } = this.props

    if(!Array.isArray(options)) {
      var res = options.split('.');
      options = []
      this.props.fieldsSettings[res[0]].map((option, index) => {
        var name = option.settings[res[1]]
        if(name === '') {
          name = 'Feld '+ (index + 1)
        }
        options.push({
          name: name,
          value: index
        })
        return null
      })
    }

    return(
      <div className="input input-select">
        <select
          className="light"
          value={value}
          onChange={this.handleChangeField.bind(this)}
        >
          {
            options.map((option, index) => {
              return(
                <option
                  key={this.props.inputKey + '_' + name + '_' + option.value}
                  value={option.value}
                >
                  {option.name}
                </option>
              )
            })
          }
        </select>
        <div className="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="7.992" height="4.35" viewBox="0 0 7.992 4.35">
            <path d="M2965.981,868.477l-3.819,3.819-3.82-3.819" transform="translate(-2958.166 -868.3)" strokeWidth="0.5" />
          </svg>
        </div>
      </div>
    )
  }
}

export default InputSelect
