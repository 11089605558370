import CmsHelper from './Cms'
import ConfigHelper from './Config'

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

class Wbt {
  static deepCopy(object) {
    return JSON.parse(JSON.stringify(object))
  }

  static randomId() {
    return Math.random().toString(36).substr(2, 8)
  }

  static async getWbt(id) {
    var response = await CmsHelper.get({
      type: 'wbts',
      id: id
    })
    if(response !== undefined) {
      var wbt = {}
      wbt.id = response._id;
      var defaultLang = response.default_language;
      if(defaultLang == undefined || defaultLang == '') {
        defaultLang = 'de'
      }
      var translations = response.translations;
      if(!(typeof translations === 'object' && translations !== null)) {
        translations = []
      }
      if(translations == undefined || translations == '') {
        translations = []
      }
      var translatedStrings = response.translated_strings;
      if(!(typeof translatedStrings === 'object' && translatedStrings !== null)) {
        translatedStrings = []
      }
      if(translatedStrings == undefined || translatedStrings == '') {
        translatedStrings = []
      }
      wbt.settings = {
        image: response.image,
        title: response.title,
        created_by: response.created_by,
        categories: response.categories,
        defaultLang: defaultLang,
        translations: translations,
        translatedStrings: translatedStrings
      }
      wbt.modules = response.content.modules
      wbt.overlays = [{
        id: '123asd',
        settings: {},
        elements: []
      }]
      wbt.type = response.type
      return wbt
    } else {
      return null
    }
  }

  static async saveWbt(wbt) {
    await CmsHelper.put({
      type: 'wbts',
      id: wbt.id,
      body: JSON.stringify({
        image: wbt.settings.image,
        title: wbt.settings.title,
        created_by: wbt.settings.created_by,
        categories: wbt.settings.categories,
        default_language: wbt.settings.defaultLang,
        translations: wbt.settings.translations,
        translated_strings: Object.assign({}, wbt.settings.translatedStrings),
        content: {
          modules: wbt.modules
        }
      })
    })
    await CmsHelper.put({
      type: 'revisions',
      body: JSON.stringify({
        id: wbt.id,
        created_by: JSON.parse(sessionStorage.getItem('userId')),
        content: {
          modules: wbt.modules
        }
      })
    })
  }

  static getModule(args, wbt) {
    var getElementModuleIndex
    wbt.modules.map((module, moduleIndex) => {
      if(module.id === args.moduleId) {
        getElementModuleIndex = moduleIndex
      }
      return null
    })
    return wbt.modules[getElementModuleIndex]
  }

  static getElement(args, wbt) {
    var getElementModuleIndex
    var getElementElementIndex
    if(args.overlayId !== undefined) {
      wbt.overlays.map((module, moduleIndex) => {
        if(module.id === args.overlayId) {
          getElementModuleIndex = moduleIndex
          if(args.elementId !== undefined) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                getElementElementIndex = elementIndex
              }
              return null
            })
          }
        }
        return null
      })
      return wbt.overlays[getElementModuleIndex].elements[getElementElementIndex]
    } else {
      wbt.modules.map((module, moduleIndex) => {
        if(module.id === args.moduleId) {
          getElementModuleIndex = moduleIndex
          if(args.elementId !== undefined) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                getElementElementIndex = elementIndex
              }
              return null
            })
          }
        }
        return null
      })
      if(wbt.modules[getElementModuleIndex] !== undefined) {
        return wbt.modules[getElementModuleIndex].elements[getElementElementIndex]
      }
    }
  }

  static duplicate(args, wbt) {
    wbt = this.deepCopy(wbt)
    var duplicateModuleIndex
    var duplicateElementIndex
    var duplicateColumnIndex
    var duplicateColumnElementIndex
    wbt.modules.map((module, moduleIndex) => {
      if(module.id === args.moduleId) {
        duplicateModuleIndex = moduleIndex
        if(args.elementId !== undefined) {
          module.elements.map((element, elementIndex) => {
            if(element.id === args.elementId) {
              duplicateElementIndex = elementIndex
              if(args.columnElementId !== undefined) {
                element.settings.columns.map((column, columnIndex) => {
                  if(column.id === args.columnId) {
                    duplicateColumnIndex = columnIndex
                    column.settings.elements.map((columnElement, columnElementIndex) => {
                      if(columnElement.id === args.columnElementId) {
                        duplicateColumnElementIndex = columnElementIndex
                      }
                      return null
                    })
                  }
                  return null
                })
              }
            }
            return null
          })
        }
      }
      return null
    })
    if(args.columnElementId !== undefined) {
      var columnElement = this.deepCopy(wbt.modules[duplicateModuleIndex].elements[duplicateElementIndex].settings.columns[duplicateColumnIndex].settings.elements[duplicateColumnElementIndex])
      columnElement.id = this.randomId()
      wbt.modules[duplicateModuleIndex].elements[duplicateElementIndex].settings.columns[duplicateColumnIndex].settings.elements.splice(
        duplicateColumnElementIndex,
        0,
        columnElement
      )
    } else if(args.elementId !== undefined) {
      var element = this.deepCopy(wbt.modules[duplicateModuleIndex].elements[duplicateElementIndex])
      element.id = this.randomId()
      if(element.type === 'columns') {
        element.settings.columns.map((column, columnIndex) => {
          element.settings.columns[columnIndex].id = this.randomId()
          column.settings.elements.map((columnElement, columnElementIndex) => {
            element.settings.columns[columnIndex].settings.elements[columnElementIndex].id = this.randomId()
            return null
          })
          return null
        })
      }
      wbt.modules[duplicateModuleIndex].elements.splice(
        duplicateElementIndex + 1,
        0,
        element
      )
    } else {
      var module = this.deepCopy(wbt.modules[duplicateModuleIndex])
      module.id = this.randomId()
      module.elements.map((element, elementIndex) => {
        module.elements[elementIndex].id = this.randomId()
        if(element.type === 'columns') {
          element.settings.columns.map((column, columnIndex) => {
            module.elements[elementIndex].settings.columns[columnIndex].id = this.randomId()
            column.settings.elements.map((columnElement, columnElementIndex) => {
              module.elements[elementIndex].settings.columns[columnIndex].settings.elements[columnElementIndex].id = this.randomId()
              return null
            })
            return null
          })
        }
        return null
      })
      wbt.modules.splice(
        duplicateModuleIndex + 1,
        0,
        module
      )
    }
    return wbt
  }

  static delete(args, wbt) {
    wbt = this.deepCopy(wbt)
    if(args.overlayId !== undefined) {
      wbt.overlays.map((module, moduleIndex) => {
        if(module.id === args.overlayId) {
          if(args.elementId !== undefined) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(args.columnElementId !== undefined) {
                  element.settings.columns.map((column, columnIndex) => {
                    if(column.id === args.columnId) {
                      column.settings.elements.map((columnElement, columnElementIndex) => {
                        if(columnElement.id === args.columnElementId) {
                          wbt.overlays[moduleIndex].elements[elementIndex].settings.columns[columnIndex].settings.elements.splice(columnElementIndex, 1)
                        }
                        return null
                      })
                    }
                    return null
                  })
                } else {
                  wbt.overlays[moduleIndex].elements.splice(elementIndex, 1)
                }
              }
              return null
            })
          } else {
            wbt.overlays.splice(moduleIndex, 1)
          }
        }
        return null
      })
    } else {
      wbt.modules.map((module, moduleIndex) => {
        if(module.id === args.moduleId) {
          if(args.elementId !== undefined) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(args.columnElementId !== undefined) {
                  element.settings.columns.map((column, columnIndex) => {
                    if(column.id === args.columnId) {
                      column.settings.elements.map((columnElement, columnElementIndex) => {
                        if(columnElement.id === args.columnElementId) {
                          wbt.modules[moduleIndex].elements[elementIndex].settings.columns[columnIndex].settings.elements.splice(columnElementIndex, 1)
                        }
                        return null
                      })
                    }
                    return null
                  })
                } else {
                  wbt.modules[moduleIndex].elements.splice(elementIndex, 1)
                }
              }
              return null
            })
          } else {
            wbt.modules.splice(moduleIndex, 1)
          }
        }
        return null
      })
    }
    return wbt
  }

  static add(args, wbt) {
    wbt = this.deepCopy(wbt)
    var settings = {}
    var elementConfig = ConfigHelper.getElementConfig(args.elementType)
    if(args.columnElementIndex !== undefined) {
      if(elementConfig.settings.sections !== undefined) {
        elementConfig.settings.sections.map((section, sectionIndex) => {
          section.fields.map((field, fieldIndex) => {
            if(field.defaultValue !== undefined) {
              var defaultValue = field.defaultValue
              if(field.type === 'repeater') {
                defaultValue.map((item, itemIndex) => {
                  defaultValue[itemIndex].id = this.randomId()
                  return null
                })
              }
              settings[field.name] = defaultValue
            } else if(field.type === 'repeater') {
              settings[field.name] = []
            } else {
              settings[field.name] = ''
            }
            return null
          })
          return null
        })
      }
      if(args.overlayId !== undefined) {
        wbt.overlays[0].elements[args.elementIndex].settings.columns[args.columnIndex].settings.elements.splice(
          args.columnElementIndex,
          0,
          {
            id: this.randomId(),
            type: args.elementType,
            settings: settings
          }
        )
      } else {
        wbt.modules[args.moduleIndex].elements[args.elementIndex].settings.columns[args.columnIndex].settings.elements.splice(
          args.columnElementIndex,
          0,
          {
            id: this.randomId(),
            type: args.elementType,
            settings: settings
          }
        )
      }
    } else if(args.elementIndex !== undefined) {
      if(elementConfig.settings.sections !== undefined) {
        elementConfig.settings.sections.map((section, sectionIndex) => {
          section.fields.map((field, fieldIndex) => {
            if(field.defaultValue !== undefined) {
              var defaultValue = field.defaultValue
              if(field.type === 'repeater') {
                defaultValue.map((item, itemIndex) => {
                  defaultValue[itemIndex].id = this.randomId()
                  return null
                })
              }
              settings[field.name] = defaultValue
            } else if(field.type === 'repeater') {
              settings[field.name] = []
            } else {
              settings[field.name] = ''
            }
            return null
          })
          return null
        })
      }
      if(args.newColumn !== undefined) {
        if(args.overlayId !== undefined) {
          var element = wbt.overlays[0].elements[args.elementIndex]
          wbt.overlays[0].elements.splice(
            args.elementIndex,
            0,
            {
              id: this.randomId(),
              type: 'columns',
              settings: {
                columns: [
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  },
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  }
                ]
              }
            }
          )
          wbt.overlays[0].elements[args.elementIndex].settings.columns[(args.newColumn === 'left' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            {
              id: this.randomId(),
              type: args.elementType,
              settings: settings
            }
          )
          wbt.overlays[0].elements[args.elementIndex].settings.columns[(args.newColumn === 'right' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            element
          )
          wbt.overlays[0].elements.splice(args.elementIndex + 1, 1)
        } else {
          var element = wbt.modules[args.moduleIndex].elements[args.elementIndex]
          wbt.modules[args.moduleIndex].elements.splice(
            args.elementIndex,
            0,
            {
              id: this.randomId(),
              type: 'columns',
              settings: {
                columns: [
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  },
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  }
                ]
              }
            }
          )
          wbt.modules[args.moduleIndex].elements[args.elementIndex].settings.columns[(args.newColumn === 'left' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            {
              id: this.randomId(),
              type: args.elementType,
              settings: settings
            }
          )
          wbt.modules[args.moduleIndex].elements[args.elementIndex].settings.columns[(args.newColumn === 'right' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            element
          )
          wbt.modules[args.moduleIndex].elements.splice(args.elementIndex + 1, 1)
        }
      } else {
        if(args.overlayId !== undefined) {
          wbt.overlays[0].elements.splice(
            args.elementIndex,
            0,
            {
              id: this.randomId(),
              type: args.elementType,
              settings: settings
            }
          )
        } else {
          wbt.modules[args.moduleIndex].elements.splice(
            args.elementIndex,
            0,
            {
              id: this.randomId(),
              type: args.elementType,
              settings: settings
            }
          )
        }
      }
    } else {
      var moduleConfig = ConfigHelper.getModuleConfig()
      if(moduleConfig.settings.sections !== undefined) {
        moduleConfig.settings.sections.map((section, sectionIndex) => {
          section.fields.map((field, fieldIndex) => {
            if(field.defaultValue !== undefined) {
              var defaultValue = field.defaultValue
              if(field.type === 'repeater') {
                defaultValue.map((item, itemIndex) => {
                  defaultValue[itemIndex].id = this.randomId()
                  return null
                })
              }
              settings[field.name] = defaultValue
            } else if(field.type === 'repeater') {
              settings[field.name] = []
            } else {
              settings[field.name] = ''
            }
            return null
          })
          return null
        })
      }
      wbt.modules.splice(
        args.moduleIndex,
        0,
        {
          id: this.randomId(),
          settings: settings,
          elements: []
        }
      )
    }
    return wbt
  }

  static addInside(args, wbt) {
    wbt = this.deepCopy(wbt)
    var settings = {}
    var elementConfig = ConfigHelper.getElementConfig(args.elementType)

    if(elementConfig.settings.sections !== undefined) {
      elementConfig.settings.sections.map((section, sectionIndex) => {
        section.fields.map((field, fieldIndex) => {
          if(field.defaultValue !== undefined) {
            var defaultValue = field.defaultValue
            if(field.type === 'repeater') {
              defaultValue.map((item, itemIndex) => {
                defaultValue[itemIndex].id = this.randomId()
                return null
              })
            }
            settings[field.name] = defaultValue
          } else if(field.type === 'repeater') {
            settings[field.name] = []
          } else {
            settings[field.name] = ''
          }
          return null
        })
        return null
      })
    }
    if(args.overlayId !== undefined) {
      wbt.overlays[0].elements[args.elementIndex].settings.columns.splice(
        args.columnIndex + (args.newColumn === 'right' ? 1 : 0),
        0,
        {
          id: this.randomId(),
          settings: {
            elements: [
              {
                id: this.randomId(),
                type: args.elementType,
                settings: settings
              }
            ]
          }
        }
      )
    } else {
      wbt.modules[args.moduleIndex].elements[args.elementIndex].settings.columns.splice(
        args.columnIndex + (args.newColumn === 'right' ? 1 : 0),
        0,
        {
          id: this.randomId(),
          settings: {
            elements: [
              {
                id: this.randomId(),
                type: args.elementType,
                settings: settings
              }
            ]
          }
        }
      )
    }

    return wbt
  }

  static move(args, wbt) {
    wbt = this.deepCopy(wbt)
    var skip = false
    var source = args.source
    var target = args.target
    var sourceModule
    var sourceElement
    var sourceModuleIndex
    var sourceElementIndex
    var sourceColumnIndex
    var sourceColumnElementIndex
    wbt.modules.map((module, moduleIndex) => {
      if(module.id === source.moduleId) {
        sourceModuleIndex = moduleIndex
        if(source.elementId !== undefined) {
          module.elements.map((element, elementIndex) => {
            if(element.id === source.elementId) {
              sourceElementIndex = elementIndex
              if(source.columnElementId !== undefined) {
                element.settings.columns.map((column, columnIndex) => {
                  if(column.id === source.columnId) {
                    column.settings.elements.map((columnElement, columnElementIndex) => {
                      if(columnElement.id === source.columnElementId) {
                        sourceElement = columnElement
                        sourceColumnIndex = columnIndex
                        sourceColumnElementIndex = columnElementIndex
                      }
                      return null
                    })
                  }
                  return null
                })
              } else {
                sourceElement = element
              }
            }
            return null
          })
        }
        sourceModule = module
      }
      return null
    })
    if(sourceColumnElementIndex !== undefined) {
      wbt.modules[sourceModuleIndex].elements[sourceElementIndex].settings.columns[sourceColumnIndex].settings.elements.splice(sourceColumnElementIndex, 1)
    } else if(sourceElementIndex !== undefined) {
      if(sourceElementIndex !== target.elementIndex || sourceModuleIndex !== target.moduleIndex) {
        wbt.modules[sourceModuleIndex].elements.splice(sourceElementIndex, 1)
      } else {
        skip = true
      }
    } else {
      wbt.modules.splice(sourceModuleIndex, 1)
    }
    if(!skip) {
      var targetModuleIndex = target.moduleIndex
      var targetElementIndex = target.elementIndex
      var targetColumnIndex = target.columnIndex
      var targetColumnElementIndex = target.columnElementIndex
      if(targetModuleIndex > sourceModuleIndex && sourceElementIndex === undefined) {
        targetModuleIndex -= 1
      }
      if(targetModuleIndex === sourceModuleIndex && targetElementIndex > sourceElementIndex && sourceColumnElementIndex === undefined) {
        targetElementIndex -= 1
      }
      if(targetColumnElementIndex !== undefined) {
        if(targetColumnIndex === sourceColumnIndex && targetColumnElementIndex > sourceColumnElementIndex) {
          targetColumnElementIndex -= 1
        }
        wbt.modules[targetModuleIndex].elements[targetElementIndex].settings.columns[targetColumnIndex].settings.elements.splice(
          targetColumnElementIndex,
          0,
          sourceElement
        )
      } else if(targetElementIndex !== undefined) {
        if(target.newColumn !== undefined) {
          var targetElement = wbt.modules[targetModuleIndex].elements[targetElementIndex]
          wbt.modules[targetModuleIndex].elements.splice(
            targetElementIndex,
            0,
            {
              id: this.randomId(),
              type: 'columns',
              settings: {
                columns: [
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  },
                  {
                    id: this.randomId(),
                    settings: {
                      elements: []
                    }
                  }
                ]
              }
            }
          )
          wbt.modules[targetModuleIndex].elements[targetElementIndex].settings.columns[(target.newColumn === 'left' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            sourceElement
          )
          wbt.modules[targetModuleIndex].elements[targetElementIndex].settings.columns[(target.newColumn === 'right' ? 0 : 1)].settings.elements.splice(
            0,
            0,
            targetElement
          )
          wbt.modules[target.moduleIndex].elements.splice(target.elementIndex + 1, 1)
        } else {
          wbt.modules[targetModuleIndex].elements.splice(
            targetElementIndex,
            0,
            sourceElement
          )
        }
      } else {
        if(sourceElementIndex !== undefined) {
          wbt.modules[targetModuleIndex].elements.splice(
            0,
            0,
            sourceElement
          )
        } else {
          wbt.modules.splice(
            targetModuleIndex,
            0,
            sourceModule
          )
        }
      }
    }

    return wbt
  }

  static moveInside(args, wbt) {
    wbt = this.deepCopy(wbt)
    var source = args.source
    var target = args.target
    var sourceElement
    var sourceModuleIndex
    var sourceElementIndex
    var sourceColumnIndex
    var sourceColumnElementIndex
    wbt.modules.map((module, moduleIndex) => {
      if(module.id === source.moduleId) {
        module.elements.map((element, elementIndex) => {
          if(element.id === source.elementId) {
            sourceModuleIndex = moduleIndex
            sourceElementIndex = elementIndex
            if(source.columnElementId !== undefined) {
              element.settings.columns.map((column, columnIndex) => {
                if(column.id === source.columnId) {
                  column.settings.elements.map((columnElement, columnElementIndex) => {
                    if(columnElement.id === source.columnElementId) {
                      sourceElement = columnElement
                      sourceColumnIndex = columnIndex
                      sourceColumnElementIndex = columnElementIndex
                    }
                    return null
                  })
                }
                return null
              })
            } else {
              sourceElement = element
            }
          }
          return null
        })
      }
      return null
    })
    if(sourceColumnElementIndex !== undefined) {
      wbt.modules[sourceModuleIndex].elements[sourceElementIndex].settings.columns[sourceColumnIndex].settings.elements.splice(sourceColumnElementIndex, 1)
    } else {
      wbt.modules[sourceModuleIndex].elements.splice(sourceElementIndex, 1)
    }
    var targetModuleIndex = target.moduleIndex
    var targetElementIndex = target.elementIndex
    var targetColumnIndex = target.columnIndex
    var targetColumnElementIndex = target.columnElementIndex
    if(targetModuleIndex === sourceModuleIndex && targetElementIndex > sourceElementIndex && sourceColumnElementIndex === undefined) {
      targetElementIndex -= 1
    }
    /*if(targetColumnElementIndex !== undefined) {
      if(targetColumnIndex === sourceColumnIndex && targetColumnElementIndex > sourceColumnElementIndex) {
        targetColumnElementIndex -= 1
      }
      wbt.modules[targetModuleIndex].elements[targetElementIndex].settings.columns[targetColumnIndex].settings.elements.splice(
        targetColumnElementIndex,
        0,
        sourceElement
      )
    } else {
      wbt.modules[targetModuleIndex].elements.splice(
        targetElementIndex,
        0,
        sourceElement
      )
    }*/
    wbt.modules[targetModuleIndex].elements[targetElementIndex].settings.columns.splice(
      targetColumnIndex + (args.target.newColumn === 'right' ? 1 : 0),
      0,
      {
        id: this.randomId(),
        settings: {
          elements: [
            sourceElement
          ]
        }
      }
    )
    return wbt
  }

  static changeFieldItem(args, wbt) {
    console.log(args)
    wbt = this.deepCopy(wbt)
    if(args.overlayId !== undefined) {
      if(args.elementId !== undefined) {
        wbt.overlays.map((module, moduleIndex) => {
          if(module.id === args.overlayId) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(1 == 1) {
                  element.settings[args.outerFieldName].map((column, columnIndex) => {
                    if(column.id === args.itemId) {
                      wbt.overlays[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.fieldName] = args.fieldValue
                    }
                    return null
                  })
                } else {
                  wbt.overlays[moduleIndex].elements[elementIndex].settings[args.fieldName] = args.fieldValue
                }
              }
              return null
            })
          }
          return null
        })
      }
    } else {
      if(args.elementId !== undefined) {
        wbt.modules.map((module, moduleIndex) => {
          if(module.id === args.moduleId) {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(1 == 1) {
                  element.settings[args.outerFieldName].map((column, columnIndex) => {
                    var itemIdSplit = args.itemId.split('_')
                    console.log(itemIdSplit)
                    if(itemIdSplit.length > 1) {
                      if(column.id === itemIdSplit[0]) {
                        column.settings[args.outerFieldName].map((c1, cIndex1) => {
                          if(c1.id === itemIdSplit[1]) {
                            if(itemIdSplit.length > 2) {
                              c1.settings[args.outerFieldName].map((c2, cIndex2) => {
                                if(c2.id === itemIdSplit[2]) {
                                  if(itemIdSplit.length > 3) {
                                    c2.settings[args.outerFieldName].map((c3, cIndex3) => {
                                      if(c3.id === itemIdSplit[3]) {
                                        if(itemIdSplit.length > 4) {
                                          c3.settings[args.outerFieldName].map((c4, cIndex4) => {
                                            if(c4.id === itemIdSplit[4]) {
                                              if(itemIdSplit.length > 5) {
                                                c4.settings[args.outerFieldName].map((c5, cIndex5) => {
                                                  if(c5.id === itemIdSplit[5]) {
                                                    if(itemIdSplit.length > 6) {
                                                      c5.settings[args.outerFieldName].map((c6, cIndex6) => {
                                                        if(c6.id === itemIdSplit[6]) {
                                                          wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.outerFieldName][cIndex2].settings[args.outerFieldName][cIndex3].settings[args.outerFieldName][cIndex4].settings[args.outerFieldName][cIndex5].settings[args.outerFieldName][cIndex6].settings[args.fieldName] = args.fieldValue
                                                        }
                                                      })
                                                    } else {
                                                      wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.outerFieldName][cIndex2].settings[args.outerFieldName][cIndex3].settings[args.outerFieldName][cIndex4].settings[args.outerFieldName][cIndex5].settings[args.fieldName] = args.fieldValue
                                                    }
                                                  }
                                                })
                                              } else {
                                                wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.outerFieldName][cIndex2].settings[args.outerFieldName][cIndex3].settings[args.outerFieldName][cIndex4].settings[args.fieldName] = args.fieldValue
                                              }
                                            }
                                          })
                                        } else {
                                          wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.outerFieldName][cIndex2].settings[args.outerFieldName][cIndex3].settings[args.fieldName] = args.fieldValue
                                        }
                                      }
                                    })
                                  } else {
                                    wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.outerFieldName][cIndex2].settings[args.fieldName] = args.fieldValue
                                  }
                                }
                              })
                            } else {
                              wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.outerFieldName][cIndex1].settings[args.fieldName] = args.fieldValue
                            }
                          }
                        })
                      }
                    } else {
                      if(column.id === args.itemId) {
                        wbt.modules[moduleIndex].elements[elementIndex].settings[args.outerFieldName][columnIndex].settings[args.fieldName] = args.fieldValue
                      }
                    }
                    return null
                  })
                } else {
                  wbt.modules[moduleIndex].elements[elementIndex].settings[args.fieldName] = args.fieldValue
                }
              }
              return null
            })
          }
          return null
        })
      }
    }
    return wbt
  }

  static changeField(args, wbt) {
    wbt = this.deepCopy(wbt)
    if(args.overlayId !== undefined) {
      wbt.overlays.map((module, moduleIndex) => {
        if(module.id === args.overlayId) {
          if(args.elementId !== undefined && args.elementId !== '') {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(args.columnElementId !== undefined) {
                  element.settings.columns.map((column, columnIndex) => {
                    if(column.id === args.columnId) {
                      column.settings.elements.map((columnElement, columnElementIndex) => {
                        if(columnElement.id === args.columnElementId) {
                          wbt.overlays[moduleIndex].elements[elementIndex].settings.columns[columnIndex].settings.elements[columnElementIndex].settings[args.fieldName] = args.fieldValue
                        }
                        return null
                      })
                    }
                    return null
                  })
                } else {
                  wbt.overlays[moduleIndex].elements[elementIndex].settings[args.fieldName] = args.fieldValue
                }
              }
              return null
            })
          } else {
            wbt.overlays[moduleIndex].settings[args.fieldName] = args.fieldValue
          }
        }
        return null
      })
    } else {
      wbt.modules.map((module, moduleIndex) => {
        if(module.id === args.moduleId) {
          if(args.elementId !== undefined && args.elementId !== '') {
            module.elements.map((element, elementIndex) => {
              if(element.id === args.elementId) {
                if(args.columnElementId !== undefined) {
                  element.settings.columns.map((column, columnIndex) => {
                    if(column.id === args.columnId) {
                      column.settings.elements.map((columnElement, columnElementIndex) => {
                        if(columnElement.id === args.columnElementId) {
                          wbt.modules[moduleIndex].elements[elementIndex].settings.columns[columnIndex].settings.elements[columnElementIndex].settings[args.fieldName] = args.fieldValue
                        }
                        return null
                      })
                    }
                    return null
                  })
                } else {
                  wbt.modules[moduleIndex].elements[elementIndex].settings[args.fieldName] = args.fieldValue
                }
              }
              return null
            })
          } else {
            wbt.modules[moduleIndex].settings[args.fieldName] = args.fieldValue
          }
        }
        return null
      })
    }
    return wbt
  }

  static changeOrder(args, wbt) {
    wbt = this.deepCopy(wbt)
    wbt.modules.map((module, moduleIndex) => {
      if(module.id === args.moduleId) {
        module.elements.map((element, elementIndex) => {
          if(element.id === args.elementId) {
            var items = wbt.modules[moduleIndex].elements[elementIndex].settings[args.fieldName]
            items.move(args.oldIndex, args.newIndex);
            wbt.modules[moduleIndex].elements[elementIndex].settings[args.fieldName] = items
          }
          return null
        })
      }
      return null
    })
    return wbt
  }

  static changeModuleHeadline(args, wbt) {
    wbt = this.deepCopy(wbt)
    if(args.moduleId !== undefined) {
      wbt.modules.map((module, moduleIndex) => {
        if(module.id === args.moduleId) {
          wbt.modules[moduleIndex].settings.headline = args.headline
        }
        return null
      })
    }
    return wbt
  }

  static changeSettings(args, wbt) {
    wbt = this.deepCopy(wbt)
    var settings = {...wbt.settings, ...args}
    wbt.settings = settings
    return wbt
  }
}

export default Wbt
