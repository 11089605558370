import React from 'react'

import ElementBase from './ElementBase'
import ElementColumnsColumn from './ElementColumnsColumn'

class ElementColumns extends ElementBase {
  html() {
    var columns = <div className="column-placeholder"></div>
    if(this.props.element.settings.columns !== undefined && this.props.element.settings.columns.length > 0) {
      columns = this.props.element.settings.columns.map((column, index) => {
        return(
          <ElementColumnsColumn
            key={column.id}
            {...this.props}
            columnIndex={index}
            column={column}
            columnsCount={this.props.element.settings.columns.length}
          />
        )
      })
    }
    return(
      <div className="columns">{columns}</div>
    )
  }
}

export default ElementColumns
