import React from 'react'

import List from './list/List'

class WBTs extends React.Component {
  render() {
    return(
      <div id="main">
        <button
          className="watch-video"
          onClick={() => this.props.onOpenHelpVideo(448967966)}
        >
          <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
              <g transform="translate(-835 -104)">
                <g transform="translate(835 104)" fill="none" stroke="#a500d9" strokeWidth="1">
                  <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
                  <circle cx="6.5" cy="6.5" r="6" fill="none" />
                </g>
                <path d="M840.533,104.941l2.731,2.731-2.731,2.731" transform="translate(-0.056 2.961)" fill="none" stroke="#8800c4" strokeLinecap="round" strokeWidth="1" />
              </g>
            </svg>
          </span>
          <span className="label">Schau dir ein kleines Video zu WBTs an</span>
        </button>
        <h1>WBTs</h1>
        <List
          {...this.props}
        />
      </div>
    )
  }
}

export default WBTs
