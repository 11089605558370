import React from 'react'

import InputBase from './InputBase'
import InputCoordinates from './InputCoordinates'
import InputColumnElements from './InputColumnElements'
import InputImage from './InputImage'
import InputAudio from './InputAudio'
import InputVideo from './InputVideo'
import InputOverlay from './InputOverlay'
import InputSwitch from './InputSwitch'
import InputText from './InputText'
import InputTextarea from './InputTextarea'
import InputWYSIWYG from './InputWYSIWYG'
import InputSelect from './InputSelect'
import InputRepeater from './InputRepeater'
import InputTeaserTypes from './InputTeaserTypes'

class Input extends React.Component {
  render() {
    switch(this.props.field.type) {
      case 'column_elements':
        return(
          <InputColumnElements
            {...this.props}
          />
        )
      case 'coordinates':
        return(
          <InputCoordinates
            {...this.props}
          />
        )
      case 'image':
        return(
          <InputImage
            {...this.props}
          />
        )
      case 'audio':
        return(
          <InputAudio
            {...this.props}
          />
        )
      case 'video':
        return(
          <InputVideo
            {...this.props}
          />
        )
      case 'overlay':
        return(
          <InputOverlay
            {...this.props}
          />
        )
      case 'switch':
        return(
          <InputSwitch
            {...this.props}
          />
        )
      case 'teaser_types':
        return(
          <InputTeaserTypes
            {...this.props}
          />
        )
      case 'text':
        return(
          <InputText
            {...this.props}
          />
        )
      case 'textarea':
        return(
          <InputTextarea
            {...this.props}
          />
        )
      case 'wysiwyg':
        return(
          <InputWYSIWYG
            {...this.props}
          />
        )
      case 'select':
        return(
          <InputSelect
            {...this.props}
          />
        )
      case 'repeater':
        return(
          <InputRepeater
            {...this.props}
          />
        )
      default:
        return(
          <InputBase
            {...this.props}
          />
        )
    }
  }
}

export default Input
