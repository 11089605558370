import React from 'react'

import CmsHelper from '../../../../../helpers/Cms'

class Audio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      url: '',
      ext: ''
    }
  }

  componentDidMount() {
    this.setUrl(this.props.id)
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.id !== undefined && props.id !== this.props.id) {
      var thisThis = this
      this.setState({
        url: ''
      }, () => {
        thisThis.setUrl(props.id)
      })
    }
  }

  async setUrl(id) {
    var response = await CmsHelper.get({
      type: 'attachments',
      id: id
    })
    if(response !== undefined && response.file !== undefined) {
      var url = ''
      if(response.file.mime === 'video/mp4') {
        url = CmsHelper.getDirectUrl({
          file: response.file.path
        })
      } else {
        url = CmsHelper.getImageUrl({
          file: response.file.path,
          width: 800
        })
      }
      this.setState({
        url: url,
        ext: response.file.mime
      })
    } else {
      this.setState({
        url: '',
        ext: ''
      })
    }
  }

  render() {
    return(
      this.state.url !== '' ?
        [
          this.state.ext === 'video/mp4' ?
            <video key="1" className="image-video" src={this.state.url} loop autoPlay muted></video>
          :
            <img key="1" className={this.state.ext === 'image/svg+xml' ? 'svg' : ''} src={this.state.url} alt="" />
        ]
      :
        <div key="1" className="image-placeholder"></div>
    )
  }
}

export default Audio
