import React from 'react'
import {Editor, EditorState, ContentState} from 'draft-js'

class ElementSlideControlPoint extends React.Component {
  constructor(props) {
    super(props)

    var text = (this.props.point !== undefined ? this.props.point : '')
    this.state = {
      textContent: EditorState.createWithContent(ContentState.createFromText(text)),
      text: text,
      focus: false
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(!this.state.focus) {
      var selectionState
      var newContentState
      var newEditorState
      if(props.point !== undefined && props.point !== this.state.text) {
        selectionState = this.state.textContent.getSelection()
        newContentState = ContentState.createFromText(props.point)
        newEditorState = EditorState.create({
          currentContent: newContentState,
          selection: selectionState
        })
        this.setState({
          textContent: newEditorState,
          text: props.point
        })
      }
    }
  }

  handleChangeText(textContent) {
    var text = textContent.getCurrentContent().getPlainText('\u0001')
    this.setState({
      textContent: textContent,
      text: text
    })
    this.props.onChangeText(text)
  }

  handleFocus() {
    this.setState({
      focus: true
    })
  }

  handleBlur() {
    this.setState({
      focus: false
    })
  }

  render() {
    return(
      <div className={'point point-' + this.props.position}>
        <Editor
          placeholder="Text..."
          editorState={this.state.textContent}
          onChange={this.handleChangeText.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
        />
      </div>
    )
  }
}

export default ElementSlideControlPoint
