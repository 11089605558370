import React from 'react'

class EditorOverlayDragButton extends React.Component {
  render() {
    return(
      <button className="editor-overlay-bar-button button-drag">
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9">
          <g transform="translate(-781 -163)">
            <rect width="14" height="1" transform="translate(781 163)" />
            <rect width="14" height="1" transform="translate(781 167)" />
            <rect width="14" height="1" transform="translate(781 171)" />
          </g>
        </svg>
      </button>
    )
  }
}

export default EditorOverlayDragButton
