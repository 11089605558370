import React from 'react'

import ElementBase from './ElementBase'
import ElementTeaser from './ElementTeaser'

class ElementTeasers extends ElementBase {
  html() {
    var teasers = <div className="teaser-placeholder"></div>
    if(this.props.element.settings !== undefined && this.props.element.settings.teasers !== undefined && this.props.element.settings.teasers.length > 0) {
      teasers = this.props.element.settings.teasers.map((teaser, index) => {
        return(
          <ElementTeaser
            key={index}
            {...this.props}
            repeaterFieldName="teasers"
            teaser={teaser}
          />
        )
      })
    }
    return(
      <div className="teasers">
        {teasers}
      </div>
    )
  }
}

export default ElementTeasers
