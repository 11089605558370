import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementText from './components/ElementText'

class ElementDragAndDrop extends ElementBase {
  html() {
    var cards = this.props.element.settings.cards;
    return(
      <div className="drag-and-drop">
        <ElementBox
          {...this.props}
        />
        <div className="areas">
          <div className="areas-column">
            {
              this.props.element.settings.fields.map((field, index) => {
                var value = field.settings.field
                if(index % 2 === 0) {
                  return(
                    <div
                      key={field.id}
                      className="area field"
                    >
                      <div className="area-header">
                        <ElementText
                          {...this.props}
                          fieldName="field"
                          text={value}
                          placeholder="Feld"
                          repeaterFieldName="fields"
                          itemId={field.id}
                        />
                      </div>
                      <div className="area-body"></div>
                    </div>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
          <div className="areas-column">
            <div className="area cards">
              {
                this.props.element.settings.cards.map((card, index) => {
                  var value = card.settings.text
                  return(
                    <div
                      key={card.id}
                      className="card"
                    >
                      <ElementText
                        {...this.props}
                        fieldName="text"
                        text={value}
                        placeholder="Text..."
                        repeaterFieldName="cards"
                        itemId={card.id}
                      />
                    </div>
                  )
                })
              }
              <div className="buttons">
                <div className="button resolve">Zur Auflösung</div>
              </div>
            </div>
          </div>
          <div className="areas-column">
            {
              this.props.element.settings.fields.map((field, index) => {
                var value = field.settings.field
                if(index % 2 === 1) {
                  return(
                    <div
                      key={field.id}
                      className="area field"
                    >
                      <div className="area-header">
                        <ElementText
                          {...this.props}
                          fieldName="field"
                          text={value}
                          placeholder="Feld"
                          repeaterFieldName="fields"
                          itemId={field.id}
                        />
                      </div>
                      <div className="area-body"></div>
                    </div>
                  )
                } else {
                  return null
                }
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default ElementDragAndDrop
