import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import InputBase from './InputBase'

class InputTextarea extends InputBase {
  handleChange(e) {
    this.changeField(e.target.value)
  }

  html() {
    const {
      name = '',
      label = ''
    } = this.props.field
    const {
      value = ''
    } = this.props

    return(
      <TextareaAutosize
        key={this.props.inputKey + '_' + name}
        className="light"
        placeholder={label}
        value={value}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

export default InputTextarea
