import React from 'react'

import ElementsElementSourceElement from './ElementsElementSourceElement.js'
import ElementsElementSourceColumnElement from './ElementsElementSourceColumnElement.js'

import CONFIG_ELEMENTS from '../../../../config/elements'

class Elements extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isElementDragging: false
    }
  }

  getElements(category) {
    return CONFIG_ELEMENTS.map((element, index) => {
      if(element.category === category && element.type !== 'columns') {
        const {
          type = '',
          icon = '',
          title = ''
        } = element

        if(element.itemType === 'column-element') {
          return(
            <ElementsElementSourceColumnElement
              key={type}
              category={category}
              type={type}
              icon={icon}
              label={title}
              isElementDragging={this.state.isElementDragging}
              zIndex={CONFIG_ELEMENTS.length - index}
              onDragStart={this.handleDragStart.bind(this)}
              onDragEnd={this.handleDragEnd.bind(this)}
            />
          )
        } else {
          return(
            <ElementsElementSourceElement
              key={type}
              category={category}
              type={type}
              icon={icon}
              label={title}
              isElementDragging={this.state.isElementDragging}
              zIndex={CONFIG_ELEMENTS.length - index}
              onDragStart={this.handleDragStart.bind(this)}
              onDragEnd={this.handleDragEnd.bind(this)}
            />
          )
        }
      } else {
        return null
      }
    })
  }

  handleDragStart() {
    this.setState({
      isElementDragging: true
    })
  }

  handleDragEnd() {
    this.setState({
      isElementDragging: false
    })
  }

  render() {
    return(
      <div className="panel panel-elements">
        <div className="panel-container" data-simplebar data-simplebar-auto-hide="false">
          <div className="sections">
            <div className="section">
              <div className="headline">Inhalt</div>
              <div className="elements">{this.getElements('content')}</div>
            </div>
            <div className="section">
              <div className="headline">Quiz</div>
              <div className="elements">{this.getElements('quiz')}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Elements
