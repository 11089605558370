import React from 'react'

import Input from './inputs/Input'

class Field extends React.Component {
  render() {
    return(
      <Input
        {...this.props}
      />
    )
  }
}

export default Field
