import React from 'react'

import List from './list/List'

class WBTs extends React.Component {
  render() {
    return(
      <div id="main">
        <h1>Vorlagen</h1>
        <List
          {...this.props}
        />
      </div>
    )
  }
}

export default WBTs
