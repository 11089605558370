import React from 'react'

import ElementBase from './ElementBase'
import ElementTextarea from './components/ElementTextarea'
import ElementWYSIWYG from './components/ElementWYSIWYG'
import ComponentImage from './components/Image'

class ElementScrollableText extends ElementBase {
  constructor(props) {
    super(props)
  }

  html() {
    return(
      <div className="scrollable-text">
        <div className="scroll-box">
          <div className="text">
            <ElementWYSIWYG
              {...this.props}
              fieldName="text"
              text={this.props.element.settings.text}
              placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua."
            />
          </div>
        </div>
      </div>
    )
  }
}

export default ElementScrollableText
