import React from 'react'

import ElementBase from './ElementBase'
import ElementBox from './ElementBox'
import ElementText from './components/ElementText'

class ElementFreeTextField extends ElementBase {
  html() {
    return(
      <div className="free-text-field">
        <ElementBox
          {...this.props}
        />
        <div className="input-field">
          <ElementText
            {...this.props}
            fieldName="placeholder"
            text={this.props.element.settings.placeholder}
            placeholder="Platzhalter"
          />
        </div>
      </div>
    )
  }
}

export default ElementFreeTextField
