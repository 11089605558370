import React from 'react'

import CmsHelper from '../../../../../helpers/Cms'

class Audio extends React.Component {
  constructor(props) {
    super(props)

    this.audio = this.props.urls[this.props.element.id + '_audio'] !== undefined ? this.props.urls[this.props.element.id + '_audio'] : '';
  }

  render() {
    return(
      <audio src={this.audio}></audio>
    )
  }
}

export default Audio
