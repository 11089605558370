import React from 'react'
import {DropTarget} from 'react-dnd'
import withScrolling from 'react-dnd-scrollzone';
import Preview, {Context} from 'react-dnd-preview'

import EditorHeader from './header/Header'
import EditorPanel from './panel/Panel'
import EditorPreview from './preview/Preview'
import EditorModal from './modal/Modal'
import AppModal from '../modal/Modal'

import ConfigHelper from '../helpers/Config'
import CmsHelper from '../helpers/Cms'
import WbtHelper from '../helpers/Wbt'

import '../../styles/editor.scss'

const ScrollingComponent = withScrolling('div');

function editorCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

class Editor extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      wbt: {},
      panelModuleId: '',
      panelElementId: '',
      panelView: 'elements',
      isSaved: true,
      modalShow: false,
      modalArgs: {},
      tempArgs: {},
      isDragOverPreview: false,
      actions: [],
      previewMode: false,
      responsiveMode: false,
      overlayId: '',
      appModal: '',
      appModalShow: false,
      appModalData: {},
      comments: [],
      totalOpenComments: 0,
      commentFiles: []
    }
  }

  componentDidMount() {
    this.loadWbt()
    this.loadComments()
  }

  goToComment(elementId) {
    var wbt = this.state.wbt
    var moduleId = ''
    var panelElementId = ''
    var panelView = ''
    if(wbt.modules !== undefined) {
      wbt.modules.map((module, moduleIndex) => {
        if(module.elements !== undefined) {
          module.elements.map((moduleElement, moduleElementIndex) => {
            if(moduleElement.id === elementId) {
              moduleId = module.id
              panelElementId = elementId
              panelView = 'comment'
            }
            return null
          })
        }
        return null
      })
    }
    this.setState({
      panelModuleId: moduleId,
      panelElementId: panelElementId,
      panelView: panelView,
    })
  }

  async loadWbt() {
    const {
      match: {
        params
      }
    } = this.props

    var wbt = await WbtHelper.getWbt(params.wbtId)
    if(wbt !== null) {
      this.setState({
        wbt: wbt
      }, () => {
        this.updateActions('')
      })
    } else {
      this.props.history.push('/wbts')
    }

    if(params.action !== undefined) {
      if(params.action == 'comment') {
        this.goToComment(params.customId)
      }
    }
  }

  handleUpdateComments() {
    this.loadComments()
  }

  async loadComments() {
    const {
      match: {
        params
      }
    } = this.props

    var response = await CmsHelper.get({
      type: 'comments',
      filter: {
        wbt_id: params.wbtId
      },
      sort: {
        _created: -1
      }
    })

    var totalOpenComments = 0
    response.map((comments) => {
      if(!comments.checked) {
        totalOpenComments++;
      }
    })

    this.setState({
      comments: response,
      totalOpenComments: totalOpenComments
    });
  }

  handleSave() {
    WbtHelper.saveWbt(this.state.wbt)
    this.setState({
      isSaved: true
    })
  }

  handleDuplicate(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.duplicate(args, wbt)
    this.setState({
      wbt: wbt,
      isSaved: false
    }, () => {
      var label
      if(args.elementId !== undefined) {
        label = 'Element dupliziert'
      } else {
        label = 'Modul dupliziert'
      }
      this.updateActions(label)
    })
  }

  handleDelete(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.delete(args, wbt)
    var state = {
      wbt: wbt
    }
    if(args.columnElementId === undefined) {
      state.panelModuleId = ''
      state.panelElementId = ''
      state.panelView = 'elements'
    }
    this.setState(state)
    this.setState({
      isSaved: false
    }, () => {
      var label
      if(args.elementId !== undefined) {
        label = 'Element gelöscht'
      } else {
        label = 'Modul gelöscht'
      }
      this.updateActions(label)
    })
  }

  handleEdit(args) {
    if(args.elementId !== undefined) {
      this.setState({
        panelModuleId: args.moduleId,
        panelElementId: args.elementId,
        panelView: 'edit'
      })
    } else if(args.moduleId !== undefined) {
      this.setState({
        panelModuleId: args.moduleId,
        panelElementId: '',
        panelView: 'edit'
      })
    }
  }

  handleEditMotivation(args) {
    if(args.elementId !== undefined) {
    } else if(args.moduleId !== undefined) {
      this.setState({
        panelModuleId: args.moduleId,
        panelElementId: '',
        panelView: 'edit-motivation'
      })
    }
  }

  handleComment(args) {
    if(args.elementId !== undefined) {
      this.setState({
        panelModuleId: args.moduleId,
        panelElementId: args.elementId,
        panelView: 'comment'
      })
    }
  }

  handleEditEnd() {
    this.setState({
      panelView: 'elements'
    }, () => {
      this.updateActions('Element bearbeitet')
    })
  }

  handleAdd(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.add(args, wbt)
    this.setState({
      wbt: wbt,
      panelView: 'elements',
      isSaved: false
    }, () => {
      var label
      var config = ConfigHelper.getElementConfig(args.elementType)
      if(args.elementType !== undefined) {
        label = config.title + ' erstellt'
      } else {
        label = 'Modul erstellt'
      }
      this.updateActions(label)
    })
  }

  handleAddInside(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.addInside(args, wbt)
    this.setState({
      wbt: wbt,
      isSaved: false
    }, () => {
      this.updateActions('Element erstellt')
    })
  }

  handleMove(source, target) {
    var wbt = this.state.wbt
    wbt = WbtHelper.move({
      source: source,
      target: target
    }, wbt)
    this.setState({
      wbt: wbt,
      isSaved: false
    }, () => {
      var label
      if(source.elementId !== undefined) {
        label = 'Element verschoben'
      } else {
        label = 'Modul verschoben'
      }
      this.updateActions(label)
    })
  }

  handleMoveInside(source, target) {
    var wbt = this.state.wbt
    wbt = WbtHelper.moveInside({
      source: source,
      target: target
    }, wbt)
    this.setState({
      wbt: wbt,
      isSaved: false
    }, () => {
      this.updateActions('Element verschoben')
    })
  }

  handleOpenModal(args) {
    if(args.translation !== undefined && args.translation) {
      var filter = ''
      if(args.filter !== undefined) {
        filter = args.filter
      }
      this.setState({
        modalShow: true,
        modalArgs: {
          modal: 'attachment',
          filter: filter
        },
        tempArgs: args,
        isSaved: false
      })
    } else if(args.commentFile !== undefined && args.commentFile) {
      var filter = ''
      if(args.filter !== undefined) {
        filter = args.filter
      }
      this.setState({
        modalShow: true,
        modalArgs: {
          modal: 'commentFile',
          filter: filter,
          id: args.id
        },
        tempArgs: args,
        isSaved: false
      })
    } else if(args.comment !== undefined && args.comment) {
      var filter = ''
      if(args.filter !== undefined) {
        filter = args.filter
      }
      this.setState({
        modalShow: true,
        modalArgs: {
          modal: 'attachment',
          filter: filter
        },
        tempArgs: args,
        isSaved: false
      })
    } else {
      var filter = ''
      if(args.filter !== undefined) {
        filter = args.filter
      }
      this.setState({
        modalShow: true,
        modalArgs: {
          modal: 'attachment',
          filter: filter
        },
        tempArgs: args,
        isSaved: false
      })
    }
  }

  handleOpenSettings() {
    this.setState({
      modalShow: true,
      modalArgs: {
        modal: 'settings'
      }
    })
  }

  handleOpenExport() {
    this.setState({
      modalShow: true,
      modalArgs: {
        modal: 'export'
      }
    })
  }

  handleSelectFile(id) {
    var wbt = this.state.wbt
    var args = this.state.tempArgs
    args.fieldValue = id
    if(args.translation !== undefined && args.translation) {
      this.setState({
        modalShow: true,
        modalArgs: {
          modal: 'settings',
          stringKey: args.stringKey,
          stringValue: args.fieldValue,
          stringLang: args.stringLang
        },
        tempArgs: {},
        commentFiles: commentFiles,
        isSaved: false
      })
    } else if(args.comment !== undefined && args.comment) {
      var commentFiles = this.state.commentFiles
      if(!commentFiles.includes(id)) {
        commentFiles.push(id)
      }
      this.setState({
        modalShow: false,
        modalArgs: {},
        tempArgs: {},
        commentFiles: commentFiles,
        isSaved: false
      })
    } else if(args.field !== undefined && args.field === 'wbt_image') {
      wbt.settings.image = args.fieldValue
      this.setState({
        wbt: wbt,
        modalShow: true,
        modalArgs: {
          modal: 'settings'
        },
        tempArgs: {},
        isSaved: false
      })
    } else {
      if(this.state.overlayId !== '') {
        args.overlayId = this.state.overlayId;
      }
      if(args.itemId !== undefined) {
        wbt = WbtHelper.changeFieldItem(args, wbt)
      } else {
        wbt = WbtHelper.changeField(args, wbt)
      }
      this.setState({
        wbt: wbt,
        modalShow: false,
        modalArgs: {},
        tempArgs: {},
        isSaved: false
      }, () => {
        this.updateActions('Bild ausgewählt')
      })
    }
  }

  handleDeleteCommentFile(id) {
    var commentFiles = this.state.commentFiles
    var index = commentFiles.indexOf(id);
    if (index !== -1) {
      commentFiles.splice(index, 1);
    }
    this.setState({
      modalShow: false,
      modalArgs: {},
      tempArgs: {},
      commentFiles: commentFiles,
      isSaved: false
    })
  }

  handleResetCommentFiled() {
    this.setState({
      commentFiles: []
    })
  }

  handleCloseModal() {
    this.setState({
      modalShow: false,
      modalArgs: {},
      tempArgs: {},
      appModal: '',
      appModalShow: false
    })
  }

  handleChangeField(args) {
    var wbt = this.state.wbt
    if(args.fieldName === 'wbt_image') {
      wbt.settings.image = args.fieldValue
    } else {
      if(this.state.overlayId !== '') {
        args.overlayId = this.state.overlayId;
      }
      wbt = WbtHelper.changeField(args, wbt)
    }
    this.setState({
      wbt: wbt,
      isSaved: false
    })
  }

  handleChangeOrder(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.changeOrder(args, wbt)
    this.setState({
      wbt: wbt
    })
    this.setState({
      isSaved: false
    })
  }

  handleChangeModuleHeadline(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.changeModuleHeadline(args, wbt)
    this.setState({
      wbt: wbt,
      isSaved: false
    })
  }

  handleDragOverPreview(state) {
    this.setState({
      isDragOverPreview: state
    })
  }

  handleChangeSettings(args) {
    var wbt = this.state.wbt
    wbt = WbtHelper.changeSettings(args, wbt)
    this.setState({
      wbt: wbt,
      modalShow: false,
      modalArgs: {}
    }, () => {
      this.handleSave()
    })
  }

  updateActions(action) {
    var actions = this.state.actions
    var selectedActionIndex = -1
    actions.forEach(function(action, i) {
      if(action.selected) {
        actions[i].selected = false
        selectedActionIndex = i
      }
    })
    selectedActionIndex--
    if(selectedActionIndex >= 0) {
      for(var i = selectedActionIndex; i >= 0; i--) {
        actions.splice(i, 1);
      }
    }
    if(action === '') {
      actions.push({
        label: '',
        selected: false,
        savedWbt: this.state.wbt
      });
    } else {
      actions[0].label = action
      actions.unshift({
        label: '',
        selected: false,
        savedWbt: this.state.wbt
      });
    }
    if(actions.length > 21) {
      actions = actions.slice(0, 21)
    }
    this.setState({
      actions: actions
    })
  }

  handleSelectAction(index) {
    var actions = this.state.actions
    var actionSelectedIndex = -1
    actions.forEach((action, i) => {
      if(i === index + 1) {
        actions[i].selected = !actions[i].selected
        if(actions[i].selected) {
          actionSelectedIndex = i
        }
      } else {
        actions[i].selected = false
      }
    })
    if(actionSelectedIndex !== -1) {
      this.setState({
        wbt: actions[actionSelectedIndex].savedWbt,
        actions: actions
      })
    } else {
      this.setState({
        wbt: actions[0].savedWbt,
        actions: actions
      })
    }
  }

  handleSelectComment(id) {
    this.goToComment(id)
  }

  handleSelectResponsiveMode(mode) {
    this.setState({
      responsiveMode: mode
    })
  }

  handleSelectRevision(revision) {
    var wbt = this.state.wbt
    wbt.modules = revision.content.modules
    this.setState({
      wbt: wbt,
      isSaved: false,
      modalShow: false,
      modalArgs: {}
    })
  }

  handleTogglePreviewMode() {
    this.setState({
      panelView: 'elements',
      previewMode: !this.state.previewMode
    })
  }

  handleAddTemplate() {
    this.setState({
      modalShow: true,
      modalArgs: {
        modal: 'add_template',
        wbt: this.state.wbt,
        wbtId: this.state.wbt.id
      }
    })
  }

  handleSaveAsTemplate(args) {
    this.setState({
      modalShow: true,
      modalArgs: {
        modal: 'add_module_template',
        wbt: this.state.wbt,
        moduleId: args.moduleId
      }
    })
  }

  handleOpenHelpVideo(video) {
    this.setState({
      appModal: 'help_video',
      appModalShow: true,
      appModalData: {
        video: video
      }
    })
  }

  handleCloseOverlay() {
    this.setState({
      overlayId: ''
    })
  }

  handleSaveLanguageSettings(args) {
    var wbt = this.state.wbt
    if(args.defaultLang !== undefined) {
      wbt.settings.defaultLang = args.defaultLang
    }
    if(args.translations !== undefined) {
      wbt.settings.translations = args.translations
    }
    if(args.translatedStrings !== undefined) {
      wbt.settings.translatedStrings = args.translatedStrings
    }
    this.setState({
      wbt: wbt
    }, () => {
      this.handleSave()
    })
  }

  render() {
    const {
      match: {
        params
      }
    } = this.props
    return this.props.connectDropTarget(
      Object.keys(this.state.wbt).length !== 0 ?
        <div id="editor" className={(this.state.overlayId !== '' ? 'overlay-mode' : '')}>
          <EditorHeader
            type={this.state.wbt.type}
            actions={this.state.actions}
            settings={this.state.wbt.settings}
            isSaved={this.state.isSaved}
            previewMode={this.state.previewMode}
            onSave={this.handleSave.bind(this)}
            onOpenSettings={this.handleOpenSettings.bind(this)}
            onOpenExport={this.handleOpenExport.bind(this)}
            onSelectAction={this.handleSelectAction.bind(this)}
            onSelectComment={this.handleSelectComment.bind(this)}
            onTogglePreviewMode={this.handleTogglePreviewMode.bind(this)}
            onAddTemplate={this.handleAddTemplate.bind(this)}
            onSelectResponsiveMode={this.handleSelectResponsiveMode.bind(this)}
            comments={this.state.comments}
            totalOpenComments={this.state.totalOpenComments}
          />
          <EditorPanel
            wbt={this.state.wbt}
            view={this.state.panelView}
            moduleId={this.state.panelModuleId}
            elementId={this.state.panelElementId}
            previewMode={this.state.previewMode}
            onEditEnd={this.handleEditEnd.bind(this)}
            onOpenModal={this.handleOpenModal.bind(this)}
            onChangeField={this.handleChangeField.bind(this)}
            onChangeOrder={this.handleChangeOrder.bind(this)}
            onOpenHelpVideo={this.handleOpenHelpVideo.bind(this)}
            overlayId={this.state.overlayId}
            commentFiles={this.state.commentFiles}
            onDeleteCommentFile={this.handleDeleteCommentFile.bind(this)}
            onResetCommentFiled={this.handleResetCommentFiled.bind(this)}
            onUpdateComments={this.handleUpdateComments.bind(this)}
          />
          <EditorPreview
            type={this.state.wbt.type}
            responsiveMode={this.state.responsiveMode}
            wbt={this.state.wbt}
            previewMode={this.state.previewMode}
            panelModuleId={this.state.panelModuleId}
            panelElementId={this.state.panelElementId}
            panelView={this.state.panelView}
            onDuplicate={this.handleDuplicate.bind(this)}
            onDelete={this.handleDelete.bind(this)}
            onEdit={this.handleEdit.bind(this)}
            onEditMotivation={this.handleEditMotivation.bind(this)}
            onEditEnd={this.handleEditEnd.bind(this)}
            onAdd={this.handleAdd.bind(this)}
            onAddInside={this.handleAddInside.bind(this)}
            onMove={this.handleMove.bind(this)}
            onMoveInside={this.handleMoveInside.bind(this)}
            onChangeField={this.handleChangeField.bind(this)}
            onChangeModuleHeadline={this.handleChangeModuleHeadline.bind(this)}
            onDragOverPreview={this.handleDragOverPreview.bind(this)}
            onSaveAsTemplate={this.handleSaveAsTemplate.bind(this)}
            overlayId={this.state.overlayId}
            onOpenHelpVideo={this.handleOpenHelpVideo.bind(this)}
            onCloseOverlay={this.handleCloseOverlay.bind(this)}
            onComment={this.handleComment.bind(this)}
            comments={this.state.comments}
          />
          <EditorModal
            wbt={this.state.wbt}
            wbtId={params.wbtId}
            actions={this.state.actions}
            settings={this.state.wbt.settings}
            onChangeSettings={this.handleChangeSettings.bind(this)}
            modalShow={this.state.modalShow}
            modalArgs={this.state.modalArgs}
            onOpenModal={this.handleOpenModal.bind(this)}
            onSelectFile={this.handleSelectFile.bind(this)}
            onCloseModal={this.handleCloseModal.bind(this)}
            onSelectAction={this.handleSelectAction.bind(this)}
            onChangeField={this.handleChangeField.bind(this)}
            onSelectRevision={this.handleSelectRevision.bind(this)}
            onSaveLanguageSettings={this.handleSaveLanguageSettings.bind(this)}
          />
          <AppModal
            modal={this.state.appModal}
            modalShow={this.state.appModalShow}
            modalData={this.state.appModalData}
            onCloseModal={this.handleCloseModal.bind(this)}
          />
          <Preview>
            <Context.Consumer>
              {
                ({itemType, item, style}) => {
                  if(this.state.isDragOverPreview || (item.moduleId !== undefined && !this.props.isOver)) {
                    style.top = '63px'
                    style.left = '316px'
                  }
                  if(item.moduleId !== undefined) {
                    style.margin = '-43px 0 0 -43px'
                  }
                  return(
                    <div className={'drag-preview is-dragging type-' + item.itemType} style={style}>
                      <div className="drag-preview-container">
                        {
                          item.icon !== undefined ?
                            <div className="icon">{item.icon}</div>
                          :
                            null
                        }
                        <div className="label">{item.label}</div>
                        <div className="background"></div>
                      </div>
                    </div>
                  )
                }
              }
            </Context.Consumer>
          </Preview>
        </div>
      :
        null
    )
  }
}


export default DropTarget(
  ['column-element', 'element', 'module'],
  {},
  editorCollect
)(Editor)
