import React from 'react'
import { Form } from 'semantic-ui-react';
import {withRouter} from 'react-router'
import axios from 'axios';

import InputImage from './WindowSettingsInputImage'
import WindowSettingsTabLanguagesTabText from './WindowSettingsTabLanguagesTabText'
import WindowSettingsTabLanguagesTabAudio from './WindowSettingsTabLanguagesTabAudio'

import CommonHelper from '../../../helpers/Common'
import CmsHelper from '../../../helpers/Cms'

class WindowSettingsTabLanguages extends React.Component {
  constructor(props) {
    super(props)

    var tab = 'text';
    if(this.props.modalArgs.stringLang !== undefined) {
      tab = 'audio'
    }

    this.state = {
      languages: [],
      defaultLanguageOptions: [
        { key: 'de', value: 'de', text: 'Deutsch' },
        { key: 'en', value: 'en', text: 'Englisch' },
        { key: 'es', value: 'es', text: 'Spanisch' },
        { key: 'fr', value: 'fr', text: 'Französisch', },
        { key: 'nl', value: 'nl', text: 'Niederländisch' }
      ],
      defaultLanguage: '',
      addLanguage: false,
      selectNewLang: '',
      translatedStrings: [],
      activeTab: tab
    }
  }

  componentDidMount() {
    this.loadSettings();
  }

  UNSAFE_componentWillReceiveProps(props) {
    if(props.modalArgs.stringLang !== undefined) {
      this.setState({
        activeTab: 'audio'
      })
    }
  }

  handleChangeDefaultLanguage(e, {value}) {
    this.setState({
      defaultLanguage: value
    })
  }

  async loadSettings() {
    var languages = this.props.wbt.settings.translations;
    var defaultLanguage = this.props.wbt.settings.defaultLang;
    this.setState({
      languages: languages,
      defaultLanguage: defaultLanguage
    })
  }

  handleSave() {
    this.props.onSaveLanguageSettings({
      translations: this.state.languages,
      defaultLang: this.state.defaultLanguage,
      translatedStrings: this.state.translatedStrings
    })
  }

  addLanguage() {
    this.setState({
      addLanguage: !this.state.addLanguage
    });
  }

  saveNewLanguage() {
    if(this.state.selectNewLang != '') {
      var languages = this.state.languages;
      languages.push(this.state.selectNewLang);
      this.setState({
        addLanguage: !this.state.addLanguage,
        languages: languages
      })
    }
  }

  handleSelectNewLanguage(e, {value}) {
    this.setState({
      selectNewLang: value
    })
  }

  handleChangeTranslation(translations) {
    this.setState({
      translatedStrings: translations
    })
  }

  handleChangeActiveTab(value) {
    this.setState({
      activeTab: value
    })
  }

  render() {
    var newLanguageOptions = []
    this.state.defaultLanguageOptions.map((lang) => {
      if(lang.key != this.state.defaultLanguage && !this.state.languages.includes(lang.key)) {
        newLanguageOptions.push(lang)
      }
    })
    return(
      <>
        <div className="headline">Sprache</div>
        <div className="languages-settings">
          <div className="section default-language">
            <div className="label">Ausgangssprache</div>
            <Form.Select
              options={this.state.defaultLanguageOptions}
              value={this.state.defaultLanguage}
              placeholder="Sprache"
              onChange={this.handleChangeDefaultLanguage.bind(this)}
            />
          </div>
          <div className="section select-languages">
            <div className="label">Sprachen</div>
            <div className="flags">
              <FlagIcon lang={this.state.defaultLanguage} />
              {
                this.state.languages.map((lang) => {
                  if(lang != this.state.defaultLanguage) {
                    return (
                      <FlagIcon key={lang} lang={lang} />
                    )
                  } else {
                    return ''
                  }
                })
              }
            </div>
            <div className="add-language-wrapper">
              {
                newLanguageOptions.length > 0 ?
                  (
                    !this.state.addLanguage ?
                      <a className="add-language" onClick={this.addLanguage.bind(this)}>
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <g id="Gruppe_903" data-name="Gruppe 903" transform="translate(2358 -2961)">
                              <circle id="Ellipse_1" data-name="Ellipse 1" cx="10" cy="10" r="10" transform="translate(-2358 2961)" fill="#fc45f0"/>
                              <path id="Pfad_851" data-name="Pfad 851" d="M2780.614,1198.017h9.232" transform="translate(-5133.23 1772.983)" fill="none" stroke="#fff" strokeWidth="1"/>
                              <path id="Pfad_852" data-name="Pfad 852" d="M2780.614,1198.017h9.232" transform="translate(-1149.982 185.77) rotate(90)" fill="none" stroke="#fff" strokeWidth="1"/>
                            </g>
                          </svg>
                        </div>
                        <div className="label">Sprache hinzufügen</div>
                      </a>
                    :
                      <div>
                        <Form.Select
                          options={newLanguageOptions}
                          value={this.state.selectNewLang}
                          placeholder="Sprache auswählen"
                          onChange={this.handleSelectNewLanguage.bind(this)}
                        />
                        <a className="add-language" onClick={this.saveNewLanguage.bind(this)}>
                          <div className="label">hinzufügen</div>
                        </a>
                      </div>
                  )
                :
                  ''
              }
            </div>
            <a className="button highlighted round" onClick={this.handleSave.bind(this)}><div className="label">Speichern</div></a>
          </div>
          <div className="section types">
            <div className="tab-buttons">
              <div className={'tab-button' + (this.state.activeTab == 'text' ? ' active' : '')} onClick={this.handleChangeActiveTab.bind(this, 'text')}>Text</div>
              <div className={'tab-button' + (this.state.activeTab == 'audio' ? ' active' : '')}  onClick={this.handleChangeActiveTab.bind(this, 'audio')}>Audio</div>
            </div>
            <div className="tabs">
              <div className={'tab' + (this.state.activeTab == 'text' ? ' active' : '')}>
                <WindowSettingsTabLanguagesTabText
                  {...this.props}
                  defaultLanguage={this.state.defaultLanguage}
                  defaultLanguageOptions={this.state.defaultLanguageOptions}
                  selectedLanguageOptions={this.state.languages}
                  onChangeTranslation={this.handleChangeTranslation.bind(this)}
                />
              </div>
              <div className={'tab' + (this.state.activeTab == 'audio' ? ' active' : '')}>
                <WindowSettingsTabLanguagesTabAudio
                  {...this.props}
                  defaultLanguage={this.state.defaultLanguage}
                  defaultLanguageOptions={this.state.defaultLanguageOptions}
                  selectedLanguageOptions={this.state.languages}
                  onChangeTranslation={this.handleChangeTranslation.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

class FlagIcon extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    var lang = this.props.lang;
    if(lang == 'de') {
      return(
        <div className="flag">
          <svg version="1.1" id="Layer_1" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96"><image id="image0" width="96" height="96" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgBAMAAAAQtmoLAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAD1BMVEUAAAAAAADfAAD/zwD/
          //8Lj5uxAAAAAXRSTlMAQObYZgAAAAFiS0dEBI9o2VEAAAAHdElNRQfkDAMJECoI7FHTAAAAM0lE
          QVRYw+3LIREAMAwEsFqYhVmYf29j5Q96V5DwVAHthARB2B1uSBAEYTq8kCAIuwPQPmmUmBBajMXF
          AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTAzVDA5OjE2OjQyKzAwOjAwbFdVCwAAACV0RVh0
          ZGF0ZTptb2RpZnkAMjAyMC0xMi0wM1QwOToxNjo0MiswMDowMB0K7bcAAAAASUVORK5CYII=" />
          </svg>
        </div>
      )
    } else if(lang == 'en') {
      return(
        <div className="flag">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAMAAADVRocKAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAARVBMVEUAAADPFCvSITfsoqv/
          /v7////9/f6PnsUKKoAAIXvtp7DSIzjcVGX54+ba3+w9Vpr65undWWnRHzXrnabbUGH54eTtpq/C
          ivxpAAAAAXRSTlMAQObYZgAAAAFiS0dEBfhv6ccAAAAHdElNRQfkDAMJERTQln05AAAB4ElEQVRo
          3u2ZC0/CMBRGvwEFqrwE9f//VLsxydbex7dEoyT3JibENZ5zJKYtAjExMTExMWW61XqThtnu9lmf
          fkU3Tv/aWLrfbcuCl9dhLcrX4XgnpNP5JwDnU3l8eRtWXm89gIpgATP994+E1fDKjyABc/3ijeOh
          oyIoQK1fBmmzpiIYQKM/AFKiInyAoN8D+u8yES5A0i+uuD/wIxyArF9EMT5zI2yAol8s8XjsRFgA
          VT+PACbCAOj6DwARoQIs/QnAjdAApv4U4EXIAEd/DrAjRICnXwHMCAHg6zcAI6IFEPotQI+oAZS+
          BNAiagClLwKUiBpA6SsAJaICMPoaQIuYAih9HSBHTAGUfg9IC6Z6D6gJgA/ofnkCEIBnADz/X/Lz
          A6wNp5/vTacFTDc8Y8MxtszJT7nehILZhrdoy2z0x523+RUREeqxZaa/0d4DP0I5eDX64sGLiQCl
          rx4d/QhQ+vrh140ApZ+N47sTAUo/WxcQOwKUfravUFYEKP3sXAKNCFD62b3GqhGg9LN/EdciQOln
          5qMEOQKUfqY+DBEjQOm7AD0ClL4PUCNA6TMAJQKUPgWQI0DpkwApApQ+CxAiQOnzgCYCn4z+AkAd
          AUp/EWAe8df/voiJiYmJ+R/zBRSniYn8jX4mAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIwLTEyLTAz
          VDA5OjE3OjIwKzAwOjAw0mUmmwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0xMi0wM1QwOToxNzoy
          MCswMDowMKM4nicAAAAASUVORK5CYII=" />
          </svg>
        </div>
      )
    } else if(lang == 'es') {
      return(
        <div className="flag">
          <svg version="1.1" id="Layer_1" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAG
          /UlEQVR42u3ca2xbZxnA8f/xOceOnfiapFna5p40bbKsV4KgaxEtBVbE6MSmCgk6EBOaBBOIMSqY
          RBkfEGWomoChSZMmpklMZVzGNIpKm60iKyS9rA1rlyZp07hJ01zb2PH9cg4fPA2qARJK3Jelz+9T
          5Njx8fP3OT6vbQWEEEIIIYQQQgghhBBCCLHUaT16ha16I25nDtUbcLuTAIpJAMUkgGISQDEJoJgE
          UEwCKCYBFJMAikkAxSSAYhJAMQmgmARQTLOPI58HKCR7gGISQDEJoNgtDXDgtxpPv6Kpfsz/V4xb
          cSdvhYOcHfbxhzc1dNumuRaWB6N01N1Q/fiVK8pZ0FzMwQvHGrkylSWaDGAwzdj4DM6yGhIpiwrX
          KL5QNbGUj6pAklCZzbfvv6x6FkoU5RD05acaGZsY4551YfovRWnzTnBPpYbXrVFXZbPZ56DWeZWh
          kTg77hqmf3iSLx5oUT0LJYpyCIolspSPaBz7sx//Sovh1918+LqX620TGBaUXwjRE5yjIgR/edLP
          8sYUxzMZ1bNQoigBDA0mZjQ8FsRmrvJaFCYrXAyeT+LQ4KWgydVEHiszSj5Txvg0eJbdnuvBRXsN
          6O6DLWsLP8/F4PPfNHD7y5iZiBEKwsS8Ds7CszydcFIXyhOJOCivLiWbi/C7Jy3Vs1BiUQJ89hs6
          wxGLFV4Hr/40DxQi7Pm+hmEEGEjl8egG5vUoDl0nG/SRyCapzBlUlUd48Qn73dvc95jOZMyiJqBx
          +GdLP8qiHIJ6whbppEEiVRj+pRGNoWGNT22An/8mSjQCtqaRszTwe6nPGJiGh7jLYvcnQxw5A03V
          SRwk6B+3yKYd5HJLf/iwSAE6Wm2crizZd15HT3U5MV4JUgFs88SoDBmsyZQAMP3d77D6rg68Ph9f
          /cpnONi7invvfYjY5HPct7aLdW02TmeeTFr1aG6NRQnQ2tTC9cQyyE0BQ+9e/kLpDY5OpikzNfYG
          NWoTLqLT0/zoa48yloyyKdBKd28vzliIXZ8u3Kaxvo35VBAzfxUYUT2foluUdcDWztVs2b6Hhrqm
          my4fc2eor4PSoE23GQfALCtjwwc3k46m8Js+1tbfzQMPP/jPv/WhTrZs30NdTbXq2dwSi7IHxBMZ
          hgZPM3flGocO69jvvKzntJVEcwHMwDLWpHsBcLvd/O2NLvyhAHErw+rmFqaGrhBww6HDOm+Hh0m6
          nBh6TvVsbokF7wFP7y+BN3rYNnGQj+mXSfWU0X3ULPzSyuJ2xsmn59BzGuezGS53vcajP/4hLs1B
          faCKUNZF48Z2TnYb5Po9tE2dYUf2VzQPDvLs0y7V8ym6BQcY6jM4M+7hWNX9vJyuxd40z/KGwtlQ
          38AE8bIv4CmxGJo3eblpK4fHdU499QvWt7Vz0Zjl4PE/4fV6qarNk1sVpzfYQZexm755g7FhXfV8
          im7BATq2p3GGAjRt6sQYi3D+hMm0ZfHHZJwvlXr5+PkDtA4OEguuZnrqELHIIU7Mpoibefw1Vdyx
          opwXn3+eaDLKuV6T1IVpmjZ1knf5qW3Lqp5P0S3KQmzvT9rJDUDj7CxrHprl16dLaZjPMuBL0u4x
          OTcIifRWzvpGQYP18Sa2bTzKqhYgD/mozl/PLWdzZ5iB5yq4GAwxG0rwy/1L/x3SBe8B+x7xsKx3
          Bsvv5/xH6ti2873PWq9Lo4Ie7kh5KU+U0lLeTWurjVGVx6zJ4VxVWEBs25kl8rl1WH4/bUNx9j3i
          UT2folvwWdB1y8L1QDtr2nYzduH3//F6zRsyOCf78KPx0Z0W+so8WYeBPm7zr/tgfUsVodZdnD7y
          DM6L/arnU3QLDvD1b6U5MVtK7KZL//1RrX6VRbXLwKzMM5dzM2RX8IHKUazEez+mXFbt5sFdS385
          vOAAzQ02L/VdYyrRRfzG5H+97ptDNjtWOLB18EbSdFZewUo4sLMamlk4c3r7wiXmcwnysQjNDUv/
          LepFWYhNTJ2ibsUpRguLXdavydFzyEbz3Xy96Vk/g6kWGnInSfc7gQya02bmVR+1dxY+H56PdFOz
          HMKx/20b3q8WfBb0iSc6GJ2Mks1kMHUNS/Ny4ZkBvrd/JafGr7G9ziAc1pgvc+Lyr8dOJOkgSnvn
          CHp5npk+D/2RAI8/Fmb1w6047HmyeRvT6aSmysfhfW+pnlFRLXgP8Hvh3Egp2YwT3WFTFSic0fxg
          7xgHj7g4frYCR0sN7hUdvH7y77hKSog0NDN5rRZXeJS7O0d4fGMYgBIzw+Sch7ylYeZN7vQtZMve
          H+S7oYrJN+MUkwCKSQDF5P8FKSZ7gGISQDEJoJgEUEwCKCYBFJMAikkAxSSAYhJAMQmgmARQTAIo
          JgEUkwBCCCGEEEIIIYQQQgghhBBF9g+0cXILbyWuWgAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0x
          Mi0wM1QwOToxNzozMyswMDowMC8nPJgAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMTItMDNUMDk6
          MTc6MzMrMDA6MDBeeoQkAAAAAElFTkSuQmCC" />
          </svg>
        </div>
      )
    } else if(lang == 'fr') {
      return(
        <div className="flag">
          <svg version="1.1" id="Layer_1"  x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAgMAAACf9p+rAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAAAAI5X////tKTm1
          PDUZAAAAAXRSTlMAQObYZgAAAAFiS0dEAmYLfGQAAAAHdElNRQfkDAMJEQW6Jl3LAAAAJklEQVRI
          x+3JoQEAMAgDMJ7kyT0JpnoakdhUcUrHiwkhhBDiF5yywO38Ls85vMcAAAAldEVYdGRhdGU6Y3Jl
          YXRlADIwMjAtMTItMDNUMDk6MTc6MDUrMDA6MDDCeA5BAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIw
          LTEyLTAzVDA5OjE3OjA1KzAwOjAwsyW2/QAAAABJRU5ErkJggg==" />
          </svg>
        </div>
      )
    } else if(lang == 'nl') {
      return(
        <div className="flag">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="96px" height="96px" viewBox="0 0 96 96" enableBackground="new 0 0 96 96">  <image id="image0" width="96" height="96" x="0" y="0"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgAgMAAACf9p+rAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
          AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAADFBMVEUAAACuHCj///8hRovb
          qsk6AAAAAXRSTlMAQObYZgAAAAFiS0dEAmYLfGQAAAAHdElNRQfkDAMJES4WmqSLAAAAKUlEQVRI
          x2NgGAWDCoTiAKMSw1diFQ4wKjEqQYnEfxxgVGL4SoyCQQUAaO/8LnxXNXYAAAAldEVYdGRhdGU6
          Y3JlYXRlADIwMjAtMTItMDNUMDk6MTc6NDUrMDA6MDBGMgC7AAAAJXRFWHRkYXRlOm1vZGlmeQAy
          MDIwLTEyLTAzVDA5OjE3OjQ1KzAwOjAwN2+4BwAAAABJRU5ErkJggg==" />
          </svg>
        </div>
      )
    } else {
      return ''
    }
  }
}

export default WindowSettingsTabLanguages
