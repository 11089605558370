import React from 'react'

import EditorOverlayDragButtonSourceModule from './EditorOverlayDragButtonSourceModule'
import EditorOverlayDragButtonSourceElement from './EditorOverlayDragButtonSourceElement'
import EditorOverlayDragButtonSourceColumnElement from './EditorOverlayDragButtonSourceColumnElement'
import Tooltip from '../../../../tooltip/Tooltip'

import ConfigHelper from '../../../../helpers/Config'

class EditorOverlay extends React.Component {
  render() {
    var show = this.props.show
    var buttonDrag
    if(this.props.elementType !== undefined) {
      var elementConfig = ConfigHelper.getElementConfig(this.props.elementType)
      if(elementConfig.type === this.props.elementType) {
        if(elementConfig.itemType === 'column-element') {
          buttonDrag = (
            <EditorOverlayDragButtonSourceColumnElement
              elementType={this.props.elementType}
              elementIcon={elementConfig.icon}
              elementLabel={elementConfig.title}
              moduleId={this.props.moduleId}
              elementId={this.props.elementId}
              columnId={this.props.columnId}
              columnElementId={this.props.columnElementId}
              onDragStart={this.props.onDragStart}
              onDragEnd={this.props.onDragEnd}
            />
          )
        } else if(elementConfig.itemType === 'element') {
          buttonDrag = (
            <EditorOverlayDragButtonSourceElement
              elementType={this.props.elementType}
              elementIcon={elementConfig.icon}
              elementLabel={elementConfig.title}
              moduleId={this.props.moduleId}
              elementId={this.props.elementId}
              columnId={this.props.columnId}
              columnElementId={this.props.columnElementId}
              onDragStart={this.props.onDragStart}
              onDragEnd={this.props.onDragEnd}
              test="teattaetaet"
            />
          )
        }
      }
    } else {
      buttonDrag = (
        <EditorOverlayDragButtonSourceModule
          moduleId={this.props.moduleId}
          onDragStart={this.props.onDragStart}
          onDragEnd={this.props.onDragEnd}
        />
      )
    }
    var comments = 0
    if(this.props.comments !== undefined) {
      this.props.comments.map((comment) => {
        if(this.props.elementId == comment.element_id && !comment.checked) {
          comments++;
        }
      })
    }
    return(
      <div
        className={'editor-overlay editor-overlay-' + this.props.type + (show ? ' show' : '')}
      >
        <div className="drag-button-bar">
          {buttonDrag}
        </div>
        {
          this.props.tooltipMode === 2 ?
            <Tooltip
              tooltip="edit_element"
            />
          :
            ''
        }
        <div className="editor-overlay-bar-button-group">
          <button className="editor-overlay-bar-button button-edit highlighted" onClick={this.props.onEdit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.001" viewBox="0 0 12 12.001">
              <path d="M10.936,2.453a1.15,1.15,0,0,0-.819.34L2.606,10.3a.46.46,0,0,0-.129.25L1.9,13.912a.465.465,0,0,0,.535.536L5.8,13.875a.469.469,0,0,0,.25-.129l7.511-7.511a1.161,1.161,0,0,0,0-1.639l-1.8-1.8a1.155,1.155,0,0,0-.82-.339ZM5.856,12.625l-2.13-2.13L9.3,4.923l2.13,2.13ZM3.28,11.359l1.712,1.712-2.064.352ZM12.9,5.579l-.819.819-2.13-2.13.819-.82a.231.231,0,0,1,.327,0l1.8,1.8a.232.232,0,0,1,0,.328Z" transform="translate(-1.897 -2.453)" />
            </svg>
          </button>
          <button className="editor-overlay-bar-button button-duplicate" onClick={this.props.onDuplicate}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
              <path d="M14.152,5H8.75A.749.749,0,0,0,8,5.751V8H5.75A.749.749,0,0,0,5,8.751v7.5A.751.751,0,0,0,5.75,17h7.5A.751.751,0,0,0,14,16.251V14H16.25A.751.751,0,0,0,17,13.251V7.84ZM14,5.909,16.1,8H14ZM13.25,16.251H5.75v-7.5h4.5V11a.751.751,0,0,0,.749.751H13.25ZM11,11V8.909L13.1,11Zm5.251,2.251H14V10.84L11.152,8h-2.4V5.751h4.5V8A.751.751,0,0,0,14,8.751H16.25Z" transform="translate(-5 -5)" />
            </svg>
          </button>
          <button className="editor-overlay-bar-button button-delete" onClick={this.props.onDelete}>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13.286" viewBox="0 0 12 13.286">
              <g transform="translate(-4.859 0)">
                <path d="M30.324,45.854a.461.461,0,0,0,.461-.454V41.7a.461.461,0,0,0-.922,0v3.7A.461.461,0,0,0,30.324,45.854Z" transform="translate(-21.68 -35.757)" />
                <path d="M47,45.854a.46.46,0,0,0,.461-.454V41.7a.461.461,0,0,0-.921,0v3.7A.461.461,0,0,0,47,45.854Z" transform="translate(-36.136 -35.757)" />
                <path d="M63.664,45.854a.461.461,0,0,0,.461-.454V41.7a.461.461,0,0,0-.922,0v3.7A.461.461,0,0,0,63.664,45.854Z" transform="translate(-50.589 -35.757)" />
                <path d="M16.4,2.23H13.45L13.071.96A1.354,1.354,0,0,0,11.785,0H9.936A1.354,1.354,0,0,0,8.65.96L8.271,2.23H5.32a.461.461,0,1,0,0,.922h.548v8.869a1.273,1.273,0,0,0,1.271,1.264h7.455a1.274,1.274,0,0,0,1.271-1.271V3.137H16.4a.461.461,0,0,0,.461-.461A.449.449,0,0,0,16.4,2.23ZM9.541,1.223h0a.428.428,0,0,1,.394-.3H11.8a.411.411,0,0,1,.4.3l.3,1.005H9.222Zm5.4,10.892h-.032a.357.357,0,0,1-.33.24H7.139A.357.357,0,0,1,6.788,12V3.137h8.155Z" />
              </g>
            </svg>
          </button>
          {
            this.props.type === 'module' ?
              <button className="editor-overlay-bar-button button-template" onClick={this.props.onSaveAsTemplate}>
                <svg style={{'width': '13px'}} xmlns="http://www.w3.org/2000/svg" width="19.031" height="19.031" viewBox="0 0 19.031 19.031">
                  <g>
                    <path d="M17.5.12h-16A1.43,1.43,0,0,0,.12,1.53v16a1.42,1.42,0,0,0,1.41,1.41h16a1.41,1.41,0,0,0,1.41-1.41v-16A1.42,1.42,0,0,0,17.5.12ZM18,1.53v6.1H8.58V1.06H17.5a.47.47,0,0,1,.47.47Zm-16.9,16v-16a.47.47,0,0,1,.47-.47h6.1V18H1.54a.47.47,0,0,1-.48-.46h0ZM17.5,18H8.58V8.58H18V17.5a.47.47,0,0,1-.47.47Z" />
                    <path d="M17.5,19h-16A1.54,1.54,0,0,1,0,17.5v-16A1.54,1.54,0,0,1,1.53,0h16A1.54,1.54,0,0,1,19,1.53v16A1.54,1.54,0,0,1,17.5,19ZM1.53.25A1.29,1.29,0,0,0,.25,1.53v16a1.28,1.28,0,0,0,1.28,1.28h16a1.28,1.28,0,0,0,1.28-1.28v-16A1.28,1.28,0,0,0,17.5.25Zm16,17.84h-9V8.45h9.64V17.5a.59.59,0,0,1-.59.59Zm-8.8-.25h8.8a.34.34,0,0,0,.34-.34V8.7H8.7Zm-7.17.25a.59.59,0,0,1-.59-.59v-.12h0V1.53a.6.6,0,0,1,.6-.59H7.76V18.09H1.53Zm-.34-.54a.35.35,0,0,0,.34.29h6V1.19h-6a.35.35,0,0,0-.35.34Zm16.9-9.79H8.45V.94H17.5a.6.6,0,0,1,.58.47h0V7.76ZM8.7,7.51h9.14v-6a.34.34,0,0,0-.34-.34H8.7Z" />
                  </g>
                </svg>
              </button>
            :
              ''
          }
          {
            this.props.type === 'module' ?
              <button className="editor-overlay-bar-button button-motivation highlighted" onClick={this.props.onEditMotivation}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.15" height="18.784" viewBox="0 0 14.15 18.784">
                  <path id="np_flame_814614_000000" d="M29.1,14.006a.514.514,0,0,0-.418.394,17.643,17.643,0,0,1-3.371,6.892C24.1,22.934,23,24.375,23,26.6c0,3.106,2.331,5.935,5.148,5.935a.515.515,0,0,0,.386-.861,2.506,2.506,0,0,1-.7-1.616,3.7,3.7,0,0,1,.41-1.688c.466-1,1.227-2.032,1.834-3.048a5.58,5.58,0,0,1,1.786,2.8,3.76,3.76,0,0,1-.756,3.555h0a.515.515,0,0,0,.386.861,4.985,4.985,0,0,0,3.95-2.227A7.355,7.355,0,0,0,36.9,26.1c0-4.246-1.745-7.781-7.416-12a.514.514,0,0,0-.386-.1Zm.346,1.376c5.036,3.887,6.427,6.89,6.427,10.719a6.473,6.473,0,0,1-1.255,3.595,4.424,4.424,0,0,1-2.035,1.592,5.026,5.026,0,0,0,.282-3.418,6.5,6.5,0,0,0-2.622-3.748h0a.515.515,0,0,0-.748.193c-.532,1.023-1.559,2.26-2.188,3.619a4.688,4.688,0,0,0-.5,2.163,3.347,3.347,0,0,0,.29,1.191A5.086,5.086,0,0,1,24.03,26.6c0-1.889.9-3.052,2.108-4.7a19.363,19.363,0,0,0,3.306-6.514Z" transform="translate(-22.875 -13.874)" fill="#fff" stroke="#fff" stroke-width="0.25" />
                </svg>
              </button>
            :
              ''
          }
          {
            this.props.type === 'element' ?
              <button className="editor-overlay-bar-button button-comments" onClick={this.props.onComment}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.249" height="18" viewBox="0 0 20.249 18">
                  <g id="Gruppe_2038" data-name="Gruppe 2038" transform="translate(-144.563 -395.655)">
                    <path id="Pfad_1123" data-name="Pfad 1123" d="M182.509,397.553c0-.007,0-.014,0-.021a1.888,1.888,0,0,0-.658-1.417,1.952,1.952,0,0,0-1.235-.46c-.143,0-.285,0-.428,0h-11.5a1.944,1.944,0,0,0-1.235.46,1.915,1.915,0,0,0-.659,1.508v13.8c0,.112,0,.223,0,.335a1.893,1.893,0,0,0,1.892,1.893c.455,0,.911,0,1.369,0h9.631a3.469,3.469,0,0,0,1.943-.291,1.93,1.93,0,0,0,.874-1.437,2.529,2.529,0,0,0,.009-.266V397.639l-.007.12C182.506,397.63,182.509,397.553,182.509,397.553Z" transform="translate(-17.696 0)" fill="#8812c4" />
                    <path id="Pfad_1124" data-name="Pfad 1124" d="M202.161,427.514c0-2.445.2-2.037.2-2.037l7.115-7.172v.266l1.019-.421-.01-.01a.6.6,0,0,0-.844,0" transform="translate(-45.862 -17.766)" fill="#8812c4" />
                    <path id="Pfad_1125" data-name="Pfad 1125" d="M216.414,421.437l-1.059,1.059c-.36.36-.718.718-1.076,1.078l-1.686,1.686-1.259,1.259" transform="translate(-53.167 -20.528)" fill="#8812c4" />
                    <path id="Pfad_1126" data-name="Pfad 1126" d="M181.063,413.345H188.9c.362,0,.726.008,1.089,0H190a.6.6,0,0,0,0-1.194h-7.834c-.362,0-.726-.008-1.089,0h-.014a.6.6,0,0,0,0,1.194Z" transform="translate(-28.587 -13.132)" fill="#fff" style={{'fill': '#fff'}} />
                    <path id="Pfad_1127" data-name="Pfad 1127" d="M199.325,428.638h-6.551a.6.6,0,0,0,0,1.194h6.551a.6.6,0,0,0,0-1.194Z" transform="translate(-37.912 -26.262)" fill="#fff" style={{'fill': '#fff'}} />
                    <path id="Pfad_1128" data-name="Pfad 1128" d="M211.776,445.1h-3.365a.6.6,0,0,0,0,1.194h3.365a.6.6,0,0,0,0-1.194Z" transform="translate(-50.363 -39.372)" fill="#fff" style={{'fill': '#fff'}} />
                    <path id="Pfad_1129" data-name="Pfad 1129" d="M214.887,461.579h-2.569a.6.6,0,0,0,0,1.194h2.569a.6.6,0,0,0,0-1.194Z" transform="translate(-53.474 -52.491)" fill="#fff" style={{'fill': '#fff'}} />
                    <g id="Gruppe_2036" data-name="Gruppe 2036" transform="translate(144.563 398.74)">
                      <path id="Pfad_1130" data-name="Pfad 1130" d="M157.076,423.609c-.326,0-.756,0-1.194-.023l-.107,0a1.351,1.351,0,0,1-.866-.21c-.26-.252-.517-.512-.774-.771l-.385-.387-2.165-2.165c-.235-.235-.474-.466-.712-.7-.461-.447-.939-.909-1.373-1.388-.1-.114-.236-.24-.376-.372a7.677,7.677,0,0,1-.591-.6l-.015-.018-.035-.033-.007-.006-1.7-1.7-.03-.027-.051-.069a.643.643,0,0,1,.06-.839c.059-.062.119-.121.179-.18l.992-.991c.054-.054.1-.1.152-.148l0,0a.78.78,0,0,1,.134-.1l.075-.042a.575.575,0,0,1,.212-.04.718.718,0,0,1,.466.187l.116.1,1.583,1.583c.395.395.787.787,1.179,1.182l3.447,3.447q.294.294.59.587c.417.414.849.842,1.264,1.269a.442.442,0,0,1,.116.193l.391-.076-.382.1a1.7,1.7,0,0,1,.06.428l.005.086c.014.184.019.366.023.558l0,.084c0,.228,0,.459,0,.691v.11c0,.1,0,.21,0,.292Z" transform="translate(-146.16 -412.386)" fill="#8812c4" />
                      <path id="Pfad_1131" data-name="Pfad 1131" d="M146.906,411.609a.319.319,0,0,1,.2.087l.109.1.252.252,1.244,1.244c.419.419.835.835,1.253,1.256l1.972,1.972,1.474,1.473q.295.295.591.588c.416.413.846.839,1.258,1.265a.113.113,0,0,1,.019.023.064.064,0,0,1,0-.011l.389-.079-.37.132a1.587,1.587,0,0,1,.037.316l.006.1c.013.17.017.347.022.534l0,.077c0,.226,0,.453,0,.68-.306,0-.682,0-1.057-.023l-.112,0a1.464,1.464,0,0,1-.6-.093c-.257-.249-.516-.511-.767-.765l-.389-.392-2.166-2.165c-.238-.237-.481-.473-.716-.7-.478-.463-.93-.9-1.356-1.369-.115-.126-.252-.257-.4-.395a7.388,7.388,0,0,1-.559-.57l-.031-.036-.035-.032-.028-.026-1.5-1.5-.092-.092-.092-.092-.009-.009,0,0-.011-.014-.017-.022a.237.237,0,0,1,.026-.316c.056-.058.113-.114.171-.171l.1-.1.9-.9c.042-.042.083-.083.126-.123l.009-.008a.347.347,0,0,1,.059-.047l.044-.025a.178.178,0,0,1,.045-.006h0m0-.815a1,1,0,0,0-.509.14l.023-.006a1.145,1.145,0,0,0-.208.154h0c-.058.052-.113.108-.169.164l-.9.9c-.093.093-.188.184-.279.279a1.048,1.048,0,0,0-.1,1.357c.007.012.017.022.025.033.021.028.039.057.063.083a.465.465,0,0,0,.037.033l.184.184,1.532,1.533s.011.008.016.013c.324.386.744.731.977.987.665.733,1.4,1.4,2.1,2.1l2.165,2.165c.386.387.771.783,1.164,1.165a1.825,1.825,0,0,0,1.237.328c.4.018.807.023,1.213.023l.3,0c.4,0,.4-.055.4-.7,0-.27,0-.54,0-.81-.005-.22-.009-.441-.026-.659a2.354,2.354,0,0,0-.088-.624l0,.014a.85.85,0,0,0-.218-.372c-.61-.63-1.238-1.241-1.858-1.861l-1.473-1.474-1.973-1.973c-.419-.422-.837-.84-1.259-1.262l-1.238-1.238-.385-.385h-.017a1.122,1.122,0,0,0-.733-.288Z" transform="translate(-144.563 -410.794)" fill="#fff" style={{'fill': '#fff'}} />
                    </g>
                    <g id="Gruppe_2037" data-name="Gruppe 2037" transform="translate(146.392 400.605)">
                      <rect id="Rechteck_420" data-name="Rechteck 420" width="2.961" height="0.815" transform="translate(0 2.093) rotate(-45)" fill="#fff" style={{'fill': '#fff'}} />
                    </g>
                  </g>
                </svg>
                {
                  comments > 0 ?
                    <div className="count">{comments}</div>
                  :
                    null
                }
              </button>
            :
              ''
          }
        </div>
        <div className="editor-overlay-background"></div>
      </div>
    )
  }
}

export default EditorOverlay
