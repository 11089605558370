import React from 'react'
import Draggable from 'react-draggable'
import $ from 'jquery'

import ElementBase from './ElementBase'
import ComponentImage from './components/Image'

class ElementLenticularSlider extends ElementBase {
  updateImages(element) {
    var images = $(element.node).closest('.lenticular-slider').find('.images')
    var precentage = ($(element.node).position().left + $(element.node).outerWidth() / 2) * 100 / images.outerWidth()
    images.find('.image.image-left').width(precentage + '%')
  }

  handleDrag(e, element) {
    this.updateImages(element)
  }

  handleStop(e, element) {
    this.updateImages(element)
  }

  html() {
    return(
      <div className="lenticular-slider">
        <div className="images">
          <div className="image image-left">
            <ComponentImage
              id={this.props.element.settings.image_left}
            />
          </div>
          <div className="image image-right">
            <ComponentImage
              id={this.props.element.settings.image_right}
            />
          </div>
        </div>
        <Draggable
          bounds="parent"
          axis="x"
          onDrag={this.handleDrag.bind(this)}
          onStop={this.handleStop.bind(this)}
        >
          <div className="handle"></div>
        </Draggable>
      </div>
    )
  }
}

export default ElementLenticularSlider
