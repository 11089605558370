import CONFIG_MODULE from '../config/module.js'
import CONFIG_ELEMENTS from '../config/elements.js'

class Config {
  static getModuleConfig() {
    return CONFIG_MODULE
  }

  static getElementConfig(type) {
    var config
    CONFIG_ELEMENTS.map((element, index) => {
      if(element.type === type) {
        config = element
      }
      return null
    })
    return config
  }
}

export default Config
