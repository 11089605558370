import React from 'react'

import ElementPairsPairsItem from './ElementPairsPairsItem'

class ElementPairsPairsList extends React.Component {
  handleChangeText1(id, text, e) {
    this.props.onChangePair({
      itemId: id,
      fieldName: 'text_1',
      fieldValue: text
    })
  }

  handleChangeText2(id, text, e) {
    this.props.onChangePair({
      itemId: id,
      fieldName: 'text_2',
      fieldValue: text
    })
  }

  render() {
    if(this.props.pairs !== undefined && this.props.pairs.length > 0) {
      var pairs = this.props.pairs.map((pair, index) => {
        return(
          <ElementPairsPairsItem
            key={index}
            text1={(pair.settings.text_1 !== undefined ? pair.settings.text_1 : '')}
            text2={(pair.settings.text_2 !== undefined ? pair.settings.text_2 : '')}
            onChangeText1={this.handleChangeText1.bind(this, pair.id)}
            onChangeText2={this.handleChangeText2.bind(this, pair.id)}
          />
        )
      })
      return(
        <div className="pairs-list">
          <div className="pair-items">
            {pairs}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

export default ElementPairsPairsList
