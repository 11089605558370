import React from 'react'
import {withRouter} from 'react-router'

class Window extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var id = this.props.modalData.video
    return(
      <div className="modal-window help-video">
        <div className="main">
					<iframe src={'https://player.vimeo.com/video/' + id + '?dnt=1&fun=0'} frameBorder="0" controls allowFullScreen></iframe>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
