import React from 'react'

import Header from './header/Header'
import Login from './content/login/Login'
import Dashboard from './content/dashboard/Dashboard'
import WBTs from './content/wbts/WBTs'
import Templates from './content/templates/Templates'
import MediaLibrary from './content/media-library/MediaLibrary'
import Help from './content/help/Help'
import Profile from './content/profile/Profile'

import '../../styles/page.scss'

class Page extends React.Component {
  render() {
    return(
      <div id="page">
        <Header
          {...this.props}
        />
        <div id="content">
          {
            (() => {
              switch(this.props.page) {
                case 'login':
                  return(
                    <Login
                      {...this.props}
                    />
                  )
                case 'dashboard':
                  return(
                    <Dashboard
                      {...this.props}
                    />
                  )
                case 'wbts':
                  return(
                    <WBTs
                      {...this.props}
                    />
                  )
                case 'templates':
                  return(
                    <Templates
                      {...this.props}
                    />
                  )
                case 'media_library':
                  return(
                    <MediaLibrary
                      {...this.props}
                    />
                  )
                case 'help':
                  return(
                    <Help
                      {...this.props}
                    />
                  )
                case 'profile':
                  return(
                    <Profile
                      {...this.props}
                    />
                  )
                default:
                  return null
              }
            })()
          }
        </div>
      </div>
    )
  }
}

export default Page
