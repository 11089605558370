import React from 'react'
import $ from 'jquery'

import Element from './elements/Element'

class ExportPreview extends React.Component {
  constructor(props) {
    super(props)

    this.wbt = this.props.wbt
  }

  componentDidMount() {
    var document = this.props.document
    const script = document.createElement("script");
    script.id = 'jquery-js';
    script.src = "/dist/wbt/scripts/jquery.min.js?v=2.3";
    script.async = false;

    document.body.appendChild(script);

    var document = this.props.document
    const scriptt = document.createElement("script");
    scriptt.id = 'jquery-ui-js';
    scriptt.src = "/dist/wbt/scripts/jquery-ui.min.js?v=2.3";
    scriptt.async = false;

    document.body.appendChild(scriptt);

    var document = this.props.document
    const script1 = document.createElement("script");
    script1.id = 'daggable-js';
    script1.src = "/dist/wbt/scripts/draggable.bundle.legacy.min.js?v=2.3";
    script1.async = false;

    document.body.appendChild(script1);

    var document = this.props.document
    const script11 = document.createElement("script");
    script11.id = 'slick-js';
    script11.src = "/dist/wbt/scripts/slick.min.js?v=2.3";
    script11.async = false;

    document.body.appendChild(script11);

    const script2 = document.createElement("script");
    script2.id = 'main-js';
    script2.src = "/dist/wbt/scripts/main.min.js?v=2.3";
    script2.async = false;

    document.body.appendChild(script2);
  }

  modules() {
    var count = 0;
    var modules = this.wbt.modules.map((module, key) => {
      var elements = module.elements.map((element) => {
        return(
          <Element
            key={element.id}
            {...element}
          />
        )
      })
      var introslider = false
      if(elements.length === 1 && module.elements[0].type === 'introslider' && module.settings.headline == '') {
        introslider = true
      }
      var isLast = false;
      if(key + 1 == this.wbt.modules.length) {
        isLast = true;
      }
      count++;
      return(
        <div key={module.id} className={'module' + (introslider ? ' full-width' : '')} style={!this.props.examMode || count == 1 ? {} : {display: 'none'}}>
          <div className="module-container">
            {
              module.settings.headline_show && module.settings.headline !== '' ?
                <h1>{module.settings.headline}</h1>
              :
                ''
            }
            <div className="elements">{elements}</div>
            {
              !introslider ?
                !isLast ?
                  <button className="button next" data-completed-label="Fertig">Weiter</button>
                :
                  <button className="button next complete-wbt" data-completed-label="Abgeschlossen">Abschließen</button>
              :
                ''
            }
          </div>
        </div>
      )
    })
    return modules
  }

  render() {
    var modules = this.modules();
    return(
      <div id="export-preview">
        <div id="wbt" className="wbt export-preview">
          <div className="wbt-container">
            <div className="modules">
              {modules}
            </div>
          </div>
          <div className="result-screen">
            <div className="result-screen-container" data-type="success">
              <div className="content">
                <div className="wrapper">
                  <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="37.134" height="35.974" viewBox="0 0 37.134 35.974">
                      <path id="np_trophy_888734_000000" d="M46.724,22.231a33.948,33.948,0,0,0,.115-3.481H23.631a33.948,33.948,0,0,0,.115,3.481H16.668v1.16c0,10.329,13.114,17.64,16.248,19.263l0,3.947a3.418,3.418,0,0,1-3.481,3.481H27.11v4.641H43.358V50.083H41.036A3.418,3.418,0,0,1,37.555,46.6V42.657C40.688,41.031,53.8,33.72,53.8,23.393v-1.16ZM19.105,24.553h4.874a30.524,30.524,0,0,0,3.133,11.6C23.4,33.257,19.569,29.194,19.105,24.553Zm24.37,11.6a30.536,30.536,0,0,0,3.133-11.6h4.874C50.9,29.194,47.072,33.257,43.475,36.157Z" transform="translate(-16.668 -18.75)" fill="#fff"/>
                    </svg>
                  </div>
                  <h1>Gratuliere</h1>
                  <p>Du hast das WBT bestanden!</p>
                  <p>Für den Abschluss deines WBTs erhältst du:</p>
                  <div className="reward">
                    <div className="coin">
                      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <circle id="Ellipse_77" data-name="Ellipse 77" cx="16.865" cy="16.865" r="16.865" transform="translate(5.052 5.052)" fill="#fff"/>
                        <path id="Pfad_914" data-name="Pfad 914" d="M40.076,58.159A18.084,18.084,0,1,1,58.159,40.075,18.085,18.085,0,0,1,40.076,58.159Zm0-33.729A15.646,15.646,0,1,0,55.72,40.075,15.646,15.646,0,0,0,40.076,24.43Z" transform="translate(-18.159 -18.159)" fill="#002451"/>
                        <circle id="Ellipse_78" data-name="Ellipse 78" cx="16.865" cy="16.865" r="16.865" transform="translate(1.395 1.395)" fill="#fff"/>
                        <path id="Pfad_915" data-name="Pfad 915" d="M18.25,36.507A18.258,18.258,0,1,1,36.507,18.25,18.259,18.259,0,0,1,18.25,36.507Zm0-33.729A15.472,15.472,0,1,0,33.72,18.25,15.472,15.472,0,0,0,18.25,2.778Z" transform="translate(0.01 0.01)" fill="#002451"/>
                        <path id="Pfad_916" data-name="Pfad 916" d="M88.7,65.374H84.812c-1.717-3.638-3.433-7.276-5.19-11L78.229,57.3H74.31c1.339-2.821,2.644-5.608,3.962-8.415h2.647Z" transform="translate(-61.367 -40.366)" fill="#012552"/>
                        <path id="Pfad_917" data-name="Pfad 917" d="M55.636,103.079,54.1,99.78h9.995l1.538,3.3Z" transform="translate(-44.676 -82.401)" fill="#fe4e00"/>
                        <path id="Pfad_918" data-name="Pfad 918" d="M54.259,121.42h3.927l-1.809,3.868H52.45Z" transform="translate(-43.314 -100.272)" fill="#002451"/>
                        <path id="Pfad_919" data-name="Pfad 919" d="M36.35,181.59l2.421,2.421-.8-1.648Z" transform="translate(-30.018 -149.963)" fill="#002350"/>
                        <path id="Pfad_920" data-name="Pfad 920" d="M184,38.739,181.58,36.32l.8,1.648Z" transform="translate(-149.955 -29.993)" fill="#002350"/>
                      </svg>
                    </div>
                    <h2>10</h2>
                    <div className="label">Coins</div>
                  </div>
                  <button className="button completed leave-wbt">Zurück zur Übersicht</button>
                </div>
              </div>
            </div>
            <div className="result-screen-container" data-type="fail">
              <div className="content">
                <div className="wrapper">
                  <h1>Leider nicht bestanden</h1>
                  <p>Bitte versuche es erneut!</p>
                  <div style={{marginTop: '35px'}}>
                    <button className="button completed restart">WBT neu Starten</button>
                  </div>
                  <div>
                    <button className="button completed leave-wbt">Zurück zur Übersicht</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ExportPreview
